import React from "react"
import { useSelector } from "react-redux"
import PatientDashboard from "../components/PatientDashboard/PatientDashboard"

const DashboardView = () => {
  const userInfo = useSelector(state => state.userInfo)
  return userInfo?.userInfo ? <PatientDashboard userInfo={userInfo?.userInfo} /> : null
}

export default DashboardView
