import React from "react"

import { Row, Col, Input, Button, Spin, Divider, Carousel, Typography } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { googleLoginAction, validateLoginDetailsAction, confirmSignupAction, resetLoginError } from "../../redux/actions/userActions"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import fitpeoLogo from "../../assets/fitpeo_logo.png"
import patientImg from "../../assets/login-patient.jpg"

import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { useState } from "react"
import LoginComponent from "./Login"
import OTPForm from "../ForgotPassword/OTPForm"
import ChangePasswordForm from "../ForgotPassword/ChangePasswordForm"
import { Mixpanel } from "../../utils/mixpanel"
import { GoogleLogin } from "@react-oauth/google"
import FloatInput from "../../utils/FloatInput/FloatInput"
import { UserOutlined } from "@ant-design/icons"

const INVALID_CREDS = "Enter a Valid Email or Phone Number"
import ConfirmSignup from "./ConfirmSignup"
import { useEffect } from "react"

const schema = yup
  .object({
    credential: yup
      .string()
      .required(INVALID_CREDS)
      .test("value-test", INVALID_CREDS, function (value) {
        const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
        const phoneRegex = /^\(?[2-9]\d{2}\)?[-.]?\d{3}[-.]?\d{4}$/;
        let isValidEmail = emailRegex.test(value)
        let isValidPhone = phoneRegex.test(value)
        if (!isValidEmail && !isValidPhone) {
          return false
        }
        return true
      })
  })
  .required()

const defaultValues = {
  credential: ""
}

const LoginHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [userName, setUserName] = useState("")
  const [showNext, setShowNext] = useState(false)
  const [otpValue, setOtpValue] = useState("")
  const [showOTPForm, setShowOTPForm] = useState(false)
  const [showConfirmSignup, setShowConfirmSignup] = useState(false)
  const [changePasswordShow, setChangePasswordShow] = useState(false)

  const { loading: validateLoading, error: validateError } = useSelector(
    state => state.validateLoginDetails
  )
  const { loading: googleLoginLoading, error: googleLoginErr } = useSelector(
    state => state.googleLogin
  )

  useEffect(()=>{
    dispatch(resetLoginError())
  },[])

  window.onpopstate = () => {
    if(showNext) setShowNext(false)
  }

  useEffect(()=>{
    if(location?.state?.credential){
      setUserName(location.state.credential)
      setShowNext(true)
    }
  },[location])

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = async ({ credential }) => {
    setUserName(credential)

    // removing the previous localstroage
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("id");
    localStorage.removeItem("welcomeTutorial");
    localStorage.removeItem("userInfo")

    let res = await dispatch(validateLoginDetailsAction(credential))
    // res?.details?.forgotOTP === false // removed below from if condition (bug-FIT-5534)
    if (res?.details?.onBoardingOTP === false && res?.details?.setPassword === false) {
      setShowNext(true)
    }
    if (res?.details?.onBoardingOTP === true || res?.details?.setPassword === true) {
      setShowConfirmSignup(true)
    }
    if (res?.details?.forgotOTP === true) {
      // setChangePasswordShow(true)
      // setShowOTPForm(true)
      if (!otpValue) {
        // dispatch(forgetPasswordProviderAction(credential))
        setShowOTPForm(true)
      } else navigate("/forget-password")
    }
  }

  const onSuccessGoogleLogin = ({ credential }) => dispatch(googleLoginAction({ credential, navigateCallback: navigate }))

  const onConfirmSignup = async () => {
    let res = await dispatch(confirmSignupAction(userName))
    if(res?.details?.onBoardingOTP === true || res?.details?.setPassword === true){
      setShowConfirmSignup(false)
      setShowOTPForm(true)
    } 
  }

  return (
    <Row align="middle" className="w-full h-screen">
      <Col xs={24} lg={12} className="px-4">
        <div style={{ maxWidth: "500px", margin: "auto" }}>
          <img alt="logo" width={150} src={fitpeoLogo} />
          <h2 className="mt-3 mb-4 text-secondary">Log in to your account</h2>
          {showNext ? (
            <LoginComponent userName={userName} />
          ) : showConfirmSignup ? (
            <ConfirmSignup onConfirmSignup={onConfirmSignup} />
          ) : showOTPForm ? (
            <OTPForm
              credential={userName}
              setOtpValue={setOtpValue}
              setShowNext={setShowOTPForm}
              setChangePasswordShow={setChangePasswordShow}
            />
          ) : changePasswordShow ? (
            <ChangePasswordForm
              otp={otpValue}
              userName={userName}
              setChangePasswordShow={setChangePasswordShow}
            />
          ) : (
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-1 min-h-[65px]">
                  <Controller
                    name="credential"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FloatInput label="Email or Phone" placeholder="Email or Phone Number">
                        <Input
                          size="large"
                          prefix={<UserOutlined className="text-[#96A0B5]" />}
                          status={errors.credential ? "error" : undefined}
                          {...field}
                          onChange={e => field.onChange(e?.target?.value?.replace(/\s/g, ""))}
                        />
                      </FloatInput>
                    )}
                  />

                  {errors.credential ? (
                    <small className="text-danger">{errors.credential.message}</small>
                  ) : null}
                  {validateError !== undefined && typeof validateError === "string" && (
                    <NavLink
                      className="text-primary float-right underline"
                      to="/identify-patient"
                      state={{ enteredCred: userName }}>
                      Forgot email or phone?
                    </NavLink>
                  )}
                </div>

                <Button
                  block
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={Boolean(validateLoading)}
                  onClick={() => {
                    Mixpanel.track("Login Validate")
                  }}>
                  {validateLoading ? "Loading..." : "Continue"}
                </Button>

                {validateError !== undefined && typeof validateError === "string" && (
                  <p className="mt-3 text-danger text-center font-medium">{validateError}</p>
                )}
              </form>
            </>
          )}

          <Divider plain>
            <span className="text-secondary text-xs">Or log in with</span>
          </Divider>

          <Spin spinning={googleLoginLoading === true}>
            <div className="mt-4 mb-3 grid place-content-center">
              <GoogleLogin
                onSuccess={onSuccessGoogleLogin}
                onError={() => {
                  console.log("Login Failed")
                }}
              />
            </div>
          </Spin>

          {googleLoginErr !== undefined && typeof googleLoginErr === "string" && (
            <p className="text-danger text-center font-medium">{googleLoginErr}</p>
          )}
        </div>
      </Col>
      <Col xs={0} lg={12} className="h-full">
        <div className="relative h-full w-full flex items-end justify-center">
          <img src={patientImg} alt="loginImage" className="absolute h-full w-full z-[-10]" />
          <div className="w-full">
            <Carousel
              autoplay
              dots
              className="p-8 m-5 h-[300px]"
              style={{
                border: "1px solid rgba(255, 255, 255, 0.2)",
                borderRadius: "12px",
                color: "#FFF",
                boxShadow: "20px 20px 10px 0px rgba(0, 0, 0, 0.05)",
                filter: "drop-shadow(20px 20px 10px rgba(0, 0, 0, 0.05))",
                backdropFilter: "blur(50px) opacity(0.95)",
                background:
                  "linear-gradient(105.83deg, rgba(255, 255, 255, 0.3) 0%, rgba(102, 101, 101, 0) 100%)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center"
              }}>
              <div>
                <h2 className="text-2xl font-semibold">
                  Gaining Insight into Patient Health from Afar
                </h2>
                <h3 className="text-sm mt-2">
                  This technology empowers both patients and clinicians, leading to more
                  personalized care and better health outcomes.
                </h3>
              </div>
              <div>
                <h2 className="text-2xl font-semibold">
                  Generate tailored reports for detailed health analysis.
                </h2>
                <h3 className="text-sm mt-2">
                  Access a comprehensive snapshot of patient health, including key vitals and recent
                  activity updates.
                </h3>
              </div>
              <div>
                <h2 className="text-2xl font-semibold">
                  Track adherence to prescribed medication schedules.
                </h2>
                <h3 className="text-sm mt-2">
                  Ensure patients are following their prescribed medication schedules with real-time
                  tracking and reminders.
                </h3>
              </div>
            </Carousel>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default LoginHome