import { Row, Col, Button, Input, Select } from "antd"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import {
  getEmergencyContactDetailsAction,
  postEmergencyContactDetailAction,
  updateEmergencyContactDetailsAction
} from "../../../redux/actions/patientAction"
import { useEffect, useState } from "react"
import Loading from "../../layouts/Loading/Loading"
import formatPhoneNumber from "../../../utils/formatPhoneNumber"
import { PatternFormat } from "react-number-format"

const alphaRegex = /^[a-zA-Z\s]+$/

const schema = yup
  .object()
  .shape({
    emergencyMobNumber: yup
      .string()
      .nullable()
      .transform(value => (value === "" ? null : value))
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Invalid Mobile Number"),
    EmergencyPersonName: yup
      .string()
      .trim()
      .nullable()
      .transform(value => (value === "" ? null : value))
      .matches(alphaRegex, "Name should contain alphabets only"),
    EmergencyPersonRelation: yup
      .string()
      .trim()
      .nullable()
      .transform(value => (value === "" ? null : value))
      .matches(alphaRegex, "Relationship should contain alphabets only")
  })
  .required()

const RelationshipOptions = [
  "Mother",
  "Father",
  "Spouse",
  "Child",
  "Sibling",
  "Grandparent",
  "Grandchild",
  "Aunt",
  "Uncle",
  "Nephew",
  "Niece",
  "Cousin",
  "Partner",
  "Friend",
  "Colleague",
  "Neighbor",
  "Guardian",
  "Other"
]

const EmergencyContactDetail = ({ formData, setFormData, setCurrentStep }) => {
  const { loading, error } = useSelector(state => state.emergencyContactDetail)
  const [isFocused, setIsFocused] = useState(false)

  const {
    getEmergencyContactDetails,
    loading: emergencyContactDetailLoading,
    error: getEmergencyContactDetailError
  } = useSelector(state => state.getEmergencyContactDetails)
  const { loading: updateLoading, error: updateError } = useSelector(
    state => state.updateEmergencyContactDetails
  )
  const dispatch = useDispatch()

  const defaultValues = {
    emergencyMobNumber: getEmergencyContactDetails?.mobileNumber || "",
    EmergencyPersonName: getEmergencyContactDetails?.name || "",
    EmergencyPersonRelation: getEmergencyContactDetails?.relationship || ""
  }
  const {
    // reset,
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    shouldFocusError: false,
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    if (
      !data?.EmergencyPersonName?.length ||
      !data?.EmergencyPersonRelation?.length ||
      !data.emergencyMobNumber?.length
    ) {
      setCurrentStep(prevStep => prevStep + 1)
      return
    }
    const formDataToSubmit = {
      name: data.EmergencyPersonName,
      relationship: data.EmergencyPersonRelation,
      mobileNumber: data.emergencyMobNumber?.replace(/[^\d]/g, ""),
      countryCode: "US"
    }
    const result = !(
      getEmergencyContactDetails?.mobileNumber && getEmergencyContactDetails?.relationship
    )
      ? dispatch(
          postEmergencyContactDetailAction(formData?.id, {
            ...formDataToSubmit
          })
        )
      : dispatch(
          updateEmergencyContactDetailsAction(formData?.id, {
            ...formDataToSubmit
          })
        )
    result.then(res => {
      if (res?.message?.toLowerCase() === "success") {
        setCurrentStep(prevStep => prevStep + 1)
      }
    })
  }

  useEffect(() => {
    dispatch(getEmergencyContactDetailsAction(formData?.id))
  }, [])

  useEffect(() => {
    if (getEmergencyContactDetails) {
      setValue("emergencyMobNumber", formatPhoneNumber(getEmergencyContactDetails?.mobileNumber))
      setValue("EmergencyPersonName", getEmergencyContactDetails?.name)
      setValue("EmergencyPersonRelation", getEmergencyContactDetails?.relationship)
    }
  }, [emergencyContactDetailLoading])

  const prevPage = () => {
    setCurrentStep(prev => prev - 1)
  }
  window.back = () => {
    prevPage()
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleMobileChange = e => {
    const { value } = e.target
    return formatPhoneNumber(value)
  }

  return (
    <>
      {!emergencyContactDetailLoading ? (
        <>
          <h1 className={`${!window?.isMobileVersion ? "pb-3" : "pb-8"} pt-4 text-center`}>
            Please enter the details
          </h1>
          <form onSubmit={handleSubmit(onSubmit)} className="h-[80vh] relative">
            <Row gutter={20}>
              <Col xs={24} md={24} className="mb-4">
                <label
                  className="text-[#959595] mb-[0.75rem] text-base"
                  htmlFor="input-emergency-mob-number">
                  Emergency Contact Person Number
                </label>
                <Controller
                  name="emergencyMobNumber"
                  control={control}
                  render={({ field }) => {
                    const { ref, ...rest } = field
                    return (
                      <PatternFormat
                        format="(###) ###-####"
                        className={`formatted-mobile-input flex px-[11px] py-[12px] w-full  rounded-xl border border-${
                          errors.emergencyMobNumber ? "danger" : isFocused ? "primary" : "#dfdfdf"
                        } focus:outline-none`}
                        id="input-emergencyMobNumber"
                        {...rest}
                        onChange={e => rest.onChange(handleMobileChange(e))}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        placeholder="Enter Mobile Number"
                      />
                    )
                  }}
                />
                {errors.emergencyMobNumber ? (
                  <small className="text-danger">{errors.emergencyMobNumber.message}</small>
                ) : null}
              </Col>

              <>
                <Col xs={24} md={12} className="mb-4">
                  <label
                    className="text-[#959595] mb-[0.75rem] text-base"
                    htmlFor="input-emergency-contact-name">
                    Emergency Contact Person Name
                  </label>
                  <Controller
                    name="EmergencyPersonName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="input-emergency-contact-name"
                        status={errors.EmergencyPersonName ? "error" : undefined}
                        className="bg-[#fff]"
                        placeholder="Enter Name"
                        value={field.value} // Use field.value from react-hook-form
                        onChange={e => {
                          if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                            field.onChange(e) // Notify react-hook-form of the change
                            setFormData(prevData => ({
                              ...prevData,
                              EmergencyPersonName: e.target.value // Update the corresponding field in formData
                            }))
                          }
                        }}
                      />
                    )}
                  />
                  {errors.EmergencyPersonName ? (
                    <small className="text-danger">{errors.EmergencyPersonName.message}</small>
                  ) : null}
                </Col>
                <Col xs={24} md={12} className="mb-4">
                  <label
                    className="text-[#959595] mb-[0.75rem] text-base"
                    htmlFor="input-emergency-contact-relation">
                    Emergency Contact Person Relationship
                  </label>
                  <Controller
                    name="EmergencyPersonRelation"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        id="input-emergency-contact-relation"
                        status={errors.EmergencyPersonRelation ? "error" : undefined}
                        className="bg-[#fff] w-full"
                        placeholder="Select Relationship"
                        value={field.value}
                        onChange={value => {
                          field.onChange(value)
                          setFormData(prevData => ({
                            ...prevData,
                            EmergencyPersonRelation: value
                          }))
                        }}>
                        {RelationshipOptions.map(option => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.EmergencyPersonRelation ? (
                    <small className="text-danger">{errors.EmergencyPersonRelation.message}</small>
                  ) : null}
                </Col>
              </>

              {error || updateError ? (
                <Col className="mb-4" xs={24}>
                  <p className="text-danger font-semibold">{error || updateError}</p>
                </Col>
              ) : null}
            </Row>

            <Row className="w-full mt-2 absolute bottom-1">
              <Col xs={12} md={12} className="mb-2 pr-2 pl-0">
                <Button
                  type="primary"
                  onClick={prevPage}
                  style={{ minWidth: 0 }}
                  className="w-full">
                  Back
                </Button>
              </Col>
              <Col xs={12} md={12} className="mb-2 pr-0 pl-2">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading || updateLoading}
                  style={{ minWidth: 0 }}
                  className="w-full">
                  Next
                </Button>
              </Col>
            </Row>
          </form>
        </>
      ) : (
        <Loading />
      )}
    </>
  )
}
export default EmergencyContactDetail
