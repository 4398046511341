import React from "react"

import { useSelector } from "react-redux"

import PatientDetailInfo from "./PatientName"
import Loading from "../layouts/Loading/Loading"

import "./patient.css"

const PatientDetail = () => {
  const { loading: patientLoading, userInfo: patientInfoDetail } = useSelector(
    state => state.userInfo
  )

  return (
    <div className="patient-detail">
      {patientLoading ? <Loading /> : <PatientDetailInfo patientDetails={patientInfoDetail} />}
    </div>
  )
}

export default PatientDetail
