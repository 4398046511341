import qs from "qs"
import AsyncLocalStorage from "@createnextapp/async-local-storage"
import { axiosBasicGet, axiosBasicPost, axiosGet, axiosPost, axiosRefreshGet, updateToken } from "../axios"
import {
  SEARCH_UNIDENTIFY_REQUEST,
  SEARCH_UNIDENTIFY_SUCCESS,
  SEARCH_UNIDENTIFY_FAIL,
  IMAGE_UPLOAD_FAIL,
  IMAGE_UPLOAD_REQUEST,
  IMAGE_UPLOAD_SUCCESS,
  PASSWORD_UPDATE_FAIL,
  PASSWORD_UPDATE_REQUEST,
  PASSWORD_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  VALIDATE_LOGIN_DETAILS_SUCCESS,
  VALIDATE_LOGIN_DETAILS_REQUEST,
  VALIDATE_LOGIN_DETAILS_FAIL,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  EMAIL_UPDATE_REQUEST,
  EMAIL_UPDATE_SUCCESS,
  EMAIL_UPDATE_FAIL,
  PHONE_UPDATE_REQUEST,
  PHONE_UPDATE_SUCCESS,
  PHONE_UPDATE_FAIL,
  USER_LOGIN_RESET,
  VALIDATE_LOGIN_DETAILS_RESET,
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_FAIL,
  GOOGLE_LOGIN_RESET,
  CONFIRM_SIGNUP_REQUEST,
  CONFIRM_SIGNUP_SUCCESS,
  CONFIRM_SIGNUP_FAIL
} from "../types/userConstant"
import { loginTypeChecker } from "../../utils/loinTypeChecker"
import { deviceInfo } from "../../utils/fixedInfo"
import { encryptBoolean } from "../../utils/encryptionUtils"
import { sanitizePhoneNumberFormat } from "../../utils/sanitizePhoneNumberFormat"
import moment from "moment"

export const searchUnIdentifiedAction =
  ({ firstName, lastName, middleName, dob, email, mobileNumber, chronicConditions, orgName }) =>
  async dispatch => {
    try {
      dispatch({
        type: SEARCH_UNIDENTIFY_REQUEST
      })
      const { data } = await axiosBasicPost(`/api/auth/searchUnIdentified`, {
        firstName,
        lastName,
        middleName,
        dob,
        email,
        mobileNumber,
        chronicConditions,
        orgName
      })

      dispatch({
        type: SEARCH_UNIDENTIFY_SUCCESS,
        payload: data?.details
      })

      return data
    } catch (error) {
      dispatch({
        type: SEARCH_UNIDENTIFY_FAIL,
        payload: error.response && error?.response?.data?.details
      })
      return error
    }
  }

export const userLoginAction = (userName, password, extension, navigateCallback) => async dispatch => {
  let updatedUserName = sanitizePhoneNumberFormat(userName)
  try {
    dispatch(resetLoginError())
    
    dispatch({
      type: USER_LOGIN_REQUEST
    })
    let type = loginTypeChecker(updatedUserName)
    let info = deviceInfo()
    const { data } = await axiosBasicPost(
      `/api/auth/authorize`,
      {
        userName: updatedUserName,
        password,
        loginTypes: extension ? 'landline' : type,
        extension: extension ? extension : null,
        appVersion: info.appVersion,
        deviceId: info.deviceId,
        deviceModel: info.deviceModel,
      }
    )
    localStorage.setItem("token", JSON.stringify(data?.details?.token))
    localStorage.setItem("refreshToken", JSON.stringify(data?.details?.refreshToken))
    localStorage.setItem("id", JSON.stringify(data?.details?.id))
    localStorage.setItem("welcomeTutorial",JSON.stringify(encryptBoolean(data?.details?.welcomeTutorial)))
    updateToken(data?.details?.token)
    if(data?.details?.welcomeTutorial)
    {
      return navigateCallback("/welcome", { state: { fromLogin: true, patientId:  data?.details?.id} })
    }
    let res = null;
    if (data?.details?.token && data?.details?.refreshToken && data?.details?.id) {
      // await dispatch(getUserProfileAction(data?.details?.id))
      document.location.href = "/"
    }
  } catch (error) {
    //error?.response?.status === 401 && dispatch(prevUserLogoutAction())
    const errDetails = error?.response?.data?.details
    if (typeof errDetails === "object") {
      const keys = Object.keys(errDetails)
      if (keys.includes("count") && keys.includes("blockedTill") && keys.includes("attemptsLeft")) {
        dispatch({
          type: USER_LOGIN_FAIL,
          payload: errDetails
        })
      } else {
        dispatch({
          type: USER_LOGIN_FAIL,
          payload: Object.values(errDetails)?.[0]
        })
      }
    } else {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: "Something went wrong, Please try again!"
      })
    }
  }
}

export const googleLoginAction = ({ credential, navigateCallback }) => async dispatch => {
  try {
    dispatch(resetLoginError())

    dispatch({
      type: GOOGLE_LOGIN_REQUEST
    })
    let info = deviceInfo()
    const { data } = await axiosBasicPost(
      `/api/auth/authorize`,
      {
        userName: credential,
        loginTypes: "google",
        appVersion: info.appVersion,
        deviceId: info.deviceId,
        deviceModel: info.deviceModel,
      }
    )
    if(data?.details?.userType !== "patient"){
      dispatch({
        type: GOOGLE_LOGIN_FAIL,
        payload: "Invalid Credentials"
      })
      return
    }
    localStorage.setItem("token", JSON.stringify(data?.details?.token))
    localStorage.setItem("refreshToken", JSON.stringify(data?.details?.refreshToken))
    localStorage.setItem("id", JSON.stringify(data?.details?.id))
    localStorage.setItem("welcomeTutorial",JSON.stringify(encryptBoolean(data?.details?.welcomeTutorial)))
    updateToken(data?.details?.token)
    if(data?.details?.welcomeTutorial)
    {
      return navigateCallback("/welcome", { state: { fromLogin: true, patientId:  data?.details?.id} })
    }
    if (data?.details?.token && data?.details?.refreshToken && data?.details?.id) {
      document.location.href = "/"
    }
  } catch (error) {
    dispatch({
      type: GOOGLE_LOGIN_FAIL,
      payload: typeof error?.response?.data?.details === "object" && Object.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const resetLoginError = () => async dispatch => {
  dispatch({
    type: USER_LOGIN_RESET
  })
  dispatch({
    type: VALIDATE_LOGIN_DETAILS_RESET
  })
  dispatch({
    type: GOOGLE_LOGIN_RESET
  })
}

export const clearUserLoginError = () => async dispatch => {
  dispatch({
    type: USER_LOGOUT
  })
}

export const confirmSignupAction = (userName) => async dispatch => {
  let updatedUserName = sanitizePhoneNumberFormat(userName)

  let loginTypes = loginTypeChecker(updatedUserName)
  try {
    dispatch({
      type: CONFIRM_SIGNUP_REQUEST
    })

    const { data } = await axiosBasicPost(`/api/auth/confirmSignUp`, { userName : updatedUserName, loginTypes })

    dispatch({
      type: CONFIRM_SIGNUP_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    // error?.response?.status === 401 && dispatch(userLogoutAction())
    if (loginTypes === "email" && error.response && error?.response?.data?.details?.message === "Invalid login details") {
      dispatch({
        type: CONFIRM_SIGNUP_FAIL,
        payload: "Please verify your mail"
      })
    } else if (loginTypes === "mobileNumber" && error.response && error?.response?.data?.details?.message === "Invalid login details") {
      dispatch({
        type: CONFIRM_SIGNUP_FAIL,
        payload: "Please verify your number"
      })
    } else {
      dispatch({
        type: CONFIRM_SIGNUP_FAIL,
        payload: error.response && error?.response?.data?.details
      })
    }

    return error.response
  }
}

export const validateLoginDetailsAction = (userName) => async dispatch => {

  let updatedUserName = sanitizePhoneNumberFormat(userName)
  let loginTypes = loginTypeChecker(updatedUserName)
  try {
    dispatch(resetLoginError())
      
    dispatch({
      type: VALIDATE_LOGIN_DETAILS_REQUEST
    })

    const { data } = await axiosBasicPost(`/api/auth/validate`, {userName: updatedUserName, loginTypes })

    if(data?.details?.profileType !== "patient"){
      dispatch({
        type: VALIDATE_LOGIN_DETAILS_FAIL,
        payload: "Invalid Credentials"
      })
      return
    }

    dispatch({
      type: VALIDATE_LOGIN_DETAILS_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    // error?.response?.status === 401 && dispatch(userLogoutAction())
    if (loginTypes === "email" && error.response && error?.response?.data?.details?.message === "Invalid login details") {
      dispatch({
        type: VALIDATE_LOGIN_DETAILS_FAIL,
        payload: "The email is not registerd. Please try again!"
      })
    } else if (loginTypes === "mobileNumber" && error.response && error?.response?.data?.details?.message === "Invalid login details") {
      dispatch({
        type: VALIDATE_LOGIN_DETAILS_FAIL,
        payload: "The mobile number is not registerd. Please try again!"
      })
    } else {
      dispatch({
        type: VALIDATE_LOGIN_DETAILS_FAIL,
        payload: typeof error?.response?.data?.details === "object" && Object.values(error?.response?.data?.details)?.[0]
      })
    }

    return error.response
  }
}

export const passwordUpdateAction = (userName, otp, password) => async dispatch => {
  let updatedUserName = sanitizePhoneNumberFormat(userName)
  let loginTypes = loginTypeChecker(updatedUserName)
  try {
    dispatch({
      type: PASSWORD_UPDATE_REQUEST
    })
    const { data } = await axiosBasicPost(
      `/api/auth/submitOTP`,
      { userName: updatedUserName, loginTypes, otp, password }
    )

    dispatch({
      type: PASSWORD_UPDATE_SUCCESS,
      payload: data
    })

    return data

    // dispatch(userLogoutAction())
  } catch (error) {
    // error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PASSWORD_UPDATE_FAIL,
      payload: error.response && error?.response?.data?.details
    })
    return error
  }
}
//password reset from dashboard->security
export const passwordResetAction = ({ oldPassword, newPassword }) => async dispatch => {
  let info = deviceInfo()
  try {
    dispatch({
      type: PASSWORD_UPDATE_REQUEST
    })
    const { data } = await axiosPost(
      `/api/resetPassword`,
      {
        password: newPassword,
        oldPassword,
        appVersion: info.appVersion,
        deviceId: info.deviceId,
        deviceModel: info.deviceModel,
      }
    )

    dispatch({
      type: PASSWORD_UPDATE_SUCCESS,
      payload: data
    })

    return data

    // dispatch(userLogoutAction())
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PASSWORD_UPDATE_FAIL,
      payload: error.response && error?.response?.data?.details
    })
    return error?.response?.data?.details
  }
}
// email
export const emailUpdateAction = (newEmail, oldEmail) => async dispatch => {
  try {
    dispatch({
      type: EMAIL_UPDATE_REQUEST
    })

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        "Content-Type": "application/json"
      }
    }

    const { data } = await axiosPost(`/support/v1/updateEmail`, { newEmail, oldEmail }, config)

    dispatch({
      type: EMAIL_UPDATE_SUCCESS,
      payload: data
    })

    return data

    // dispatch(userLogoutAction())
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: EMAIL_UPDATE_FAIL,
      payload: error.response && error?.response?.data?.details
    })
    return error
  }
}
// phone number
export const phoneUpdateAction = (phoneNumber, countryCode) => async dispatch => {
  try {
    dispatch({
      type: PHONE_UPDATE_REQUEST
    })

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        "Content-Type": "application/json"
      }
    }

    const { data } = await axiosPost(
      `/support/v1/updatePhoneNumber`,
      { phoneNumber, countryCode },
      config
    )

    dispatch({
      type: PHONE_UPDATE_SUCCESS,
      payload: data
    })

    return data

    // dispatch(userLogoutAction())
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PHONE_UPDATE_FAIL,
      payload: error.response && error?.response?.data?.details
    })
    return error
  }
}

export const UpdateProfileAction = formData => async dispatch => {
  try {
    dispatch({
      type: PROFILE_UPDATE_REQUEST
    })
    const updatedFormData = {
      ...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
    }
    const { data } = await axiosPost(
      `/api/cs2/patients/${formData?.id}/update`,
      updatedFormData,
      // { headers: { "Content-Type": "application/json" } }
    )

    localStorage.setItem("userInfo", JSON.stringify(formData))
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: formData
    })

    dispatch({
      type: PROFILE_UPDATE_SUCCESS,
      payload: data
    })

    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PROFILE_UPDATE_FAIL,
      payload: error.response && error?.response?.data?.details
    })
    return error?.response?.data?.details?.message
  }
}

export const ImageUploadAction = profilePic => async dispatch => {
  try {
    dispatch({
      type: IMAGE_UPLOAD_REQUEST
    })

    const formData = new FormData()
    formData.append("profImg", profilePic)

    const { data } = await axiosPost(`v1/uploadPhoto`, formData)

    // localStorage.setItem('profileImage',data)
    dispatch({
      type: IMAGE_UPLOAD_SUCCESS,
      payload: data
    })

    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: IMAGE_UPLOAD_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getUserProfileAction = (id) => async dispatch => {
  try {
    const { data } = await axiosGet(`/api/cs/patients/${id}/profile`)
    await AsyncLocalStorage.setItem("userInfo", JSON.stringify(data?.details))
    // localStorage.setItem("profileImage", JSON.stringify(data.profileImage));
    await dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data?.details
    })
    // dispatch({ type: GET_ADMIN_SUCCESS, payload: data.profileImage });
    // if (data?.details?.id !== null) {
    //   document.location.href = "/"
    // }
    return data;
  } catch (error) {
    error?.response?.status === 401 &&
      (!window.isMobileVersion
        ? dispatch(userLogoutAction())
        : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" }))
    console.log(error)  
  }
}

// const handleLogout = async () => {
//   try {
//     await axiosGet("/support/v1/logout")
//   } catch (error) {
//     document.location.href = "/login"
//   }
// }

export const prevUserLogoutAction = () => async dispatch => {
  const res = await axiosGet(`/api/cs/config/logout`)
  if (res?.data?.message === 'Success') {
    // window.webkit.messageHandlers.logout.postMessage({ value: "harish" });
    // localStorage.removeItem("userInfo")
    // localStorage.removeItem("token")
    // localStorage.removeItem("refreshToken") // added new
    // localStorage.removeItem("image")
    // localStorage.removeItem("id")
    localStorage.clear()
    //await handleLogout()
    dispatch({ type: USER_LOGOUT })
    document.location.href = "/login"
  }
}

export const userLogoutAction = () => async dispatch => {
  try {

    const res = await axiosRefreshGet('/api/refresh')
    if (res?.data?.details?.token && res?.data?.details?.refreshToken && res?.data?.details?.id) {
      await AsyncLocalStorage.setItem("token", JSON.stringify(res?.data?.details?.token))
        .then(() => {
          AsyncLocalStorage.setItem("refreshToken", JSON.stringify(res?.data?.details?.refreshToken))
        }).then(() => {
          AsyncLocalStorage.setItem("id", JSON.stringify(res?.data?.details?.id))
        })
        .then(() => {
          window.location.reload()
        })
    }
  } catch (err) {
    localStorage.clear()
    //await handleLogout()
    dispatch({ type: USER_LOGOUT })
    document.location.href = "/login"
  }
}
