import React from "react"
import { Link, NavLink } from "react-router-dom"

import { Button } from "antd"

import { useSelector } from "react-redux"

const RPMViewNavbar = ({ setSidebarOpen, sidebarOpen, title }) => {
  const { userInfo } = useSelector(state => state.userInfo)

  return (
    <div className="viewheader-component header-component">
      <nav className="navbar navbar-expand-lg navbar-light bg-transparent pb-3">
        <div className="container-fluid view_header_text">
          <Link to="/ccm-view-care" class="navbar-brand">
            {title || "View Treatement Plan"}
          </Link>
          <Button
            type="primary"
            id="sidebarCollapse"
            className="block md:hidden"
            onClick={() => setSidebarOpen(!sidebarOpen)}>
            <i className="fas fa-align-left" />
            <span>Toggle Sidebar</span>
          </Button>
          {/* <div className='collapse navbar-collapse' id='navbarNav'> */}
          <div className="navbar-collapse flex " id="navbarNav">
            <ul className="navbar-nav ms-auto view_navbarNav">
              <li className="nav-item ms-auto">
                <NavLink to="/settings" className="nav-profile-setting nav-link ">
                  <div className="nav-profile-details me-2">
                    <div className="fs-6 font-medium">
                      {userInfo !== null ? userInfo?.userName : "Example Name"}
                    </div>
                    <div className="fs-6 font-medium">
                      {userInfo !== null ? userInfo?.orgDetails?.name : "Example Org"}
                    </div>
                  </div>
                  <img
                    className="img-fluid header-user-image"
                    src={
                      userInfo !== null && userInfo?.profileImage !== null
                        ? `data:image/png;base64,${userInfo?.profileImage}`
                        : "/assets/fitpeo_logo.png"
                    }
                    alt="User Profile"
                  />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default RPMViewNavbar
