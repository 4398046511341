import { Button } from "antd"
import { useEffect, useState } from "react"
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"
import Loading from "../layouts/Loading/Loading"

const ImageCarousel = ({ next, patientId, prevPage }) => {
  patientId = JSON.parse(localStorage.getItem("id"));

  const { loading, data:userWelcomeTutorial, error } = useSelector(state => state.getSelfOnboardedPatient)

  const [currentImageCount, setCurrentImageCount] = useState(1)
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const [isSwippedScreen, setIsSwippedScreen] = useState(false)
  const [welcomeImages, setWelcomeImages] = useState([])
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  })

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50

  const onTouchStart = e => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
    setIsSwippedScreen(false)
  }

  const onTouchMove = e => setTouchEnd(e.targetTouches[0].clientX)
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isRightSwipe || (isLeftSwipe && screenSize.dynamicWidth <= 768)) {
      setIsSwippedScreen(true)
    }
  }
  const rightClickHandler = () => {
    if (!(welcomeImages.length === currentImageCount)) {
      setCurrentImageCount(prev => prev + 1)
    } else if (welcomeImages.length === currentImageCount) {
      next()
    }
  }
  const leftClickHandler = () => {
    if (currentImageCount !== 1) {
      setCurrentImageCount(prev => prev - 1)
    }
  }
  const skipClickHandler = () => {
    next()
  }

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    if(userWelcomeTutorial)
    {
     const images = Object.entries(userWelcomeTutorial?.tutorials).map(([id, bgurl]) => ({id: parseInt(id), bgurl}))
      setWelcomeImages(images)
    }
  }, [loading])
  
  useEffect(() => {
    window.addEventListener("resize", setDimension)

    return () => {
      window.removeEventListener("resize", setDimension)
    }
  }, [screenSize])

  window.back = () => {
    prevPage()
 }
  return (
    <>
     <Helmet>
        <script>{`
                if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.firstPage) {
                  window.webkit.messageHandlers.firstPage.postMessage(false);
                   } else {
                   console.error("window.webkit.messageHandlers.firstPage is undefined. Unable to post message.");
                 }
                 if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.navigationTitle) {
                  window.webkit.messageHandlers.navigationTitle.postMessage("");
                   } else {
                   console.error("window.webkit.messageHandlers.navigationTitle is undefined. Unable to post message.");
                 }
                `}
       </script>
      </Helmet>
      <>{!loading ? <div
      className="relative h-screen bg-cover bg-center w-full"
      style={{ backgroundImage: `url(${welcomeImages[currentImageCount - 1]?.bgurl})` }}>
      <div className="flex items-center  w-full backdrop-blur-xl ">
        {screenSize.dynamicWidth >= 768 ? (
          <div
            className={`${
              currentImageCount === 1
                ? "bg-[#959595] cursor-not-allowed"
                : "bg-white cursor-pointer"
            } rounded-full h-9 w-10 flex align-center justify-center items-center ml-2`}
            onClick={() => leftClickHandler()}>
            <FaChevronLeft size={15} className="" />
          </div>
        ) : null}
        <div
          alt="bg-screen"
          className="bg-no-repeat bg-center h-screen object-cover  w-full mx-auto z-40"
          style={{ backgroundImage: `url(${welcomeImages[currentImageCount - 1]?.bgurl})` }}>
          <div className={`${isSwippedScreen ? "max-md:bg-[#000] max-md:bg-opacity-70" : ""}`}>
            <div className="pt-[1.125rem] pb-2 px-[1.25rem] flex w-full gap-1">
              {welcomeImages.map(img => (
                <div
                  key={img.id}
                  className={`h-1 rounded-[1.25rem]`}
                  style={{
                    width: `calc(100% / ${welcomeImages.length})`,
                    backgroundColor: `${img.id <= currentImageCount ? "#000" : "#939393"}`
                  }}></div>
              ))}
            </div>
            <svg
              onClick={skipClickHandler}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              className="ml-auto mr-5 cursor-pointer">
              <path
                d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"
                fill="black"
              />
            </svg>
          </div>
          <div
            className="flex h-[78%] "
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}>
            <div
              className={`w-50  ${
                isSwippedScreen
                  ? "h-screen max-md:border-dashed max-md:border-r-2 border-white flex justify-center items-center flex-col max-md:bg-[#000] max-md:bg-opacity-70 "
                  : ""
              } `}
              onClick={() => leftClickHandler()}>
              {isSwippedScreen && screenSize.dynamicWidth <= 768 ? (
                <>
                  <div
                    className={`${
                      currentImageCount === 1 ? "bg-[#959595] " : "bg-white "
                    } rounded-full  h-9 w-9 flex  justify-center items-center`}>
                    <FaChevronLeft size={15} className="" />
                  </div>
                  <p
                    className={`${
                      currentImageCount === 1 ? "text-[#959595] " : "text-white "
                    } mt-2`}>
                    Tab Back
                  </p>
                </>
              ) : null}
            </div>
            <div
              className={` w-50 ${
                isSwippedScreen
                  ? "h-screen flex flex-col items-center justify-center  max-md:bg-[#000] max-md:bg-opacity-70"
                  : ""
              }`}
              onClick={() => rightClickHandler()}>
              {isSwippedScreen && screenSize.dynamicWidth <= 768 ? (
                <>
                  <div className="bg-white rounded-full  h-9 w-9 flex justify-center items-center">
                    <FaChevronRight size={15} className=" " />
                  </div>
                  <p className="text-white mt-2">Tab Next</p>
                </>
              ) : null}
            </div>
          </div>
          <div className={`flex justify-end`}>
            <Button
              type="primary"
              className="font-semibold text-[1rem] w-[30vh] mr-4 mb-4 sm:mb-10"
              style={{minWidth:0}}
              onClick={() => skipClickHandler()}>
              Skip
            </Button>
          </div>
        </div>
        {screenSize.dynamicWidth >= 768 ? (
          <div
            className="bg-white rounded-full  h-9 w-11 flex justify-center items-center mr-2"
            onClick={() => rightClickHandler()}>
            <FaChevronRight size={15} className=" " />
          </div>
        ) : null}
      </div>
    </div> : <Loading/>}</>  
    
    </>
  )
}
export default ImageCarousel
