

import { Col, Input, Row, Slider } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { getPatientBpCustomParameter } from "../../redux/actions/customParameterActions"

const PatientProgressbar = ({ patientId, bpData }) => {
  const [sysValues, setSysValues] = useState([10, 90, 121, 141, 161, 300])
  const [marksSys, setMarksSys] = useState({})
  const [diaValues, setDiaValues] = useState([10, 60, 81, 91, 100, 150])
  const [marksDia, setMarksDia] = useState({})
  const [pulseValues, setPulseValues] = useState([10, 60, 100, 250])
  const [marksPulse, setMarksPulse] = useState({})
  const [sysValuePercent, setSysValuePercent] = useState(0);
  const [diaValuePercent, setDiaValuePercent] = useState(0);
  const [pulseValuePercent, setPulseValuePercent] = useState(0);

  const [sysStageWidth, setSysStageWidth] = useState({
    low: 20,
    normal: 20,
    preHypertension: 20,
    stage1Hypertension: 20,
    stage2Hypertension: 20,
  });
  const [diaStageWidth, setDiaStageWidth] = useState({
    low: 20,
    normal: 20,
    preHypertension: 20,
    stage1Hypertension: 20,
    stage2Hypertension: 20,
  });
  const [pulseStageWidth, setPulseStageWidth] = useState({
    low: 20,
    normal: 20,
    high: 20,
  });

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  // selectors
  const { loading: BpCustomParameterLoading, bpCustomParameters } = useSelector(state => state.getPatientBpCustomParameter)

  useEffect(() => {
    if (!BpCustomParameterLoading) {
      if (bpCustomParameters?.hasOwnProperty("id") && bpCustomParameters?.hasOwnProperty("lowerLimitSystolic")) {
        let customSysValueArr = [];
        let customDiaValueArr = [];
        let customPulseValueArr = [];
        const sysValuesOrder = ["lowerLimitSystolic", "normalSystolic", "preHyperTensionSystolic", "stageOneHyperTensionSystolic", "stageTwoHyperTensionSystolic", "higherLimitSystolic"]
        const diaValuesOrder = ["lowerLimitDiastolic", "normalDiastolic", "preHyperTensionDiastolic", "stageOneHyperTensionDiastolic", "stageTwoHyperTensionDiastolic", "higherLimitDiastolic"]
        const pulseValueOrder = ["bpmLow", "bpmNormalLow", "bpmNormalHigh", "bpmHigh"]
        for (let i = 0; i < sysValuesOrder.length; i++) {
          customSysValueArr.push(bpCustomParameters && bpCustomParameters[sysValuesOrder[i]])
        }
        setSysValues(customSysValueArr);
        for (let j = 0; j < diaValuesOrder.length; j++) {
          customDiaValueArr.push(bpCustomParameters && bpCustomParameters[diaValuesOrder[j]])
        }
        setDiaValues(customDiaValueArr)
        for (let k = 0; k < pulseValueOrder.length; k++) {
          customPulseValueArr.push(bpCustomParameters && bpCustomParameters[pulseValueOrder[k]])
        }
        setPulseValues(customPulseValueArr);
      } else {
        setSysValues([10, 90, 121, 141, 161, 300])
        setDiaValues([10, 60, 81, 91, 100, 150])
        setPulseValues([10, 60, 100, 250])
      }
    }
  }, [bpCustomParameters])

  function calculatePercentage(start, end, minValue, maxValue) {
    const range = maxValue - minValue;
    const percentage = ((end - start) / range) * 100;
    return parseInt(percentage)
  }

  useEffect(() => {
    if (!BpCustomParameterLoading) {
      dispatch(getPatientBpCustomParameter(patientId))
    }
  }, [])

  useEffect(() => {
    const firstToSecondPoint = calculatePercentage(sysValues[0], sysValues[1], sysValues[0], sysValues[sysValues.length - 1]);
    const secondToThirdPoint = calculatePercentage(sysValues[1], sysValues[2], sysValues[0], sysValues[sysValues.length - 1]);
    const thirdToFourthPoint = calculatePercentage(sysValues[2], sysValues[3], sysValues[0], sysValues[sysValues.length - 1]);
    const fourthToFifthPoint = calculatePercentage(sysValues[3], sysValues[4], sysValues[0], sysValues[sysValues.length - 1]);
    const fifthToEndPoint = calculatePercentage(sysValues[4], sysValues[sysValues.length - 1], sysValues[0], sysValues[sysValues.length - 1]);

    setSysStageWidth({
      ...sysStageWidth,
      low: firstToSecondPoint,
      normal: secondToThirdPoint,
      preHypertension: thirdToFourthPoint,
      stage1Hypertension: fourthToFifthPoint,
      stage2Hypertension: fifthToEndPoint,
    })
  }, [sysValues])

  useEffect(() => {
    const firstToSecondPoint = calculatePercentage(diaValues[0], diaValues[1], diaValues[0], diaValues[diaValues.length - 1]);
    const secondToThirdPoint = calculatePercentage(diaValues[1], diaValues[2], diaValues[0], diaValues[diaValues.length - 1]);
    const thirdToFourthPoint = calculatePercentage(diaValues[2], diaValues[3], diaValues[0], diaValues[diaValues.length - 1]);
    const fourthToFifthPoint = calculatePercentage(diaValues[3], diaValues[4], diaValues[0], diaValues[diaValues.length - 1]);
    const fifthToEndPoint = calculatePercentage(diaValues[4], diaValues[diaValues.length - 1], diaValues[0], diaValues[diaValues.length - 1]);

    setDiaStageWidth({
      ...diaStageWidth,
      low: firstToSecondPoint,
      normal: secondToThirdPoint,
      preHypertension: thirdToFourthPoint,
      stage1Hypertension: fourthToFifthPoint,
      stage2Hypertension: fifthToEndPoint,
    })
  }, [diaValues])

  useEffect(() => {
    const firstToSecondPoint = calculatePercentage(pulseValues[0], pulseValues[1], pulseValues[0], pulseValues[pulseValues.length - 1]);
    const secondToThirdPoint = calculatePercentage(pulseValues[1], pulseValues[2], pulseValues[0], pulseValues[pulseValues.length - 1]);
    const thirdToEndPoint = calculatePercentage(pulseValues[2], pulseValues[pulseValues.length - 1], pulseValues[0], pulseValues[pulseValues.length - 1]);

    setPulseStageWidth({
      ...pulseStageWidth,
      low: firstToSecondPoint,
      normal: secondToThirdPoint,
      high: thirdToEndPoint
    })
  }, [pulseValues])

  useEffect(() => {
    const newSysvalue = sysValues.slice(1, -1)
    const newMarksSys = newSysvalue.reduce((acc, value, index) => {
      acc[value] = {
        label: (<>{value}</>),
        style: {
          position: "absolute",
          top: "-50px",
          fontSize: "16px"
        }
      };
      return acc;
    }, {});
    setMarksSys(newMarksSys)
    const sysValPercent = calculatePercentage(sysValues[0], bpData?.[0]?.systolicPressure, sysValues[0], sysValues[sysValues.length - 1])
    setSysValuePercent(sysValPercent);
  }, [sysValues])

  useEffect(() => {
    const newdiavalue = diaValues.slice(1, -1)
    const newMarksDia = newdiavalue.reduce((acc, value, index) => {
      acc[value] = {
        label: (<>{value}</>),
        style: {
          position: "absolute",
          top: "-50px",
          fontSize: "16px"
        }
      };
      return acc;
    }, {});
    setMarksDia(newMarksDia)
    const diaValPercent = calculatePercentage(diaValues[0], bpData?.[0]?.diastolicPressure, diaValues[0], diaValues[diaValues.length - 1])
    setDiaValuePercent(diaValPercent);
  }, [diaValues])

  useEffect(() => {
    const newPulseValue = pulseValues.slice(1, -1)
    const newMarksPulse = newPulseValue.reduce((acc, value, index) => {
      acc[value] = {
        label: (<>{value}</>),
        style: {
          position: "absolute",
          top: "-50px",
          fontSize: "16px"
        }
      };
      return acc;
    }, {});
    setMarksPulse(newMarksPulse)
    const pulseValPercent = calculatePercentage(pulseValues[0], bpData?.[0]?.heartRate, pulseValues[0], pulseValues[pulseValues.length - 1])
    setPulseValuePercent(pulseValPercent);
  }, [pulseValues])

  const trackColors = ["#ff3b30", "#34c759", "#ffcc00", "#ffcc00", "#ff3b30"]
  const pulseTrackColors = ["#ff3b30", "#34c759", "#ff3b30"]

  return (
    <div>
      <div className="flex flex-col gap-4 mb-5">
        <div className="w-min border-[1px] border-current rounded p-4 text-center">
          <h6 className="text-lg font-bold text-secondary mb-2">Systolic</h6>
          <p>mmHg</p>
          <p className="font-bold text-4xl mb-0">
            {bpData?.[0]?.systolicPressure}
          </p>
        </div>
        <div className="relative">
          <Slider
            range
            min={10}
            max={300}
            draggableTrack={false}
            // onChange={setSysValues}
            value={sysValues}
            marks={marksSys}
            handleStyle={{ display: "none" }}
            step={null}
            dots={true}
            dotStyle={{ borderColor: 'transparent', backgroundColor: 'transparent', width: 0, height: 0 }} // Hide dots
            trackStyle={trackColors.map((color, index) => ({
              backgroundColor: color
            }))}
          />
          <div className="absolute top-[12px] rounded-full bg-[#0c66e4] cursor-pointer" style={{ width: "14px", height: "14px", left: `${sysValuePercent}%` }}></div>
          <Row className="flex justify-between">
            <Col className="flex justify-center items-center font-medium" style={{ width: `${sysStageWidth.low}%` }}>Low</Col>
            <Col className="flex justify-center items-center font-medium" style={{ width: `${sysStageWidth.normal}%` }}>Normal</Col>
            <Col className="flex justify-center items-center font-medium break-words" style={{ width: `${sysStageWidth.preHypertension}%` }}>{sysStageWidth.preHypertension < 8 ? "Pre-Hyper- tension" : "Pre-Hypertension"}</Col>
            <Col className="flex justify-center items-center font-medium break-words" style={{ width: `${sysStageWidth.stage1Hypertension}%` }}>{sysStageWidth.stage1Hypertension < 8 ? "Stage 1 Hyper- tension" : "Stage 1 Hypertension"}</Col>
            <Col className="flex justify-center items-center font-medium break-words" style={{ width: `${sysStageWidth.stage2Hypertension}%` }}>{sysStageWidth.stage2Hypertension < 8 ? "Stage 2 Hyper- tension" : "Stage 2 Hypertension"}</Col>
          </Row>
        </div>
        <div className="w-min border-[1px] border-current rounded p-4 text-center">
          <h6 className="text-lg font-bold text-secondary mb-2">Diastolic</h6>
          <p>mmHg</p>
          <p className="font-bold text-4xl mb-0">
            {bpData?.[0]?.diastolicPressure}
          </p>
        </div>
        <div className="relative">
          <Slider
            range
            min={10}
            max={150}
            draggableTrack={false}
            // onChange={setDiaValues}
            value={diaValues}
            marks={marksDia}
            handleStyle={{ display: "none" }}
            step={null}
            dots={true}
            dotStyle={{ borderColor: 'transparent', backgroundColor: 'transparent', width: 0, height: 0 }} // Hide dots
            trackStyle={trackColors.map((color, index) => ({
              backgroundColor: color
            }))}
          />
          <div className="absolute top-[12px] rounded-full bg-[#0c66e4] cursor-pointer" style={{ width: "14px", height: "14px", left: `${diaValuePercent}%` }}></div>
          <Row className="flex justify-between">
            <Col className="flex justify-center items-center font-medium " style={{ width: `${diaStageWidth.low}%` }}>Low</Col>
            <Col className="flex justify-center items-center font-medium " style={{ width: `${diaStageWidth.normal}%` }}>Normal</Col>
            <Col className="flex justify-center items-center font-medium break-words" style={{ width: `${diaStageWidth.preHypertension}%` }}>{diaStageWidth.preHypertension < 8 ? "Pre-Hyper- tension" : "Pre-Hypertension"}</Col>
            <Col className="flex justify-center items-center font-medium break-words" style={{ width: `${diaStageWidth.stage1Hypertension}%` }}>{diaStageWidth.stage1Hypertension < 8 ? "Stage 1 Hyper- tension" : "Stage 1 Hypertension"}</Col>
            <Col className="flex justify-center items-center font-medium break-words" style={{ width: `${diaStageWidth.stage2Hypertension}%` }}>{diaStageWidth.stage2Hypertension < 8 ? "Stage 2 Hyper- tension" : "Stage 2 Hypertension"}</Col>
          </Row>
        </div>
        <div className="w-min border-[1px] border-current rounded p-4 text-center">
          <h6 className="text-lg font-bold text-secondary mb-2">Pulse</h6>
          <p>BPM</p>
          <p className="font-bold text-4xl mb-0">{bpData?.[0]?.heartRate}</p>
        </div>
        <div className="relative">
          <Slider
            range
            min={10}
            max={250}
            draggableTrack={false}
            // onChange={setPulseValues}
            value={pulseValues}
            marks={marksPulse}
            handleStyle={{ display: "none" }}
            step={null}
            dots={true}
            dotStyle={{ borderColor: 'transparent', backgroundColor: 'transparent', width: 0, height: 0 }} // Hide dots
            trackStyle={pulseTrackColors.map((color, index) => ({
              backgroundColor: color
            }))}
          />
          <div className="absolute top-[12px] rounded-full bg-[#0c66e4] cursor-pointer" style={{ width: "14px", height: "14px", left: `${pulseValuePercent}%` }}></div>
          <Row className="flex justify-between">
            <Col className="flex justify-center items-center font-medium " style={{ width: `${pulseStageWidth.low}%` }}>Low</Col>
            <Col className="flex justify-center items-center font-medium " style={{ width: `${pulseStageWidth.normal}%` }}>Normal</Col>
            <Col className="flex justify-center items-center font-medium " style={{ width: `${pulseStageWidth.high}%` }}>High</Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default PatientProgressbar
