import {
  GET_RPM_FAIL,
  GET_RPM_REQUEST,
  GET_RPM_SUCCESS,
  POST_RPM_USER_FAIL,
  POST_RPM_USER_REQUEST,
  POST_RPM_USER_SUCCESS,
  GET_RPM_CAREPLAN_REQUEST,
  GET_RPM_CAREPLAN_SUCCESS,
  GET_RPM_CAREPLAN_FAIL,
  GET_RPM_FOLLOW_UP_REQUIEST,
  GET_RPM_FOLLOW_UP_SUCCESS,
  GET_RPM_FOLLOW_UP_FAIL,
  POST_RPM_TRIAGE_REQUEST,
  POST_RPM_TRIAGE_SUCCESS,
  POST_RPM_TRIAGE_FAIL,
  GET_RPM_DEVICE_REQUEST,
  GET_RPM_DEVICE_SUCCESS,
  GET_RPM_DEVICE_FAIL
} from "../types/rpmConstants"
import { axiosGet, axiosPost } from "../axios"
import { userLogoutAction } from "./userActions"

export const getrpmDataActions = moniterId => async dispatch => {
  try {
    dispatch({
      type: GET_RPM_REQUEST
    })
    const { data } = await axiosGet(`/caretaker/v1/getUsersForMoniterType?moniterId=${moniterId}`)
    dispatch({
      type: GET_RPM_SUCCESS,
      payload: data.result
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_RPM_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}
export const postRPMUserActions = (userId, formInputs) => async dispatch => {
  try {
    dispatch({
      type: POST_RPM_USER_REQUEST
    })

    // console.log('formInputs from action',formInputs);
    // debugger

    const { data } = await axiosPost(`/caretaker/v1/postRPMCareplan`, {
      userId,
      careplan: { ...formInputs }
    })
    dispatch({
      type: POST_RPM_USER_SUCCESS,
      payload: data
    })
    // window.location.href = '/rpm-newPatient';
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    console.log("error", error)
    dispatch({
      type: POST_RPM_USER_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.detail
    })
  }
}

export const getrpmCareplanDataActions = patientId => async dispatch => {
  try {
    dispatch({
      type: GET_RPM_CAREPLAN_REQUEST
    })
    
    const { data } = await axiosGet(`/api/rpm/readings/treatmentplan/latest/${patientId}/fetch`)

    dispatch({
      type: GET_RPM_CAREPLAN_SUCCESS,
      payload: data?.details // also getting careplanDetails
    })
    return data?.details
  } catch (error) {
    console.debug(error?.response)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_RPM_CAREPLAN_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getUsersForRpmFollowUpAction = (month, year, orgId) => async dispatch => {
  try {
    dispatch({
      type: GET_RPM_FOLLOW_UP_REQUIEST
    })
    const { data } = await axiosGet(
      `/caretaker/v1/getUserForRPMFollowUpPlan?month=${month}&year=${year}&orgId=${orgId}`
    )
    dispatch({
      type: GET_RPM_FOLLOW_UP_SUCCESS,
      payload: data.result
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_RPM_FOLLOW_UP_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const postRpmTriageAction = (userId, report) => async dispatch => {
  try {
    dispatch({
      type: POST_RPM_TRIAGE_REQUEST
    })

    const { data } = await axiosPost(`/caretaker/v1/postUserTriageData`, { userId, report })
    dispatch({
      type: POST_RPM_TRIAGE_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    console.log("error", error)
    dispatch({
      type: POST_RPM_TRIAGE_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.detail
    })
  }
}

export const getrpmDeviceActions = () => async dispatch => {
  try {
    dispatch({
      type: GET_RPM_DEVICE_REQUEST
    })
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        "Content-Type": "application/json"
      }
    }
    const { data } = await axiosGet("/api/cs/config/monitorTypes", config)
    dispatch({
      type: GET_RPM_DEVICE_SUCCESS,
      payload: data.details
    })
    localStorage.setItem("monitorTypes", JSON.stringify(data?.details))
    return data
  } catch (error) {
    error?.response?.status === 401 && (!window.isMobileVersion ? dispatch(userLogoutAction()) : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" }))
    dispatch({
      type: GET_RPM_DEVICE_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}
