import {
  ADD_MANUAL_TIME_FAIL,
  ADD_MANUAL_TIME_REQUEST,
  ADD_MANUAL_TIME_SUCCESS,
  ADD_PATIENT_FAIL,
  ADD_PATIENT_REQUEST,
  ADD_PATIENT_SUCCESS,
  PATIENT_BPR_FAIL,
  PATIENT_BPR_REQUEST,
  PATIENT_BPR_SUCCESS,
  PATIENT_BP_FAIL,
  PATIENT_BP_REQUEST,
  PATIENT_BP_SUCCESS,
  PATIENT_HEALTH_KPI_FAIL,
  PATIENT_HEALTH_KPI_REQUEST,
  PATIENT_HEALTH_KPI_SUCCESS,
  PATIENT_INFO_FAIL,
  PATIENT_INFO_REQUEST,
  PATIENT_INFO_SUCCESS,
  PATIENT_OXYR_FAIL,
  PATIENT_OXYR_REQUEST,
  PATIENT_OXYR_SUCCESS,
  PATIENT_OXY_FAIL,
  PATIENT_OXY_REQUEST,
  PATIENT_OXY_SUCCESS,
  PATIENT_GLUCOSE_FAIL,
  PATIENT_GLUCOSE_REQUEST,
  PATIENT_GLUCOSE_SUCCESS,
  PATIENT_LATEST_READING_FAIL,
  PATIENT_LATEST_READING_REQUEST,
  PATIENT_LATEST_READING_SUCCESS,
  PATIENT_TIMELINE_FAIL,
  PATIENT_TIMELINE_REQUEST,
  PATIENT_TIMELINE_SUCCESS,
  PATIENT_WMR_FAIL,
  PATIENT_WMR_REQUEST,
  PATIENT_WMR_SUCCESS,
  GET_CHRONIC_CONDITION_REQUEST,
  GET_CHRONIC_CONDITION_SUCCESS,
  GET_CHRONIC_CONDITION_FAIL,
  GET_MANUAL_TIME_REQUEST,
  GET_MANUAL_TIME_SUCCESS,
  GET_MANUAL_TIME_FAIL,
  CRITICAL_STATUS_REQUEST,
  CRITICAL_STATUS_SUCCESS,
  CRITICAL_STATUS_FAIL,
  CHRONIC_TYPE_REQUEST,
  CHRONIC_TYPE_SUCCESS,
  CHRONIC_TYPE_FAIL,
  POST_CHRONIC_DATA_REQUEST,
  POST_CHRONIC_DATA_SUCCESS,
  POST_CHRONIC_DATA_FAIL,
  UPDATE_PATIENT_REQUEST,
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAIL,
  GET_REPORT_DATA_REQUEST,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAIL,
  PATIENT_WM_REQUEST,
  PATIENT_WM_SUCCESS,
  PATIENT_WM_FAIL,
  UPDATE_PATIENT_LANDLINE_REQUEST,
  UPDATE_PATIENT_LANDLINE_SUCCESS,
  UPDATE_PATIENT_LANDLINE_FAIL,
  POST_CONCERN_REQUEST,
  POST_CONCERN_SUCCESS,
  POST_CONCERN_FAIL,
  GET_CONCERN_REQUEST,
  GET_CONCERN_SUCCESS,
  GET_CONCERN_FAIL,
  GET_USER_PAST_CONSULTATION_REQUEST,
  GET_USER_PAST_CONSULTATION_SUCCESS,
  GET_USER_PAST_CONSULTATION_FAIL,
  GET_USER_FUTURE_CONSULTATION_REQUEST,
  GET_USER_FUTURE_CONSULTATION_SUCCESS,
  GET_USER_FUTURE_CONSULTATION_FAIL,
  POST_CALL_REQUEST,
  POST_CALL_SUCCESS,
  POST_CALL_FAIL,
  GET_USER_MONITOR_TYPE_REQUEST,
  GET_USER_MONITOR_TYPE_SUCCESS,
  GET_USER_MONITOR_TYPE_FAIL,
  GET_USER_BOOKING_REQUEST,
  GET_USER_BOOKING_SUCCESS,
  GET_USER_BOOKING_FAIL,
  POST_USER_CONSULTATION_REQUEST,
  POST_USER_CONSULTATION_SUCCESS,
  POST_USER_CONSULTATION_FAIL,
  POST_USER_CANCEL_CONSULTATION_REQUEST,
  POST_USER_CANCEL_CONSULTATION_SUCCESS,
  POST_USER_CANCEL_CONSULTATION_FAIL,
  PATIENT_VITALS_REQUEST,
  PATIENT_VITALS_SUCCESS,
  PATIENT_VITALS_FAIL,
  GET_PATIENT_CARETAKER_REQUEST,
  GET_PATIENT_CARETAKER_SUCCESS,
  GET_PATIENT_CARETAKER_FAIL,
  PATIENT_MONITORING_REQUEST,
  PATIENT_MONITORING_SUCCESS,
  PATIENT_MONITORING_FAIL,
  PATIENT_DIAGNOSTIC_REQUEST,
  PATIENT_DIAGNOSTIC_SUCCESS,
  PATIENT_DIAGNOSTIC_FAIL,
  PATIENT_CRITICAL_DEVICES_REQUEST,
  PATIENT_CRITICAL_DEVICES_SUCCESS,
  PATIENT_CRITICAL_DEVICES_FAIL,
  POST_EMERGENCY_CONTACT_DETAILS_REQUEST,
  POST_EMERGENCY_CONTACT_DETAILS_SUCCESS,
  POST_EMERGENCY_CONTACT_DETAILS_FAIL,
  POST_PATIENT_VITALS_REQUEST,
  POST_PATIENT_VITALS_SUCCESS,
  POST_PATIENT_VITALS_FAIL,
  POST_PATIENT_VITALS_RESET,
  POST_SHIPPING_ADDRESS_REQUEST,
  POST_SHIPPING_ADDRESS_SUCCESS,
  POST_SHIPPING_ADDRESS_FAIL,
  GET_SHIPPING_ADDRESS_REQUEST,
  GET_SHIPPING_ADDRESS_SUCCESS,
  GET_SHIPPING_ADDRESS_FAIL,
  UPDATE_SHIPPING_ADDRESS_REQUEST,
  UPDATE_SHIPPING_ADDRESS_SUCCESS,
  UPDATE_SHIPPING_ADDRESS_FAIL,
  UPDATE_EMERGENCY_CONTACT_DETAILS_REQUEST,
  UPDATE_EMERGENCY_CONTACT_DETAILS_SUCCESS,
  UPDATE_EMERGENCY_CONTACT_DETAILS_FAIL,
  GET_EMERGENCY_CONTACT_DETAILS_REQUEST,
  GET_EMERGENCY_CONTACT_DETAILS_SUCCESS,
  GET_EMERGENCY_CONTACT_DETAILS_FAIL
} from "../types/PatientConstant"

const initialState = {
  patientHealthKpi: {},
  patientWmDetail: {},
  patientBpDetail: {},
  patientOxyDetail: {},
  patientGlucoseDetail: {},
  patientInfoDetail: {},
  patientLatestReading: {},
  patientTimeline: {},
  patientWmReading: {},
  patientBpReading: {},
  patientOxyReading: {},
  patientVitals: {},
  patientCaretaker: {},
  patientMonitoringData: {},
  patientDiagnosticData: {},
  patientCriticalDevices: {}
}

export const PatientCriticalDevicesReducer = (
  state = initialState.patientCriticalDevices,
  action
) => {
  switch (action.type) {
    case PATIENT_CRITICAL_DEVICES_REQUEST:
      return { loading: true }
    case PATIENT_CRITICAL_DEVICES_SUCCESS:
      return { loading: false, patientCriticalDevices: action.payload }
    case PATIENT_CRITICAL_DEVICES_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const PatientDiagnosticReducer = (state = initialState.patientDiagnosticData, action) => {
  switch (action.type) {
    case PATIENT_DIAGNOSTIC_REQUEST:
      return { loading: true }
    case PATIENT_DIAGNOSTIC_SUCCESS:
      return { loading: false, patientDiagnosticData: action.payload }
    case PATIENT_DIAGNOSTIC_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const PatientMonitoringReducer = (state = initialState.patientMonitoringData, action) => {
  switch (action.type) {
    case PATIENT_MONITORING_REQUEST:
      return { loading: true }
    case PATIENT_MONITORING_SUCCESS:
      return { loading: false, patientMonitoringData: action.payload }
    case PATIENT_MONITORING_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

// get patient's caretaker list
export const patientCaretakerReducer = (state = initialState.patientCaretaker, action) => {
  switch (action.type) {
    case GET_PATIENT_CARETAKER_REQUEST:
      return { loading: true }
    case GET_PATIENT_CARETAKER_SUCCESS:
      return { loading: false, patientCaretaker: action.payload }
    case GET_PATIENT_CARETAKER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

// get vitals in v2 that contains all height & weight data
export const PatientVitalsReducer = (state = initialState.patientVitals, action) => {
  switch (action.type) {
    case PATIENT_VITALS_REQUEST:
      return { loading: true }
    case PATIENT_VITALS_SUCCESS:
      return { loading: false, patientVitals: action.payload }
    case PATIENT_VITALS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postPatientVitalsReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_PATIENT_VITALS_REQUEST:
      return { loading: true }
    case POST_PATIENT_VITALS_SUCCESS:
      return { loading: false }
    case POST_PATIENT_VITALS_FAIL:
      return { loading: false, error: action.payload }
    case POST_PATIENT_VITALS_RESET:
      return { loading: false, error: null }
    default:
      return state
  }
}

export const outpatientAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_PATIENT_ADDRESS_REQUEST":
      return { loading: true }
    case "GET_PATIENT_ADDRESS_SUCCESS":
      return { loading: false, outpatientAddress: action.payload }
    case "GET_PATIENT_ADDRESS_FAIL":
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const postOutpatientAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_PATIENT_ADDRESS_REQUEST":
      return { loading: true }
    case "UPDATE_PATIENT_ADDRESS_SUCCESS":
      return { loading: false }
    case "UPDATE_PATIENT_ADDRESS_FAIL":
      return { loading: false, error: action.payload }
    case "UPDATE_PATIENT_ADDRESS_RESET":
      return { loading: false, error: null }
    default:
      return state
  }
}

// add patient
export const AddPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_PATIENT_REQUEST:
      return { loading: true }
    case ADD_PATIENT_SUCCESS:
      return { loading: false, addPatient: action.payload }
    case ADD_PATIENT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

// Update patient
export const UpdatePatientReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PATIENT_REQUEST:
      return { loading: true }
    case UPDATE_PATIENT_SUCCESS:
      return { loading: false, updatePatient: action.payload }
    case UPDATE_PATIENT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

// add manual time
export const AddManualTime = (state = {}, action) => {
  switch (action.type) {
    case ADD_MANUAL_TIME_REQUEST:
      return { loading: true }
    case ADD_MANUAL_TIME_SUCCESS:
      return { loading: false, addManualTime: action.payload }
    case ADD_MANUAL_TIME_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const HealthKpiReducer = (state = initialState.patientHealthKpi, action) => {
  switch (action.type) {
    case PATIENT_HEALTH_KPI_REQUEST:
      return { loading: true }
    case PATIENT_HEALTH_KPI_SUCCESS:
      return { loading: false, patientHealthKpi: action.payload }
    case PATIENT_HEALTH_KPI_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const HealthWmReducer = (state = initialState.patientWmDetail, action) => {
  switch (action.type) {
    case PATIENT_WM_REQUEST:
      return { loading: true }
    case PATIENT_WM_SUCCESS:
      return { loading: false, patientWmDetail: action.payload }
    case PATIENT_WM_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const HealthBpReducer = (state = initialState.patientBpDetail, action) => {
  switch (action.type) {
    case PATIENT_BP_REQUEST:
      return { loading: true }
    case PATIENT_BP_SUCCESS:
      return { loading: false, patientBpDetail: action.payload }
    case PATIENT_BP_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const HealthOxyReducer = (state = initialState.patientOxyDetail, action) => {
  switch (action.type) {
    case PATIENT_OXY_REQUEST:
      return { loading: true }
    case PATIENT_OXY_SUCCESS:
      return { loading: false, patientOxyDetail: action.payload }
    case PATIENT_OXY_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const HealthGlucoseReducer = (state = initialState.patientOxyDetail, action) => {
  switch (action.type) {
    case PATIENT_GLUCOSE_REQUEST:
      return { loading: true }
    case PATIENT_GLUCOSE_SUCCESS:
      return { loading: false, patientGlucoseDetail: action.payload }
    case PATIENT_GLUCOSE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const PatientLatestReadingReducer = (state = initialState.patientLatestReading, action) => {
  switch (action.type) {
    case PATIENT_LATEST_READING_REQUEST:
      return { loading: true }
    case PATIENT_LATEST_READING_SUCCESS:
      return { loading: false, patientLatestReading: action.payload }
    case PATIENT_LATEST_READING_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const PatientInfoReducer = (state = initialState.patientInfoDetail, action) => {
  switch (action.type) {
    case PATIENT_INFO_REQUEST:
      return { loading: true }
    case PATIENT_INFO_SUCCESS:
      return { loading: false, patientInfoDetail: action.payload }
    case PATIENT_INFO_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const patientTimelineReducer = (state = initialState.patientTimeline, action) => {
  switch (action.type) {
    case PATIENT_TIMELINE_REQUEST:
      return { loading: true }
    case PATIENT_TIMELINE_SUCCESS:
      return { loading: false, patientTimeline: action.payload }
    case PATIENT_TIMELINE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const patientWmReadingReducer = (state = initialState.patientWmReading, action) => {
  switch (action.type) {
    case PATIENT_WMR_REQUEST:
      return { loading: true }
    case PATIENT_WMR_SUCCESS:
      return { loading: false, patientWmReading: action.payload }
    case PATIENT_WMR_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const patientBpReadingReducer = (state = initialState.patientBpReading, action) => {
  switch (action.type) {
    case PATIENT_BPR_REQUEST:
      return { loading: true }
    case PATIENT_BPR_SUCCESS:
      return { loading: false, patientBpReading: action.payload }
    case PATIENT_BPR_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const patientOxyReadingReducer = (state = initialState.patientOxyReading, action) => {
  switch (action.type) {
    case PATIENT_OXYR_REQUEST:
      return { loading: true }
    case PATIENT_OXYR_SUCCESS:
      return { loading: false, patientOxyReading: action.payload }
    case PATIENT_OXYR_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getManualTimeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MANUAL_TIME_REQUEST:
      return { loading: true }
    case GET_MANUAL_TIME_SUCCESS:
      return { loading: false, manualTimeList: action.payload }
    case GET_MANUAL_TIME_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getChronicConditionReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CHRONIC_CONDITION_REQUEST:
      return { loading: true }
    case GET_CHRONIC_CONDITION_SUCCESS:
      return { loading: false, ChronicConcern: action.payload }
    case GET_CHRONIC_CONDITION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const criticalStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case CRITICAL_STATUS_REQUEST:
      return { loading: true }
    case CRITICAL_STATUS_SUCCESS:
      return { loading: false, criticalStatus: action.payload }
    case CRITICAL_STATUS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const chronicTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case CHRONIC_TYPE_REQUEST:
      return { loading: true }
    case CHRONIC_TYPE_SUCCESS:
      return { loading: false, chronicDeviceType: action.payload }
    case CHRONIC_TYPE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postChronicConditionReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_CHRONIC_DATA_REQUEST:
      return { loading: true }
    case POST_CHRONIC_DATA_SUCCESS:
      return { loading: false, postChronicConditionStatus: action.payload }
    case POST_CHRONIC_DATA_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getPatientReportReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_REPORT_DATA_REQUEST:
      return { loading: true }
    case GET_REPORT_DATA_SUCCESS:
      return { loading: false, getPatientReport: action.payload }
    case GET_REPORT_DATA_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateLandlineReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PATIENT_LANDLINE_REQUEST:
      return { loading: true }
    case UPDATE_PATIENT_LANDLINE_SUCCESS:
      return { loading: false, updateLandline: action.payload }
    case UPDATE_PATIENT_LANDLINE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const addConcernReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_CONCERN_REQUEST:
      return { loading: true }
    case POST_CONCERN_SUCCESS:
      return { loading: false, patientConcern: action.payload }
    case POST_CONCERN_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getConcernReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CONCERN_REQUEST:
      return { loading: true }
    case GET_CONCERN_SUCCESS:
      return { loading: false, patientConcern: action.payload }
    case GET_CONCERN_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

///

export const getUserPastConsultationReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_PAST_CONSULTATION_REQUEST:
      return { loading: true }
    case GET_USER_PAST_CONSULTATION_SUCCESS:
      return { loading: false, patientPastConsultation: action.payload }
    case GET_USER_PAST_CONSULTATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getUserFutureConsultationReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_FUTURE_CONSULTATION_REQUEST:
      return { loading: true }
    case GET_USER_FUTURE_CONSULTATION_SUCCESS:
      return { loading: false, patientFutureConsultation: action.payload }
    case GET_USER_FUTURE_CONSULTATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postCallRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_CALL_REQUEST:
      return { loading: true }
    case POST_CALL_SUCCESS:
      return { loading: false, callRequest: action.payload }
    case POST_CALL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getUserMonitorTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_MONITOR_TYPE_REQUEST:
      return { loading: true }
    case GET_USER_MONITOR_TYPE_SUCCESS:
      return { loading: false, patientMonitorTypes: action.payload }
    case GET_USER_MONITOR_TYPE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getUserBookingReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_BOOKING_REQUEST:
      return { loading: true }
    case GET_USER_BOOKING_SUCCESS:
      return { loading: false, patientBooking: action.payload }
    case GET_USER_BOOKING_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postConsultationReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_USER_CONSULTATION_REQUEST:
      return { loading: true }
    case POST_USER_CONSULTATION_SUCCESS:
      return { loading: false, patientConsultation: action.payload }
    case POST_USER_CONSULTATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postCancelConsultationReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_USER_CANCEL_CONSULTATION_REQUEST:
      return { loading: true }
    case POST_USER_CANCEL_CONSULTATION_SUCCESS:
      return { loading: false, patientCancelConsultation: action.payload }
    case POST_USER_CANCEL_CONSULTATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postEmergencyContactDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_EMERGENCY_CONTACT_DETAILS_REQUEST:
      return { loading: true }
    case POST_EMERGENCY_CONTACT_DETAILS_SUCCESS:
      return { loading: false }
    case POST_EMERGENCY_CONTACT_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const updateEmergencyContactDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_EMERGENCY_CONTACT_DETAILS_REQUEST:
      return { loading: true }
    case UPDATE_EMERGENCY_CONTACT_DETAILS_SUCCESS:
      return { loading: false }
    case UPDATE_EMERGENCY_CONTACT_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getEmergencyContactDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_EMERGENCY_CONTACT_DETAILS_REQUEST:
      return { loading: true }
    case GET_EMERGENCY_CONTACT_DETAILS_SUCCESS:
      return { loading: false, getEmergencyContactDetails: action.payload }
    case GET_EMERGENCY_CONTACT_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postShippingAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_SHIPPING_ADDRESS_REQUEST:
      return { loading: true }
    case POST_SHIPPING_ADDRESS_SUCCESS:
      return { loading: false }
    case POST_SHIPPING_ADDRESS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateShippingAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SHIPPING_ADDRESS_REQUEST:
      return { loading: true }
    case UPDATE_SHIPPING_ADDRESS_SUCCESS:
      return { loading: false }
    case UPDATE_SHIPPING_ADDRESS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getShippingAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SHIPPING_ADDRESS_REQUEST:
      return { loading: true }
    case GET_SHIPPING_ADDRESS_SUCCESS:
      return { loading: false, shippingAddress: action.payload }
    case GET_SHIPPING_ADDRESS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}