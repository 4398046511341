import React from "react"

const CCMViewFormInput = props => {
  const { ques, handleChange, handleInput, otherInputs, setFormInputs } = props
  console.log("ques", ques)
  console.log("quesType", ques.quesType)
  console.log("ques-Value-type", typeof ques.value)

  switch (ques.quesType) {
    case "text":
      return ques.value !== undefined && ques.notApplicable === false ? (
        <div style={{ marginTop: "10px" }}>
          <textarea
            disabled
            id={ques.id}
            style={{ width: "100%", height: "50px" }}
            // value={ques.value ? ques.value : ""}
            onChange={e => {
              handleChange(ques.id, e)
            }}>
            {ques.value ? ques.value : ""}
          </textarea>
        </div>
      ) : (
        <div className="flex">
          <input
            disabled="true"
            style={{ marginRight: "5px" }}
            type="checkbox"
            // id={`custom-checkbox-${index}`}
            name="dsa"
            checked
            // onChange={(e) => handleChange(ques.id, e, index)}
          />
          <label style={{ fontSize: "12px" }}>Not Applicable</label>
        </div>
      )
    case "multi":
      return ques.value !== undefined && ques.notApplicable === false ? (
        ques.questionaryOptions?.map((opt, index) => (
          <div key={index} style={{ marginTop: "10px" }}>
            <input
              disabled="true"
              style={{ marginRight: "5px" }}
              type="checkbox"
              id={`custom-checkbox-${index}`}
              name="dsa"
              checked={ques.value ? ques.value[index] : false}
              onChange={e => handleChange(ques.id, e, index)}
            />
            <label style={{ fontSize: "12px" }}>{opt.name}</label>
            <br />
            {opt.name.trim() === "other" && ques.value && ques.value[index] === true && (
              <textarea
                // type="text"
                disabled="true"
                style={{ width: "100%", height: "30px", marginTop: "10px" }}
                // value={otherInputs[ques.id] ? otherInputs[ques.id].value : ""}
                onChange={e => {
                  setFormInputs({
                    ...otherInputs,
                    [ques.id]: { value: e.target.value }
                  })
                }}>
                {/* {ques.value['other']} */}
                {ques.value[ques.questionaryOptions.length + 1]?.other &&
                  ques.value[ques.questionaryOptions.length + 1].other}
                {/* {otherInputs[ques.id] ? otherInputs[ques.id].value : ""} */}
              </textarea>
            )}
          </div>
        ))
      ) : ques.ccmType === "CONSENT" ? (
        ques.questionaryOptions?.map((opt, index) => (
          <div key={index} style={{ marginTop: "10px" }}>
            <input
              disabled="true"
              style={{ marginRight: "5px" }}
              type="checkbox"
              id={`custom-checkbox-${index}`}
              name="dsa"
              checked={ques.value ? ques.value[index] : false}
              onChange={e => handleChange(ques.id, e, index)}
            />
            <label style={{ fontSize: "12px" }}>{opt.name}</label>
            <br />
            {opt.name.trim() === "other" && ques.value && ques.value[index] === true && (
              <textarea
                // type="text"
                disabled="true"
                style={{ width: "100%", height: "30px", marginTop: "10px" }}
                // value={otherInputs[ques.id] ? otherInputs[ques.id].value : ""}
                onChange={e => {
                  setFormInputs({
                    ...otherInputs,
                    [ques.id]: { value: e.target.value }
                  })
                }}>
                {/* {ques.value['other']} */}
                {ques.value[ques.questionaryOptions.length + 1]?.other &&
                  ques.value[ques.questionaryOptions.length + 1].other}
                {/* {otherInputs[ques.id] ? otherInputs[ques.id].value : ""} */}
              </textarea>
            )}
          </div>
        ))
      ) : (
        <div className="flex">
          <input
            disabled="true"
            style={{ marginRight: "5px" }}
            type="checkbox"
            // id={`custom-checkbox-${index}`}
            name="dsa"
            checked
            // onChange={(e) => handleChange(ques.id, e, index)}
          />
          <label style={{ fontSize: "12px" }}>Not Applicable</label>
        </div>
      )
    case "bool-text":
      return ques.value !== undefined && ques.notApplicable === false ? (
        <div style={{ marginTop: "10px" }}>
          <div style={{ display: "flex" }}>
            <div
              className="form-input"
              style={{
                display: "flex",
                alignItem: "center",
                marginRight: "20px",
                marginBottom: 0
              }}>
              <input
                type="radio"
                disabled
                id={ques.id}
                value={ques.value}
                name={ques.id}
                size="100"
                checked={ques?.value?.toString() === "true"}
                style={{ marginRight: "5px" }}
                onChange={e => handleChange(ques.id, e)}
              />
              <label style={{ fontSize: "14px" }}>YES</label>
            </div>
            <div
              className="form-input"
              style={{
                display: "flex",
                alignItem: "center",
                marginRight: "20px"
              }}>
              <input
                type="radio"
                disabled="true"
                id={ques.id}
                value={ques.value}
                name={ques.id}
                size="100"
                checked={ques?.value?.toString() === "false"}
                style={{ marginRight: "5px" }}
                onChange={e => handleChange(ques.id, e)}
              />
              <label style={{ fontSize: "14px" }}>NO</label>
            </div>
          </div>
          {((ques.onCondition && ques.value) || (!ques.onCondition && !ques.value)) &&
            ques.value !== undefined &&
            ques.questionaryOptions?.map((que, index) => (
              <div key={index} className="d2">
                <label
                  style={{
                    marginTop: "20px",
                    fontSize: "14px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "10px"
                  }}>
                  {que.name}
                </label>
                <textarea
                  id={que.id}
                  // type="text"
                  disabled="true"
                  style={{ width: "100%", height: "40px" }}
                  // value={que.value ? que.value : ""}
                  onChange={e => {
                    handleInput(ques.id, que.id, e)
                  }}>
                  {que.value ? que.value : ""}
                </textarea>
              </div>
            ))}
        </div>
      ) : (
        <div className="flex">
          <input
            disabled="true"
            style={{ marginRight: "5px" }}
            type="checkbox"
            // id={`custom-checkbox-${index}`}
            name="dsa"
            checked
            // onChange={(e) => handleChange(ques.id, e, index)}
          />
          <label style={{ fontSize: "12px" }}>Not Applicable</label>
        </div>
      )
    case "bool":
      return ques.value !== undefined && ques.notApplicable === false ? (
        ques.questionaryOptions?.length !== 0 ? (
          ques.questionaryOptions?.map((opt, index) => (
            <div key={index} style={{ display: "flex", marginBottom: "30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItem: "center",
                  marginRight: "20px",
                  marginBottom: 0
                }}>
                <input
                  type="radio"
                  disabled="true"
                  id={ques.id}
                  value="true"
                  name={ques.id}
                  checked={ques.value ? ques.value[index] : false}
                  style={{ marginRight: "5px" }}
                  size="100"
                  onChange={e => handleChange(ques.id, e)}
                />
                <label style={{ fontSize: "14px", marginRight: "16px" }}>YES</label>
                <input
                  type="radio"
                  disabled="true"
                  id={ques.id}
                  value="false"
                  name={ques.id}
                  checked={ques.value ? ques.value[index] : false}
                  size="100"
                  style={{ marginRight: "5px" }}
                  onChange={e => handleChange(ques.id, e)}
                />
                <label style={{ fontSize: "14px" }}>NO</label>
              </div>
            </div>
          ))
        ) : (
          <div style={{ display: "flex", marginBottom: "30px" }}>
            <div
              style={{
                display: "flex",
                alignItem: "center",
                marginRight: "20px",
                marginBottom: 0
              }}>
              <input
                type="radio"
                disabled="true"
                id={ques.id}
                value="true"
                name={ques.id}
                checked={ques?.value?.toString() === "true"}
                style={{ marginRight: "5px" }}
                size="100"
                onChange={e => handleChange(ques.id, e)}
              />
              <label style={{ fontSize: "14px", marginRight: "16px" }}>YES</label>
              <input
                type="radio"
                disabled="true"
                id={ques.id}
                value="false"
                name={ques.id}
                checked={ques?.value?.toString() === "false"}
                size="100"
                style={{ marginRight: "5px" }}
                onChange={e => handleChange(ques.id, e)}
              />
              <label style={{ fontSize: "14px" }}>NO</label>
            </div>
          </div>
        )
      ) : (
        <div className="flex">
          <input
            disabled="true"
            style={{ marginRight: "5px" }}
            type="checkbox"
            // id={`custom-checkbox-${index}`}
            name="dsa"
            checked
            // onChange={(e) => handleChange(ques.id, e, index)}
          />
          <label style={{ fontSize: "12px" }}>Not Applicable</label>
        </div>
      )
    case "text-multi":
      if (ques.value === undefined) {
        ques.value = [""]
      }
      return ques.value !== undefined && ques.notApplicable === false ? (
        <div style={{ marginTop: "10px" }}>
          {ques.value?.map((val, index) => (
            // debugger
            <textarea
              key={index}
              id={ques.id}
              // type="text"
              disabled="true"
              style={{
                width: "100%",
                height: "30px",
                marginBottom: "8px"
              }}
              value={ques.value[index] !== "" ? ques.value[index] : ""} // prev => Not added anything! in place of ""
              // value={que["value"] ? que["value"][ind] : ""}
              onChange={e => {
                handleChange(ques.id, e, index)
              }}
            />
            /* <textarea
                            style={{ width: "100%", height: "30px", marginBottom: "8px" }}
                            id={ques.id}
                            // type="text"
                            disabled="true"
                            defaultValue={ques.value[index].length !== '' ? ques.value[index] : 'Nothing is selected'}
                            // value={ques.value ? ques.value[index] : ""}
                            onChange={(e) => handleChange(ques.id, e, index)}
                        /> */
          ))}
        </div>
      ) : (
        <div className="flex">
          <input
            disabled="true"
            style={{ marginRight: "5px" }}
            type="checkbox"
            // id={`custom-checkbox-${index}`}
            name="dsa"
            checked
            // onChange={(e) => handleChange(ques.id, e, index)}
          />
          <label style={{ fontSize: "12px" }}>Not Applicable</label>
        </div>
      )
    case "bool-text-multi":
      ques.questionaryOptions.forEach((que, index) => {
        if (que.value === undefined) {
          que.value = [""] // eslint-disable-line
        }
      })
      return ques.value !== undefined && ques.notApplicable === false ? (
        <div style={{ marginBottom: "20px" }}>
          <div style={{ display: "flex" }}>
            <div
              className="form-input"
              style={{
                display: "flex",
                alignItem: "center",
                marginRight: "20px",
                marginBottom: "16px"
              }}>
              <input
                type="radio"
                disabled="true"
                id={ques.id}
                value="true"
                name={ques.id}
                size="100"
                checked={ques?.value?.toString() === "true"}
                style={{ marginRight: "5px" }}
                onChange={e => handleChange(ques.id, e)}
              />
              <label style={{ fontSize: "14px" }}>YES</label>
            </div>
            <div
              className="form-input"
              style={{
                display: "flex",
                alignItem: "center",
                marginRight: "20px",
                marginBottom: "16px"
              }}>
              <input
                type="radio"
                disabled="true"
                id={ques.id}
                value="false"
                name={ques.id}
                size="100"
                checked={ques?.value?.toString() === "false"}
                style={{ marginRight: "5px" }}
                onChange={e => handleChange(ques.id, e)}
              />
              <label style={{ fontSize: "14px" }}>NO</label>
            </div>
          </div>
          {((ques.onCondition && ques.value) || (!ques.onCondition && !ques.value)) &&
            ques.value !== undefined &&
            ques.questionaryOptions?.map((que, index) => (
              <div key={index} className="d2">
                <h5 style={{ marginBottom: "10px", fontSize: "14px" }}>{que.name}</h5>
                {que.value?.map((val, ind) => (
                  <textarea
                    key={ind}
                    id={que.id}
                    // type="text"
                    disabled="true"
                    style={{
                      width: "100%",
                      height: "30px",
                      marginBottom: "10px"
                    }}
                    // value={que["value"] ? que["value"][ind] : ""}
                    onChange={e => {
                      handleInput(ques.id, que.id, e, ind)
                    }}>
                    {que.value ? que.value[ind] : ""}
                  </textarea>
                ))}
                {/* <Button
                    id={ques.id}
                    onClick={(e) => handleAddInputField(ques.id, true, index)}
                    variant="contained"
                    style={{
                      backgroundColor: "#D8D7D7",
                      marginTop: "10px",
                      color: "#838181",
                      marginBottom: "-30px",
                      textTransform: "capitalize",
                    }}
                    startIcon={
                      <AddIcon
                        style={{
                          color: "#fff",
                          backgroundColor: "#2E5276",
                          borderRadius: "50%",
                        }}
                      />
                    }
                  >
                    Add another
                  </Button> */}
              </div>
            ))}
        </div>
      ) : (
        <div className="flex">
          <input
            disabled="true"
            style={{ marginRight: "5px" }}
            type="checkbox"
            // id={`custom-checkbox-${index}`}
            name="dsa"
            checked
            // onChange={(e) => handleChange(ques.id, e, index)}
          />
          <label style={{ fontSize: "12px" }}>Not Applicable</label>
        </div>
      )

    default:
      return null
  }
}

export default CCMViewFormInput
