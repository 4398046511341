import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import PlanView from "@fitpeo/fit-plan-viewer"

import Loading from "../../layouts/Loading/Loading"
import { getrpmCareplanDataActions } from "../../../redux/actions/rpmAction"

import { getPatientDiagnosticsData } from "../../../redux/actions/patientAction"

import { Typography } from "antd"

function RPMAdminPatient(props) {
  const [providers, setProviders] = useState([])
  const [practitioners, setPractitioners] = useState([])
  const dispatch = useDispatch()

  const { loading: patientLoading, userInfo: patientInfoDetail } = useSelector(
    state => state.userInfo
  )
  const { loading: rpmLoading, getRpmCarePlan } = useSelector(state => state.rpmPatientCarePlan)
  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)
  const { patientDiagnosticData } = useSelector(state => state.patientDiagnosticData)

  // const { isDark } = useSelector(state => state.themeConfig)

  useEffect(() => {
    if (!patientInfoDetail?.id) return
    dispatch(getrpmCareplanDataActions(patientInfoDetail?.id))
  }, [dispatch, patientInfoDetail]) //eslint-disable-line

  useEffect(() => {
    if (!getRpmCarePlan) return
    dispatch(getPatientDiagnosticsData(patientInfoDetail?.id))
  }, [getRpmCarePlan]) //eslint-disable-line

  useEffect(() => {
    if (patientMonitoringData?.rpm) {
      let providers = []
      let practitioners = []
      patientMonitoringData?.rpm?.map(item => {
        if (item.providerId) {
          providers.push(`${item?.providerfirstName}${(item?.providerMiddleName && item?.providerMiddleName !== "") ? " " + item?.providerMiddleName : ""} ${item?.providerLastName}`)
        }
        if (item.practitionerId) {
          practitioners.push(`${item?.practitionerfirstName}${(item?.practitionerMiddleName && item?.practitionerMiddleName !== "") ? " " + item?.practitionerMiddleName : ""} ${item?.practitionerLastName}`)
        }
      })
      setProviders(providers)
      setPractitioners(practitioners)
    }

  }, [patientMonitoringData])

  return patientLoading || rpmLoading ? (
    <Loading />
  ) : (
    (patientInfoDetail && getRpmCarePlan && (
      <PlanView
        type="rpm"
        patientInfoDetail={patientInfoDetail}
        plan={Object.values(getRpmCarePlan?.plan).filter(data => data?.id)}
        planDate={getRpmCarePlan?.planDate}
        planIcds={patientDiagnosticData?.map(({ icdCode }) => icdCode)}
        addedBy={getRpmCarePlan?.plan?.addingBy}
        providers={providers}
        practitioners={practitioners}
        isDarkMode={false}
        userType="patient"
      />
    )) || (
      <Typography.Title level={4} className="text-center">
        Treatment plan has not being created yet.
      </Typography.Title>
    )
  )
}

export default RPMAdminPatient
