export const UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_REQUEST = "UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_REQUEST"
export const UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_SUCCESS = "UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_SUCCESS"
export const UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_FAIL = "UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_FAIL"

export const GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_REQUEST = "GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_REQUEST"
export const GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_SUCCESS = "GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_SUCCESS"
export const GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_FAIL = "GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_FAIL"

export const GET_SELFONBOARD_ENROLSP_CODE_REQUEST = "GET_SELFONBOARD_ENROLSP_CODE_REQUEST"
export const GET_SELFONBOARD_ENROLSP_CODE_SUCCESS = "GET_SELFONBOARD_ENROLSP_CODE_SUCCESS"
export const GET_SELFONBOARD_ENROLSP_CODE_FAIL = "GET_SELFONBOARD_ENROLSP_CODE_FAIL"