import React, { useEffect, useState } from "react"
import PersonalDetailForm from "./PersonalDetailForm"
import VitalDetail from "./VitalDetail"
import EmergencyContactDetail from "./EmergencyContactDetail"
import AddressForm from "./AddressForm"
import { getPatientVitals } from "../../../redux/actions/patientAction"
import { useDispatch, useSelector } from "react-redux"
import { getUserProfileAction } from "../../../redux/actions/userActions"
import { Helmet } from "react-helmet"
import Loading from "../../layouts/Loading/Loading"
import EquipmentShipOption from "./EquipmentShipOption"

const PersonalDetails = ({ next, prevPage, patientId, isFromLastScreen }) => {
  const dispatch = useDispatch()
  patientId = JSON.parse(localStorage.getItem("id"));
  const { patientVitals, loading: vitalsLoading } = useSelector(state => state.patientVitals)
  const { loading: patientLoading, userInfo: patientInfoDetail } = useSelector(
    state => state.userInfo
  )
  const examplePersonalData = {
    id: patientId,
    firstName: patientInfoDetail?.firstName,
    lastName: patientInfoDetail?.lastName,
    middleName: patientInfoDetail?.middleName ?? null,
    email: patientInfoDetail?.email,
    mobileNumber: patientInfoDetail?.mobileNumber,
    gender: patientInfoDetail?.gender,
    dob: patientInfoDetail?.dob,
    height: patientVitals?.find(item => item.vitalType === "height")?.value,
    weight: patientVitals?.find(item => item.vitalType === "weight")?.value,
    telephone: patientInfoDetail?.telephone,
    healthcareProvider: patientInfoDetail?.orgName,
    ...patientInfoDetail
  }
  //if back is clicked by facility patient we dont need to show the equipment options
  const [currentStep, setCurrentStep] = useState(isFromLastScreen ? (patientInfoDetail?.userType === "facility" ? 3 : 4) : 1)
  const [formData, setFormData] = useState(examplePersonalData)

  useEffect(() => {
    if (patientVitals?.length) {
      setFormData({
        ...formData,
        height: patientVitals?.find(item => item.vitalType === "height")?.value,
        weight: patientVitals?.find(item => item.vitalType === "weight")?.value
      })
      if (patientInfoDetail?.length) {
        setFormData({
          ...formData,
          firstName: patientInfoDetail?.firstName,
          lastName: patientInfoDetail?.lastName,
          middleName: patientInfoDetail?.middleName ?? null,
          email: patientInfoDetail?.email,
          mobileNumber: patientInfoDetail?.mobileNumber,
          gender: patientInfoDetail?.gender,
          dob: patientInfoDetail?.dob,
          telephone: patientInfoDetail?.telephone,
          teleExtension: patientInfoDetail?.extension,
        })
      }
    }
  }, [vitalsLoading, patientLoading, patientInfoDetail])

  // const handleNext = () => {
  //   // Validate form data or perform any necessary actions before moving to the next step
  //   if (currentStep < 3) {
  //     setCurrentStep((prevStep) => prevStep + 1);
  //   } else {
  //     // Final step: Handle form submission logic
  //     submitForm();
  //   }
  // };

  if (currentStep === 4 && patientInfoDetail?.userType === "facility") {
    next()
  }
  
  if (currentStep === 5) {
    next()
    // Perform form submission logic, e.g., make an API request
    console.log("Form submitted with data:", formData)

    // Reset the form or perform any other necessary actions
    // to redirect the user or show a success message
  }

  return (
    <>
      <Helmet>
        <script>{`

               if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.navigationTitle) {
                 window.webkit.messageHandlers.navigationTitle.postMessage("Your Details");
                  } else {
                  console.error("window.webkit.messageHandlers.navigationTitle is undefined. Unable to post message.");
                }
              
               `}</script>
      </Helmet>
      {!window?.isMobileVersion ? <div className="flex justify-center py-3  text-[1rem] font-semibold shadow-sm ">Your Details</div> : null}
      <div className={`${!window?.isMobileVersion ? "pt-1" :"py-6"} px-3`}>
        <div>
          {currentStep === 1 &&
            (patientInfoDetail ? (
              <PersonalDetailForm
                formData={formData}
                setFormData={setFormData}
                setCurrentStep={setCurrentStep}
                prevPage={prevPage}
              />
            ) : (
              <Loading />
            ))}
          {currentStep === 2 && (
            <VitalDetail
              formData={formData}
              setFormData={setFormData}
              setCurrentStep={setCurrentStep}
            />
          )}
          {currentStep === 3 && (
            <EmergencyContactDetail
              formData={formData}
              setFormData={setFormData}
              setCurrentStep={setCurrentStep}
            />
          )}
          {currentStep === 4 && (
            <EquipmentShipOption setCurrentStep={setCurrentStep} patientId={patientId} />
          )}

          {/* {currentStep <= 3 && (
        <Col xs={24} md={24} className="px-6">
        <Button type="primary" onClick={handleNext} className="w-100 rounded-xl h-12 mb-3">
        {currentStep === 3 ? 'Submit' : 'Next'}
        </Button>
      </Col>
        
      )} */}
        </div>
      </div>
    </>
  )
}
export default PersonalDetails
