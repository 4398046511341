import {
  GET_EQUIPMENT_STATUS_FAIL,
  GET_EQUIPMENT_STATUS_REQUEST,
  GET_EQUIPMENT_STATUS_SUCCESS,
  POST_EQUIPMENT_FUNCTIONING_STATUS_FAIL,
  POST_EQUIPMENT_FUNCTIONING_STATUS_REQUEST,
  POST_EQUIPMENT_FUNCTIONING_STATUS_SUCCESS,
  POST_EQUIPMENT_METHOD_STATUS_FAIL,
  POST_EQUIPMENT_METHOD_STATUS_REQUEST,
  POST_EQUIPMENT_METHOD_STATUS_SUCCESS
} from "../types/equipmentStatusConstant"

export const getEquipmentStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_EQUIPMENT_STATUS_REQUEST:
      return { loading: true }
    case GET_EQUIPMENT_STATUS_SUCCESS:
      return { loading: false, getEquipmentStatus: action.payload }
    case GET_EQUIPMENT_STATUS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postEquipmentFunctioningStatusReducer = (state = {}, action) => {
    switch (action.type) {
      case POST_EQUIPMENT_FUNCTIONING_STATUS_REQUEST:
        return { loading: true }
      case POST_EQUIPMENT_FUNCTIONING_STATUS_SUCCESS:
        return { loading: false }
      case POST_EQUIPMENT_FUNCTIONING_STATUS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

export const postEquipmentStatusMethodReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_EQUIPMENT_METHOD_STATUS_REQUEST:
      return { loading: true }
    case POST_EQUIPMENT_METHOD_STATUS_SUCCESS:
      return { loading: false }
    case POST_EQUIPMENT_METHOD_STATUS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}