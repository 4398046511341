import {
  GET_SELFONBOARD_ENROLSP_CODE_FAIL,
  GET_SELFONBOARD_ENROLSP_CODE_REQUEST,
  GET_SELFONBOARD_ENROLSP_CODE_SUCCESS,
  GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_FAIL,
  GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_REQUEST,
  GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_SUCCESS,
  UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_FAIL,
  UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_REQUEST,
  UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_SUCCESS
} from "../types/selfOnboardPatientConstant"

export const updateSelfOnboardedPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_REQUEST:
      return { loading: true }
    case UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_SUCCESS:
      return { loading: false }
    case UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getSelfOnboardedPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_REQUEST:
      return { loading: true }
    case GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getEnrolSpGeneratedCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SELFONBOARD_ENROLSP_CODE_REQUEST:
      return { loading: true }
    case GET_SELFONBOARD_ENROLSP_CODE_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_SELFONBOARD_ENROLSP_CODE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
