import React, { useState, useEffect } from "react"
import PlanView from "@fitpeo/fit-plan-viewer"
import Loading from "../../layouts/Loading/Loading"
import { useDispatch, useSelector } from "react-redux"
import { getccmCareplanDataActions } from "../../../redux/actions/ccmActions"
import { getPatientMonitoringData, getPatientDiagnosticsData } from "../../../redux/actions/patientAction"
import "./CCMAdminPatient.css"
import { Typography } from "antd"
// import CCMViewQuestion from "../CCMViewCare/Component/CCMViewQuestion/CCMViewQuestion";
// import moment from "moment";
// import TopDetalCard from "../../layouts/TopDetalCard/TopDetalCard";

const CCMAdminPatient = props => {
  const [callRestApis, setCallRestApis] = useState(false)
  const [provider, setProvider] = useState([])
  const [practitioner, setPractitioner] = useState([])
  const dispatch = useDispatch()

  const { loading: patientLoading, userInfo: patientInfoDetail } = useSelector(state => state.userInfo)
  const { loading: ccmLoading, getCcmCarePlan: ccmCarePlan } = useSelector(state => state.ccmPatientCarePlan)
  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)
  const { patientDiagnosticData } = useSelector(state => state.patientDiagnosticData)
  // const { isDark } = useSelector(state => state.themeConfig)

  useEffect(() => {
    if (!patientInfoDetail?.id) return
    dispatch(getccmCareplanDataActions(patientInfoDetail?.id))
    setCallRestApis(true);
  }, [dispatch, patientInfoDetail]) //eslint-disable-line

  useEffect(() => {
    if (ccmCarePlan && callRestApis) {
      if (!ccmCarePlan?.plan?.planIcds) dispatch(getPatientDiagnosticsData(patientInfoDetail?.id))
      setCallRestApis(false)
    }
  }, [ccmCarePlan])

  // It will convert v1 careplan structure to v2 careplan structure
  const convertCareplanToV2 = plan => {
    if (plan?.body) return plan?.body
    else return Object.values(plan)
  }

  useEffect(() => {
    let provider = []
    let practitioner = []

    if (ccmCarePlan?.plan?.providers) provider = ccmCarePlan?.plan?.providers
    else if (patientMonitoringData?.ccm?.[0]) {
      provider = patientMonitoringData?.ccm?.[0]?.providerId ? [` ${patientMonitoringData?.ccm?.[0]?.providerfirstName}
      ${patientMonitoringData?.ccm?.[0]?.providerMiddleName && patientMonitoringData?.ccm?.[0]?.providerMiddleName !== "" ? " " + patientMonitoringData?.ccm?.[0]?.providerMiddleName : ""} 
      ${patientMonitoringData?.ccm?.[0]?.providerLastName}`] : []
    }
    if (ccmCarePlan?.plan?.practitioners) practitioner = ccmCarePlan?.plan?.practitioners
    else if (patientMonitoringData?.ccm?.[0]) {
      practitioner = patientMonitoringData?.ccm?.[0]?.practitionerId ? [` ${patientMonitoringData?.ccm?.[0]?.practitionerfirstName}
      ${patientMonitoringData?.ccm?.[0]?.practitionerMiddleName && patientMonitoringData?.ccm?.[0]?.practitionerMiddleName !== "" ? " " + patientMonitoringData?.ccm?.[0]?.practitionerMiddleName : ""} 
      ${patientMonitoringData?.ccm?.[0]?.practitionerLastName}`] : []
    }

    if (ccmCarePlan?.plan?.provider) {
      provider = [`${ccmCarePlan?.plan?.provider?.providerFirstName && ccmCarePlan?.plan?.provider?.providerFirstName !== "" ? " " + ccmCarePlan?.plan?.provider?.providerFirstName : ""}
      ${ccmCarePlan?.plan?.provider?.providerMiddleName && ccmCarePlan?.plan?.provider?.providerMiddleName !== "" ? " " + ccmCarePlan?.plan?.provider?.providerMiddleName : ""} 
      ${ccmCarePlan?.plan?.provider?.providerLastName && ccmCarePlan?.plan?.provider?.providerLastName !== "" ? " " + ccmCarePlan?.plan?.provider?.providerLastName : ""}`]
    }

    setProvider(provider)
    setPractitioner(practitioner)
  }, [ccmCarePlan, patientMonitoringData])

  const planIcds = ccmCarePlan?.plan?.planIcds ?? patientDiagnosticData?.map(({ icdCode }) => icdCode)

  return patientLoading || ccmLoading ? (
    <Loading />
  ) : (
    (patientInfoDetail !== undefined && ccmCarePlan && (
      <PlanView
        type="ccm"
        patientInfoDetail={patientInfoDetail}
        planDate={ccmCarePlan?.planDate}
        plan={convertCareplanToV2(ccmCarePlan?.plan)}
        planIcds={planIcds}
        providers={provider}
        practitioners={practitioner}
        addedBy={ccmCarePlan?.plan?.addedBy}
        isDarkMode={false}
        userType="patient"
      />
    )) || (
      <Typography.Title level={4} className="text-center">
       CCM care plan has not being created yet.
      </Typography.Title>
    )
  )
}

export default CCMAdminPatient
