import React, { useState, useEffect } from "react"
import { useToasts } from "react-toast-notifications"
import "../index.css"

const useNavigatorOnLine = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  useEffect(() => {
    const handleOnline = () => setIsOnline(true)
    const handleOffline = () => setIsOnline(false)

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [])

  return isOnline
}

const NoInternetConnection = () => {
  const isOnline = useNavigatorOnLine()
  const { addToast, removeToast } = useToasts()
  const [toastId, setToastId] = useState(null)

  useEffect(() => {
    if (!isOnline) {
      showToast()
    } else {
      if (toastId) {
        removeToast(toastId)
        setToastId(null) // Reset toastId after removing the toast
      }
    }
  }, [isOnline])

  const showToast = () => {
    const id = addToast("Your internet connection was interrupted. Kindly reconnect.", {
      appearance: "error",
      autoDismiss: false,
      className: "no-internet-toast"
    })
    setToastId(id) // Store the toastId in state
  }

  return null
}

export default NoInternetConnection
