import React from "react"

import { Alert, Card, Avatar } from "antd"
import { UserOutlined } from "@ant-design/icons"

import { useSelector } from "react-redux"
// import { getPatientMonitoringData } from "../../redux/actions/patientAction"
import Loading from "../layouts/Loading/Loading"
import getFullName from "../../utils/getFullName"
// import swal from "sweetalert"
// import { postCallRequestAction } from "../../redux/actions/patientAction"

const PatientProviderMain = () => {
  // const { userInfo } = useSelector(state => state.userInfo)
  // const dispatch = useDispatch()

  const { loading, patientMonitoringData } = useSelector(state => state.patientMonitoringData)

  // const [selectedId, setSelectedId] = useState("") // eslint-disable-line

  // const { error } = useSelector(store => store.userCallRequest)

  // useEffect(() => {
  //   if (!userInfo || !userInfo?.id) return

  //   dispatch(getPatientMonitoringData(userInfo?.id))
  // }, [userInfo])

  // const handleSubmit = async (type, id, index) => {
  //   // console.log('type',type,'id',id)
  //   setSelectedId(index)
  //   if (!userInfo?.phoneNumber || !userInfo?.email)
  //     return swal("Alert", "Phone number and Email is mandatory, Please add them from setting section", "warning")
  //   const newData = {
  //     userName: userInfo?.userName,
  //     userEmail: userInfo?.email,
  //     phoneNumber: userInfo?.phoneNumber,
  //     adminId: id,
  //     callType: type
  //   }
  //   const res = await dispatch(postCallRequestAction(newData))
  //   if (res?.success) {
  //     swal("Call Placed Successfully", {
  //       icon: "success"
  //     })
  //   } else {
  //     swal("Oops!", "Unable to place call request.", "error")
  //   }
  // }

  // const handleCall = (type, id, index) => {
  //   swal({
  //     title: "Please Confirm",
  //     text: `Do you want to place ${type} call request ?`,
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true
  //   }).then(willDelete => {
  //     if (willDelete) {
  //       handleSubmit(type, id, index)
  //     } else {
  //       swal("Call Cancelled")
  //     }
  //   })
  // }

  // useEffect(() => {
  //   if (error) return swal("Oops!", "Unable to place call request.", "error")
  // }, [error])

  return loading ? (
    <Loading />
  ) : (
    <div className="flex gap-4 flex-wrap mt-5">
      {patientMonitoringData && Object.values(patientMonitoringData)?.length ? (
        Object.values(patientMonitoringData)
          ?.flat(Infinity)
          ?.map(monitorData =>
            monitorData?.providerId || monitorData?.practitionerId
              ? monitorData?.programs?.map(({ programName }, idx) => (
                  <Card
                    hoverable
                    key={idx}
                    title=""
                    className="provider-card min-w-[330px] text-center">
                    <Avatar
                      size={80}
                      className="mb-4"
                      icon={<UserOutlined className="mt-1 align-text-top" />}
                    />
                    <h5 className="capitalize text-xl">
                      {monitorData?.providerId
                        ? getFullName(
                            monitorData?.providerfirstName,
                            monitorData?.providerMiddleName,
                            monitorData?.providerLastName
                          )
                        : getFullName(
                            monitorData?.practitionerfirstName,
                            monitorData?.practitionerMiddleName,
                            monitorData?.practitionerLastName
                          )}
                    </h5>
                    <p className="uppercase text-secondary my-1">{monitorData?.monitoringType}</p>
                    <p className="uppercase text-secondary">{programName}</p>
                  </Card>
                ))
              : null
          )
      ) : (
        <Alert type="error" message="No Provider Added Yet." />
      )}
    </div>
  )
}

export default PatientProviderMain
