import moment from "moment"
import { axiosGet, axiosPost } from "../axios"
import { axiosLengthyGet } from "../axiosLargeFileGetRequest"

import {
  ADD_MANUAL_TIME_FAIL,
  ADD_MANUAL_TIME_REQUEST,
  ADD_MANUAL_TIME_SUCCESS,
  ADD_PATIENT_FAIL,
  ADD_PATIENT_REQUEST,
  ADD_PATIENT_SUCCESS,
  CHRONIC_TYPE_FAIL,
  CHRONIC_TYPE_REQUEST,
  CHRONIC_TYPE_SUCCESS,
  CRITICAL_STATUS_FAIL,
  CRITICAL_STATUS_REQUEST,
  CRITICAL_STATUS_SUCCESS,
  GET_CHRONIC_CONDITION_FAIL,
  GET_CHRONIC_CONDITION_REQUEST,
  GET_CHRONIC_CONDITION_SUCCESS,
  GET_MANUAL_TIME_FAIL,
  GET_MANUAL_TIME_REQUEST,
  GET_MANUAL_TIME_SUCCESS,
  GET_REPORT_DATA_FAIL,
  GET_REPORT_DATA_REQUEST,
  GET_REPORT_DATA_SUCCESS,
  PATIENT_BP_FAIL,
  PATIENT_BP_REQUEST,
  PATIENT_BP_SUCCESS,
  PATIENT_HEALTH_KPI_FAIL,
  PATIENT_HEALTH_KPI_REQUEST,
  PATIENT_HEALTH_KPI_SUCCESS,
  PATIENT_INFO_FAIL,
  PATIENT_INFO_REQUEST,
  PATIENT_INFO_SUCCESS,
  PATIENT_OXY_FAIL,
  PATIENT_OXY_REQUEST,
  PATIENT_OXY_SUCCESS,
  PATIENT_LATEST_READING_FAIL,
  PATIENT_LATEST_READING_REQUEST,
  PATIENT_LATEST_READING_SUCCESS,
  PATIENT_WM_FAIL,
  PATIENT_WM_REQUEST,
  PATIENT_WM_SUCCESS,
  PATIENT_GLUCOSE_FAIL,
  PATIENT_GLUCOSE_REQUEST,
  PATIENT_GLUCOSE_SUCCESS,
  POST_CHRONIC_DATA_FAIL,
  POST_CHRONIC_DATA_REQUEST,
  POST_CHRONIC_DATA_SUCCESS,
  UPDATE_PATIENT_FAIL,
  UPDATE_PATIENT_LANDLINE_FAIL,
  UPDATE_PATIENT_LANDLINE_REQUEST,
  UPDATE_PATIENT_LANDLINE_SUCCESS,
  UPDATE_PATIENT_REQUEST,
  UPDATE_PATIENT_SUCCESS,
  POST_CONCERN_REQUEST,
  POST_CONCERN_SUCCESS,
  POST_CONCERN_FAIL,
  GET_CONCERN_REQUEST,
  GET_CONCERN_SUCCESS,
  GET_CONCERN_FAIL,
  GET_USER_PAST_CONSULTATION_REQUEST,
  GET_USER_PAST_CONSULTATION_SUCCESS,
  GET_USER_PAST_CONSULTATION_FAIL,
  GET_USER_FUTURE_CONSULTATION_REQUEST,
  GET_USER_FUTURE_CONSULTATION_SUCCESS,
  GET_USER_FUTURE_CONSULTATION_FAIL,
  POST_CALL_REQUEST,
  POST_CALL_SUCCESS,
  POST_CALL_FAIL,
  GET_USER_MONITOR_TYPE_REQUEST,
  GET_USER_MONITOR_TYPE_SUCCESS,
  GET_USER_MONITOR_TYPE_FAIL,
  GET_USER_BOOKING_REQUEST,
  GET_USER_BOOKING_SUCCESS,
  GET_USER_BOOKING_FAIL,
  POST_USER_CONSULTATION_REQUEST,
  POST_USER_CONSULTATION_SUCCESS,
  POST_USER_CONSULTATION_FAIL,
  POST_USER_CANCEL_CONSULTATION_REQUEST,
  POST_USER_CANCEL_CONSULTATION_SUCCESS,
  POST_USER_CANCEL_CONSULTATION_FAIL,
  PATIENT_VITALS_REQUEST,
  PATIENT_VITALS_SUCCESS,
  PATIENT_VITALS_FAIL,
  GET_PATIENT_CARETAKER_REQUEST,
  GET_PATIENT_CARETAKER_SUCCESS,
  GET_PATIENT_CARETAKER_FAIL,
  PATIENT_MONITORING_REQUEST,
  PATIENT_MONITORING_SUCCESS,
  PATIENT_MONITORING_FAIL,
  PATIENT_DIAGNOSTIC_REQUEST,
  PATIENT_DIAGNOSTIC_SUCCESS,
  PATIENT_DIAGNOSTIC_FAIL,
  PATIENT_CRITICAL_DEVICES_REQUEST,
  PATIENT_CRITICAL_DEVICES_SUCCESS,
  PATIENT_CRITICAL_DEVICES_FAIL,
  POST_EMERGENCY_CONTACT_DETAILS_FAIL,
  POST_EMERGENCY_CONTACT_DETAILS_SUCCESS,
  POST_EMERGENCY_CONTACT_DETAILS_REQUEST,
  POST_PATIENT_VITALS_REQUEST,
  POST_PATIENT_VITALS_SUCCESS,
  POST_PATIENT_VITALS_FAIL,
  POST_SHIPPING_ADDRESS_FAIL,
  POST_SHIPPING_ADDRESS_SUCCESS,
  POST_SHIPPING_ADDRESS_REQUEST,
  GET_SHIPPING_ADDRESS_REQUEST,
  GET_SHIPPING_ADDRESS_SUCCESS,
  GET_SHIPPING_ADDRESS_FAIL,
  UPDATE_SHIPPING_ADDRESS_REQUEST,
  UPDATE_SHIPPING_ADDRESS_SUCCESS,
  UPDATE_SHIPPING_ADDRESS_FAIL,
  GET_EMERGENCY_CONTACT_DETAILS_REQUEST,
  GET_EMERGENCY_CONTACT_DETAILS_SUCCESS,
  GET_EMERGENCY_CONTACT_DETAILS_FAIL,
  UPDATE_EMERGENCY_CONTACT_DETAILS_REQUEST,
  UPDATE_EMERGENCY_CONTACT_DETAILS_SUCCESS,
  UPDATE_EMERGENCY_CONTACT_DETAILS_FAIL,
} from "../types/PatientConstant"
import { userLogoutAction } from "./userActions"

// get patient's critical device(s) in v2
export const getPatientCriticalDevices = patientId => async dispatch => {
  try {
    dispatch({
      type: PATIENT_CRITICAL_DEVICES_REQUEST
    })

    const { data } = await axiosGet(`/api/rpm/readings/critical/${patientId}/fetch`)

    dispatch({
      type: PATIENT_CRITICAL_DEVICES_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_CRITICAL_DEVICES_FAIL,
      payload: error?.response && error?.response?.data?.error
    })
  }
}

export const getPatientDiagnosticsData = patientId => async dispatch => {
  try {
    dispatch({
      type: PATIENT_DIAGNOSTIC_REQUEST
    })
    const { data } = await axiosGet(`/api/cs/patients/diagnostic/${patientId}/fetch`)
    dispatch({
      type: PATIENT_DIAGNOSTIC_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_DIAGNOSTIC_FAIL,
      payload: error.response && error?.response?.data?.error
    })
  }
}

// get monitoring data in v2
export const getPatientMonitoringData = patientId => async dispatch => {
  try {
    dispatch({
      type: PATIENT_MONITORING_REQUEST
    })

    const { data } = await axiosGet(`/api/cs/patients/monitoring/${patientId}/fetch`)

    dispatch({
      type: PATIENT_MONITORING_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    error?.response?.status === 401 && (!window.isMobileVersion ? dispatch(userLogoutAction()) : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" })
    )
    dispatch({
      type: PATIENT_MONITORING_FAIL,
      payload: error?.response && error?.response?.data?.error
    })
  }
}

export const getPatientCaretaker = patientID => async dispatch => {
  try {
    dispatch({
      type: GET_PATIENT_CARETAKER_REQUEST
    })
    const { data } = await axiosGet(`/api/cs/patients/${patientID}/caretaker/fetchAll`)
    dispatch({
      type: GET_PATIENT_CARETAKER_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PATIENT_CARETAKER_FAIL,
      payload: error.response && error?.response?.data?.error
    })
  }
}

export const postAddPatient =
  ({
    countryCode,
    phoneNumber,
    addressDetails,
    email,
    firstName,
    lastName,
    middleName,
    dob,
    gender,
    userName,
    image,
    orgId,
    moniterDetails,
    primaryAdminId,
    caretakerId,
    vitalDetails,
    diagnosticData,
    // facilityAddress,
    landlineCode,
    landNumber,
    extension,
    insuranceNumber,
    insuranceProvider,
    ccmCareplanMetadata
  }) =>
  async dispatch => {
    try {
      dispatch({
        type: ADD_PATIENT_REQUEST
      })

      const formData = {
        countryCode,
        phoneNumber,
        addressDetails,
        email,
        firstName,
        lastName,
        middleName,
        dob,
        gender,
        userName,
        image,
        orgId,
        moniterDetails,
        primaryAdminId,
        caretakerId,
        vitalDetails,
        diagnosticData,
        landNumber,
        extension,
        insuranceNumber,
        insuranceProvider,
        landlineCode,
        ccmCareplanMetadata
        // facilityAddress
      }

      /* if (facilityAddress?.address !== undefined && facilityAddress?.address?.length > 0) {
                formData.facilityAddress = facilityAddress;
            } */

      const { data } = await axiosPost(`/admin/v2/registerUser`, formData, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      dispatch({
        type: ADD_PATIENT_SUCCESS,
        payload: JSON.stringify(data)
      })

      return data
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: ADD_PATIENT_FAIL,
        payload:
          error?.response && error?.response?.data?.detail
            ? error.response.data.detail
            : error.detail
      })
      return error?.response?.data?.detail
    }
  }

export const getPatientHealthKpi = patientId => async dispatch => {
  try {
    dispatch({
      type: PATIENT_HEALTH_KPI_REQUEST
    })

    const { data } = await axiosGet(
      `v1/getPatientDetailsKPIForProviderDB?providerId=${patientId.patientId}`
    )

    dispatch({
      type: PATIENT_HEALTH_KPI_SUCCESS,
      payload: data
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_HEALTH_KPI_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getWmDetails =
  ({ patientId, startDate, endDate, programId }) =>
  async dispatch => {
    try {
      dispatch({
        type: PATIENT_WM_REQUEST
      })

      const { data } = await axiosPost(`/api/rpm/readings/${programId}/wm/${patientId}/fetch/100`, {
        startDate,
        endDate
      })

      dispatch({
        type: PATIENT_WM_SUCCESS,
        payload: data?.details
      })
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: PATIENT_WM_FAIL,
        payload: error.response && error?.response?.data?.detail
      })
    }
  }

export const getPatientVitals = patientId => async dispatch => {
  try {
    dispatch({
      type: PATIENT_VITALS_REQUEST
    })

    const { data } = await axiosGet(`/api/cs/patients/vital/${patientId}/fetch`)

    dispatch({
      type: PATIENT_VITALS_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    error?.response?.status === 401 && (!window.isMobileVersion ? dispatch(userLogoutAction()) : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" }))
    dispatch({
      type: PATIENT_VITALS_FAIL,
      payload: error.response && error?.response?.data?.error
    })
  }
}

export const postPatientVitals = (patientId, vitalId, formData) => async dispatch => {
  try {
    dispatch({
      type: POST_PATIENT_VITALS_REQUEST
    })
    const updatedFormData = {...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
    const { data } = await axiosPost(
      `/api/cs2/patients/vital/${patientId}/update/${vitalId}`,
      updatedFormData
    )

    await dispatch({
      type: POST_PATIENT_VITALS_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && (!window.isMobileVersion ? dispatch(userLogoutAction()) : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" }))
    dispatch({
      type: POST_PATIENT_VITALS_FAIL,
      payload: Object?.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const createPatientVitals = (patientId, formData) => async dispatch => {
  try {
    dispatch({
      type: POST_PATIENT_VITALS_REQUEST
    })
    const { data } = await axiosPost(`/api/cs/patients/vital/${patientId}/create`, formData)

    await dispatch({
      type: POST_PATIENT_VITALS_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && (!window.isMobileVersion ? dispatch(userLogoutAction()) : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" }))
    dispatch({
      type: POST_PATIENT_VITALS_FAIL,
      payload: Object?.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const getBpDetails = fetchData => async dispatch => {
  const { programId, patientId, startDate, endDate } = fetchData
  try {
    dispatch({
      type: PATIENT_BP_REQUEST
    })

    const { data } = await axiosPost(`/api/rpm/readings/${programId}/bp/${patientId}/fetch/100`, {
      startDate,
      endDate
    })

    dispatch({
      type: PATIENT_BP_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    console.debug("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_BP_FAIL,
      payload: error?.response && error.response?.data?.details
    })
  }
}

export const getOxyDetails =
  ({ programId, patientId, startDate, endDate }) =>
  async dispatch => {
    try {
      dispatch({
        type: PATIENT_OXY_REQUEST
      })

      const { data } = await axiosPost(
        `/api/rpm/readings/${programId}/oximeter/${patientId}/fetch/100`,
        {
          startDate,
          endDate
        }
      )

      dispatch({
        type: PATIENT_OXY_SUCCESS,
        payload: data.details
      })
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: PATIENT_OXY_FAIL,
        payload: error.response && error?.response?.data?.detail
      })
    }
  }

export const getGlucoseDetails = fetchData => async dispatch => {
  const { programId, patientId, startDate, endDate } = fetchData
  try {
    dispatch({
      type: PATIENT_GLUCOSE_REQUEST
    })

    const { data } = await axiosPost(
      `/api/rpm/readings/${programId}/glucose/${patientId}/fetch/100`,
      {
        startDate,
        endDate
      }
    )

    dispatch({
      type: PATIENT_GLUCOSE_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    console.debug(error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_GLUCOSE_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getLatestReadings = patientId => async dispatch => {
  try {
    dispatch({
      type: PATIENT_LATEST_READING_REQUEST
    })

    const { data } = await axiosGet(`/api/rpm/readings/latest/${patientId}/fetch`)

    dispatch({
      type: PATIENT_LATEST_READING_SUCCESS,
      payload: data?.details
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_LATEST_READING_FAIL,
      payload: error?.response && error?.response?.data?.details
    })
  }
}

export const getPatientInfo = patientId => async dispatch => {
  try {
    dispatch({
      type: PATIENT_INFO_REQUEST
    })

    // const { data } = await axiosGet(`admin/v2/getUserProfile?memberId=${patientId.patientId}`);
    const { data } = await axiosGet(`/users/v1/getProfile`)

    dispatch({
      type: PATIENT_INFO_SUCCESS,
      payload: data
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_INFO_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

// POST ADD MANUAL TIME
export const AddManualTimeAction =
  (patientId, program, care, dateTime, duration, notes) => async dispatch => {
    try {
      dispatch({
        type: ADD_MANUAL_TIME_REQUEST
      })

      const formData = {
        userId: patientId,
        program: [program],
        care,
        careTime: duration.toString(),
        careDate: dateTime,
        notes
      }
      console.log("formdata", formData)

      const { data } = await axiosPost("/caretaker/v1/postManualLogDetails", formData)
      dispatch({
        type: ADD_MANUAL_TIME_SUCCESS,
        payload: data
      })
      dispatch(getManualTimeAction(patientId))
      return data
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: ADD_MANUAL_TIME_FAIL,
        payload: error.response && error?.response?.data?.detail
      })
    }
  }

// GET MANUAL TIME DATA
export const getManualTimeAction = patientId => async dispatch => {
  try {
    dispatch({
      type: GET_MANUAL_TIME_REQUEST
    })

    const { data } = await axiosGet(`/caretaker/v1/getManualLogDetails?memberId=${patientId}`)

    dispatch({
      type: GET_MANUAL_TIME_SUCCESS,
      payload: data?.manualLog
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_MANUAL_TIME_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

// GET MANUAL TIME DATA
export const getChronicConditionAction = patientId => async dispatch => {
  try {
    dispatch({
      type: GET_CHRONIC_CONDITION_REQUEST
    })

    const { data } = await axiosGet(`/caretaker/v1/getDiagnosticData?memberId=${patientId}`)

    dispatch({
      type: GET_CHRONIC_CONDITION_SUCCESS,
      payload: data.diagnosticData
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_CHRONIC_CONDITION_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const criticalStatusAction = (criticalStatus, patientId) => async dispatch => {
  try {
    dispatch({
      type: CRITICAL_STATUS_REQUEST
    })

    const { data } = await axiosPost(`/caretaker/v2/postCriticalStatus`, {
      status: criticalStatus,
      userId: patientId
    })

    dispatch({
      type: CRITICAL_STATUS_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: CRITICAL_STATUS_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getChronicTypeListAction = memberId => async dispatch => {
  try {
    dispatch({
      type: CHRONIC_TYPE_REQUEST
    })

    const { data } = await axiosGet(`/caretaker/v1/getDiagnosticData?memberId=${memberId}`)

    dispatch({
      type: CHRONIC_TYPE_SUCCESS,
      payload: data
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: CHRONIC_TYPE_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const postChronicConditionAction =
  (memberId, chronicCondition, chronicConditionsData) => async dispatch => {
    try {
      dispatch({
        type: POST_CHRONIC_DATA_REQUEST
      })

      const { data } = await axiosPost(`/caretaker/v1/postDiagnosticData`, {
        userId: memberId.toString(),
        diagnosticData: chronicCondition,
        ccmCareplanMetadata: chronicConditionsData
      })

      dispatch({
        type: POST_CHRONIC_DATA_SUCCESS,
        payload: data
      })
      dispatch(getChronicConditionAction(memberId))
      return data
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: POST_CHRONIC_DATA_FAIL,
        payload: error.response && error?.response?.data?.detail
      })
    }
  }

// export const UpdatePatientAction =
//   ({
//     countryCode,
//     // phoneNumber,
//     addressDetails,
//     userId,
//     firstName,
//     lastName,
//     middleName,
//     dob,
//     gender,
//     userName,
//     orgId,
//     moniterDetails,
//     primaryAdminId,
//     caretakerId,
//     vitalDetails,
//     // facilityAddress,
//     insuranceNumber,
//     insuranceProvider
//   }) =>
//   async dispatch => {
//     try {
//       dispatch({
//         type: UPDATE_PATIENT_REQUEST
//       })

//       const formData = {
//         countryCode,
//         // phoneNumber,
//         addressDetails,
//         userId,
//         firstName,
//         lastName,
//         middleName,
//         dob,
//         gender,
//         userName,
//         orgId,
//         moniterDetails,
//         primaryAdminId,
//         caretakerId,
//         vitalDetails,
//         insuranceNumber,
//         insuranceProvider
//       }

//       /* if (facilityAddress.address !== undefined && facilityAddress.address.length > 0) {
//                 formData.facilityAddress = facilityAddress;
//             } */

//       const { data } = await axiosPost(`/admin/v2/updatePatientProfile`, formData)
//       dispatch({
//         type: UPDATE_PATIENT_SUCCESS,
//         payload: JSON.stringify(data)
//       })
//       return data
//     } catch (error) {
//       error?.response?.status === 401 && dispatch(userLogoutAction())
//       dispatch({
//         type: UPDATE_PATIENT_FAIL,
//         payload: error.response && error?.response?.data?.detail
//       })
//     }
//   }
export const UpdatePatientAction = formData => async dispatch => {
  try {
    dispatch({
      type: UPDATE_PATIENT_REQUEST
    })
    const updatedFormData = {
      ...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
    }
    const { data } = await axiosPost(`/api/cs2/patients/${formData.id}/update`, updatedFormData)
    dispatch({
      type: UPDATE_PATIENT_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && (!window.isMobileVersion ? dispatch(userLogoutAction()) : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" }))
    dispatch({
      type: UPDATE_PATIENT_FAIL,
      payload: Object?.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const getOutPatientAddressAction = patientID => async dispatch => {
  try {
    dispatch({
      type: "GET_PATIENT_ADDRESS_REQUEST"
    })


    const { data } = await axiosGet(`/api/cs/address/outpatient/${patientID}/fetch`)
    dispatch({
      type: "GET_PATIENT_ADDRESS_SUCCESS",
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && (!window.isMobileVersion ? dispatch(userLogoutAction()) : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" }))
    dispatch({
      type: "GET_PATIENT_ADDRESS_FAIL",
      payload: error.response && error?.response?.data?.detail
    })
  }
}
export const postOutPatientAddressAction = (patientID, formData) => async dispatch => {
  try {
    dispatch({
      type: "UPDATE_PATIENT_ADDRESS_REQUEST"
    })
    const updatedFormData = {...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
    const { data } = await axiosPost(`/api/cs2/address/outpatient/${patientID}/update`, updatedFormData)
    dispatch({
      type: "UPDATE_PATIENT_ADDRESS_SUCCESS",
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && (!window.isMobileVersion ? dispatch(userLogoutAction()) : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" }))
    dispatch({
      type: "UPDATE_PATIENT_ADDRESS_FAIL",
      payload: Object?.values(error?.response?.data?.details)?.[0]
    })
  }
}
// patient report generated
export const getPatientReportAction = (patientId, startDate, endDate) => async dispatch => {
  try {
    dispatch({
      type: GET_REPORT_DATA_REQUEST
    })

    const { data } = await axiosGet(
      `/admin/v1/updatePatientProfile?memberId=${patientId}&startDate=${startDate}&endDate=${endDate}`
    )

    dispatch({
      type: GET_REPORT_DATA_SUCCESS,
      payload: {
        startDate,
        endDate,
        data
      }
    })

    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_REPORT_DATA_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const updateLandlineAction =
  (userId, landlineCode, landNumber, extension) => async dispatch => {
    try {
      dispatch({
        type: UPDATE_PATIENT_LANDLINE_REQUEST
      })

      const LandlineUpdate = {
        userId: userId.userId,
        landlineCode: userId.landlineCode,
        landNumber: userId.landNumber,
        extension: userId.extension
      }

      const { data } = await axiosPost(`/admin/v1/postUpdateUserLandnumber`, LandlineUpdate)

      dispatch({
        type: UPDATE_PATIENT_LANDLINE_SUCCESS,
        payload: JSON.stringify(data)
      })
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: UPDATE_PATIENT_LANDLINE_FAIL,
        payload: error.response && error?.response?.data?.detail
      })
    }
  }

// add concern / consent
export const postConcernAction = (userId, consent, consentFiles) => async dispatch => {
  try {
    dispatch({
      type: POST_CONCERN_REQUEST
    })

    const { data } = await axiosPost(`/caretaker/v1/postConsentDetails`, {
      userId,
      consent,
      consentFiles
    })

    dispatch({
      type: POST_CONCERN_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_CONCERN_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

// get concern / consent
export const getConcernAction = userId => async dispatch => {
  try {
    dispatch({
      type: GET_CONCERN_REQUEST
    })

    const { data } = await axiosLengthyGet(`/caretaker/v1/getConsentDetails?memberId=${userId}`)

    dispatch({
      type: GET_CONCERN_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_CONCERN_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

/// //
export const getUserPastConsultationAction = () => async dispatch => {
  try {
    dispatch({
      type: GET_USER_PAST_CONSULTATION_REQUEST
    })

    const { data } = await axiosGet(`/users/v1/getPastConsultation`)

    dispatch({
      type: GET_USER_PAST_CONSULTATION_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_USER_PAST_CONSULTATION_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getUserFutureConsultationAction = () => async dispatch => {
  try {
    dispatch({
      type: GET_USER_FUTURE_CONSULTATION_REQUEST
    })

    const { data } = await axiosGet(`/users/v1/getFutureConsultations`)

    dispatch({
      type: GET_USER_FUTURE_CONSULTATION_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_USER_FUTURE_CONSULTATION_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const postCallRequestAction = values => async dispatch => {
  try {
    dispatch({
      type: POST_CALL_REQUEST
    })

    const { data } = await axiosPost(`/users/v1/postCallRequest`, values)

    dispatch({
      type: POST_CALL_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_CALL_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getUserMonitorTypesAction = () => async dispatch => {
  try {
    dispatch({
      type: GET_USER_MONITOR_TYPE_REQUEST
    })

    const { data } = await axiosGet(`/users/v1/getMoniterTypes`)

    dispatch({
      type: GET_USER_MONITOR_TYPE_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_USER_MONITOR_TYPE_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getUserBookingAction = (date, adminId, providerId, monitorId) => async dispatch => {
  try {
    dispatch({
      type: GET_USER_BOOKING_REQUEST
    })
    // date in epach
    const { data } = await axiosGet(
      `/users/v1/getBookingAvailability?consultationDate=${date}&adminId=${adminId}&providerId=${providerId}&moniterId=${monitorId}`
    )

    dispatch({
      type: GET_USER_BOOKING_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_USER_BOOKING_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const postConsultationAction = values => async dispatch => {
  try {
    dispatch({
      type: POST_USER_CONSULTATION_REQUEST
    })

    const { data } = await axiosPost(`/users/v1/postConsultationData`, values)

    dispatch({
      type: POST_USER_CONSULTATION_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_USER_CONSULTATION_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const postCancelConsultationAction = values => async dispatch => {
  try {
    dispatch({
      type: POST_USER_CANCEL_CONSULTATION_REQUEST
    })

    const { data } = await axiosPost(`/users/v1/postCancelConsultastion`, values)

    dispatch({
      type: POST_USER_CANCEL_CONSULTATION_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_USER_CANCEL_CONSULTATION_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const postEmergencyContactDetailAction = (patientId, formValues) => async dispatch => {
  try {
    dispatch({
      type: POST_EMERGENCY_CONTACT_DETAILS_REQUEST
    })

    const { data } = await axiosPost(
      `/api/cs/patients/emergencyContact/${patientId}/create`,
      formValues
    )

    dispatch({
      type: POST_EMERGENCY_CONTACT_DETAILS_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && (!window.isMobileVersion ? dispatch(userLogoutAction()) : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" }))
    dispatch({
      type: POST_EMERGENCY_CONTACT_DETAILS_FAIL,
      payload: Object.values(error?.response?.data?.details)[0] ??
        error?.response?.data?.details?.message
    })
  }
}

export const getEmergencyContactDetailsAction = (patientId) => async dispatch => {
  try {
    dispatch({
      type: GET_EMERGENCY_CONTACT_DETAILS_REQUEST
    })

    const { data } = await axiosGet(`/api/cs/patients/emergencyContact/${patientId}/fetch`)

    dispatch({
      type: GET_EMERGENCY_CONTACT_DETAILS_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_EMERGENCY_CONTACT_DETAILS_FAIL,
      payload: Object.values(error?.response?.data?.details)[0] ??
        error?.response?.data?.details?.message
    })
  }
}

export const updateEmergencyContactDetailsAction = (patientID, formData) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_EMERGENCY_CONTACT_DETAILS_REQUEST
    })

    const { data } = await axiosPost(`/api/cs/patients/emergencyContact/${patientID}/update`, formData)
    dispatch({
      type: UPDATE_EMERGENCY_CONTACT_DETAILS_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && (!window.isMobileVersion ? dispatch(userLogoutAction()) : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" }))
    dispatch({
      type: UPDATE_EMERGENCY_CONTACT_DETAILS_FAIL,
      payload: Object.values(error?.response?.data?.details)[0] ??
        error?.response?.data?.details?.message
    })
  }
}

export const postShippingAddressAction = (patientId, formValues) => async dispatch => {
  console.log(formValues)
  try {
    dispatch({
      type: POST_SHIPPING_ADDRESS_REQUEST
    })

    const { data } = await axiosPost(
      `/api/cs/address/shippingAddress/${patientId}/create`,
      formValues
    )
    dispatch({
      type: POST_SHIPPING_ADDRESS_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && (!window.isMobileVersion ? dispatch(userLogoutAction()) : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" }))
    dispatch({
      type: POST_SHIPPING_ADDRESS_FAIL,
      payload: Object.values(error?.response?.data?.details)[0] ??
        error?.response?.data?.details?.message
    })
  }
}

export const getShippingAddressAction = (patientId) => async dispatch => {
  try {
    dispatch({
      type: GET_SHIPPING_ADDRESS_REQUEST
    })

    const { data } = await axiosGet(`/api/cs/address/shippingAddress/${patientId}/fetch`)

    dispatch({
      type: GET_SHIPPING_ADDRESS_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_SHIPPING_ADDRESS_FAIL,
      payload: Object.values(error?.response?.data?.details)[0] ??
        error?.response?.data?.details?.message
    })
  }
}

export const updateShippingAddressAction = (patientID, formData) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_SHIPPING_ADDRESS_REQUEST
    })

    const { data } = await axiosPost(`/api/cs/address/shippingAddress/${patientID}/update`, formData)
    dispatch({
      type: UPDATE_SHIPPING_ADDRESS_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && (!window.isMobileVersion ? dispatch(userLogoutAction()) : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" }))
    dispatch({
      type: UPDATE_SHIPPING_ADDRESS_FAIL,
      payload: Object.values(error?.response?.data?.details)[0] ??
        error?.response?.data?.details?.message
    })
  }
}