import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { decryptBoolean } from "../../utils/encryptionUtils";

const AuthGuard = ({ children }) => {
  // const userInfo = useSelector(state => state.userInfo)
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("id");
  const welcomeTutorial = localStorage.getItem("welcomeTutorial")

  const navigate = useNavigate()

  useEffect(() => {
    // if (Object.keys(userInfo).length === 0 || userInfo.userInfo === null) {
    if (!token && !userId || !welcomeTutorial) {
      navigate("/login", { replace: true })
    }
    else if(decryptBoolean(JSON.parse(welcomeTutorial)))
    {
      navigate("/welcome")
    } 
  }, []) //eslint-disable-line

  return children
}

export default AuthGuard
