import { Button } from "antd"
import { useSelector } from "react-redux"

const ConfirmSignup = ({ onConfirmSignup }) => {
  const { loading, error } = useSelector(state => state.confirmSignup)

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-lg font-medium mb-2">Welcome to FitPeo</h2>
      <p className="mb-4 text-[#979797]">
        As you are a new user, would you like to Sign Up for your account?
      </p>

      <Button
        size="large"
        type="primary"
        block
        className="mb-2"
        onClick={onConfirmSignup}
        loading={loading}>
        Confirm Sign Up
      </Button>
    </div>
  )
}

export default ConfirmSignup
