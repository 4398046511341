import { useState } from "react"
import { Card, Row, Col, Avatar, Button, Divider, Calendar } from "antd"
import { IoCall } from "react-icons/io5"
import { SiGooglemeet } from "react-icons/si"
import { IoIosArrowBack,IoIosArrowForward } from "react-icons/io";

const BookAppointment = () => {
  const [showPhoneDetail, setShowPhoneDetail] = useState(false)
  const [showMeetDetail, setShowMeetDetail] = useState(false)

  const onPanelChange = (value, mode) => {
    console.log(value.format('YYYY-MM-DD'), mode);
  };

  const monthHeaderRender = ({ value, type, onChange }) => {
    const year = value.year();
    const monthName = value.format('MMMM');

    const handlePrevMonth = () => {
      onChange(value.clone().subtract(1, 'month'));
    };

    const handleNextMonth = () => {
      onChange(value.clone().add(1, 'month'));
    };

    return (
      <div className='flex py-3 px-4 space justify-between border-y-0'>
        <span
          className ='cursor-pointer pt-2' 
          onClick={handlePrevMonth}
        >
         <IoIosArrowBack color={'#979797'}/>
        </span>
        <span className="text-xl font-medium">
          {`${monthName} ${year}`}
        </span>
        <span
         className ='cursor-pointer pt-2'
          onClick={handleNextMonth}
        >
          <IoIosArrowForward color={'#979797'}/>
        </span>
      </div>
    );
  };

  return (
    <Row gutter={16}>
      <Col sm={24} xl={12} className="mb-4 w-full">
        <Card className="rounded-[10px]">
          <div className="flex flex-col justify-between items-center gap-[10px] sm:items-start">
            {showPhoneDetail || showMeetDetail ? (
              <div className="w-full ml-2 flex flex-col justify-center items-start">
                <h1 className="mt-[7px] text-lg text-left font-semibold">Hello Shaqueena</h1>
                <p className="text-sm text-[#979797] font-medium text-left">
                  How do you want us to contact you?
                </p>
              </div>
            ) : (
              <>
                <h1 className="mt-[7px] text-lg font-semibold">Hello Shaqueena</h1>
                <p className="text-sm font-medium">
                How do you want us to contact you?
                </p>
              </>
            )}
            <div className="mt-[2px] w-full flex justify-center items-center gap-4">
              <button
                className={`border-solid border-[#007AFF] border-[2px] rounded-xl w-32 h-32 flex flex-col items-center justify-center gap-[10px] ${
                  showPhoneDetail && "bg-[#007AFF]"
                }`}
                onClick={() => {
                  setShowMeetDetail(false)
                  setShowPhoneDetail(true)
                }}>
                {!showPhoneDetail ? (
                  <IoCall style={{ color: "#007AFF", fontSize: "4.6em" }} />
                ) : (
                  <IoCall style={{ color: "#fff", fontSize: "4.6em" }} />
                )}
                <p
                  className={`text-lg font-normal ${
                    showPhoneDetail ? "text-white" : "text-[#007AFF]"
                  }`}>
                  Phone
                </p>
              </button>
              <button
                className={`border-solid border-[#007AFF] border-[2px] rounded-xl w-32 h-32 flex flex-col items-center justify-center ${
                  showMeetDetail && "bg-[#007AFF] text-white"
                }`}
                onClick={() => {
                  setShowPhoneDetail(false)
                  setShowMeetDetail(true)
                }}>
                {!showMeetDetail ? (
                  <SiGooglemeet style={{ color: "#007AFF", fontSize: "4.6em" }} />
                ) : (
                  <SiGooglemeet style={{ color: "#fff", fontSize: "4.6em" }} />
                )}
                <p
                  className={`mt-2 text-lg font-normal text-[#007AFF] ${
                    showMeetDetail ? "text-white" : "text-[#007AFF]"
                  }`}>
                  Virtual meet
                </p>
              </button>
            </div>
            {(showPhoneDetail || showMeetDetail) && (
              <div>
                <Divider className="mt-[19px] mb-[14px]" />
            
                <div className="mt-[18px]">
                  <h1 className="text-lg font-semibold ml-2 " style={{ marginBottom: "16px" }}>
                    Choose Your slot
                  </h1>
                  <h1
                    className="text-base font-medium text-[#979797] "
                    style={{ marginBottom: "16px" }}>
                    Select Date
                  </h1>
                  <div className="flex flex-wrap gap-x-3 gap-y-4 border-2 border-[#e0e0e0] rounded-xl">
                  <Calendar fullscreen={false} onPanelChange={onPanelChange} headerRender={monthHeaderRender}/>
                    {/* <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-14 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      Mon
                    </button>
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-14 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      Tue
                    </button>
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-14 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      Wed
                    </button>
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-14 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      Thu
                    </button>
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-14 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      Fri
                    </button>
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-14 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      Sat
                    </button>
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-14 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      Sun
                    </button> */}
                  </div>
                </div>
                <div className="mt-[29px]">
                  <h1 className="text-base font-medium text-[#979797] mb-[11px]">Select Time</h1>
                  <h1 className="text-base font-medium mb-[9px]">Morning</h1>
                  <div className="flex flex-wrap gap-x-3 gap-y-4">
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      9:00 AM
                    </button>
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      10:00 AM
                    </button>
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      10:30 AM
                    </button>
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      11:00 AM
                    </button>
                    {/* <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      11:30AM
                    </button> */}
                  </div>
                  <h1 className="text-base font-medium mb-[9px] mt-[11px]">Afternoon</h1>
                  <div className="flex flex-wrap gap-x-3 gap-y-4">
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      12:00 PM
                    </button>
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      1:00 PM
                    </button>
                    {/* <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      1:30 PM
                    </button> */}
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      2:00 PM
                    </button>
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      3:00 PM
                    </button>
                  </div>
                  <h1 className="text-base font-medium mb-[9px] mt-[11px]">Evening</h1>
                  <div className="flex flex-wrap gap-x-3 gap-y-4">
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      5:00 PM
                    </button>
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      5:30 PM
                    </button>
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      6:00 PM
                    </button>
                    {/* <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      6:30 PM
                    </button> */}
                    <button className="border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF]">
                      7:00 PM
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="w-full flex justify-center">
              <Button className="mt-[17px] w-full h-[49px] text-base text-white font-semibold rounded-xl bg-[#007AFF] sm:w-72">
                {showPhoneDetail || showMeetDetail ? "Continue" : "Schedule"}
              </Button>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default BookAppointment
