export const EMAIL_VERIFICATION_REQUEST = "EMAIL_VERIFICATION_REQUEST"
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS"
export const EMAIL_VERIFICATION_FAIL = "EMAIL_VERIFICATION_FAIL"

export const FORGET_PASSWORD_OPT_REQUEST = "FORGET_PASSWORD_OPT_REQUEST"
export const FORGET_PASSWORD_OPT_SUCCESS = "FORGET_PASSWORD_OPT_SUCCESS"
export const FORGET_PASSWORD_OPT_FAIL = "FORGET_PASSWORD_OPT_FAIL"

export const FORGET_PASSWORD_CHANGE_REQUEST = "FORGET_PASSWORD_CHANGE_REQUEST"
export const FORGET_PASSWORD_CHANGE_SUCCESS = "FORGET_PASSWORD_CHANGE_SUCCESS"
export const FORGET_PASSWORD_CHANGE_FAIL = "FORGET_PASSWORD_CHANGE_FAIL"

export const UPDATE_EMAIL_REQUEST = "UPDATE_EMAIL_REQUEST"
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS"
export const UPDATE_EMAIL_FAIL = "UPDATE_EMAIL_FAIL"

export const UPDATE_NUMBER_REQUEST = "UPDATE_NUMBER_REQUEST"
export const UPDATE_NUMBER_SUCCESS = "UPDATE_NUMBER_SUCCESS"
export const UPDATE_NUMBER_FAIL = "UPDATE_NUMBER_FAIL"
