export const loginTypeChecker = (value) =>{
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let str = value.trim()
    let parsess = parseFloat(str)
    let checNumb = typeof parsess === 'number'
    // console.log(str.length, checNumb)
    if(value.match(mailformat)){
      return "email"
    }
    if(str.length == 10 && checNumb == true){
      return "mobileNumber"
    }
  
  }