import { IoCall } from "react-icons/io5"
import { MdEmail } from "react-icons/md"
import { RiCalendarCheckLine } from "react-icons/ri"
import { Button, Card, Col, Row } from "antd"
import { useState } from "react"
import BookAppointment from "./BookAppointment"
import contactImg from "../../assets/contact-us.png"

const ContactUs = () => {
  const [contactMedium, setContactMedium] = useState()

  if(contactMedium === "appointment")
    return <BookAppointment/>
  return (
    <div className="bg-[#f7f7f7] pt-6 pb-6 h-screen">
      <Card className="mx-2">
        <Row gutter={20}>
          <Col xs={24} md={12}>
            <img className="w-[350px] m-auto" src={contactImg} loading="lazy" alt="contact us" />
          </Col>
          <Col xs={24} md={12} className="md:flex md:flex-col md:items-center md:justify-center">
            <p className="font-semibold mb-4 flex justify-center sm:text-lg lg:text-2xl">
              Contact Us
            </p>

            {/* <Button
              type="primary"
              className="mb-4  w-100 h-12 flex items-center justify-center rounded-xl"
              onClick={() => setContactMedium("appointment")}>
              <RiCalendarCheckLine className="mx-1" /> Book Appointment
            </Button> */}
            <Button
              type="primary"
              className="mb-4  w-100 h-12 flex items-center justify-center rounded-xl"
              onClick={() => setContactMedium("call")}>
              <IoCall className="mx-1" />
              Call us at +1 610-634-8736
            </Button>
            <Button
              type="primary"
              className="mb-8  w-100 h-12 flex items-center justify-center rounded-xl"
              onClick={() => setContactMedium("email")}>
              <MdEmail className="mx-1" /> Email
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  )
}

export default ContactUs
