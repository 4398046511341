import React from "react"

const RPMOnlyViewForm = ({ ques }) => {
  switch (ques.quesType) {
    case "text":
      return (
        <div style={{ marginTop: "10px" }}>
          {ques.datefield ? (
            <input
              required
              id={ques.id}
              type="date"
              disabled
              style={{ width: "100%", height: "50px" }}
              value={ques.text}
              // onClick={e=>console.log(ques.id)}//only id
            />
          ) : (
            <input
              required
              id={ques.id}
              type="text"
              disabled
              style={{ width: "100%", height: "50px" }}
              value={ques.notApplicable ? "" : ques.value ? ques.value : ""}
            />
          )}
        </div>
      )
    case "single":
      return (
        <div style={{ marginTop: "10px" }}>
          <div className="flex items-center justify-evenly">
            {ques.questionaryOptions.map((item, i) => (
              <div key={i}>
                <input
                  type="radio"
                  id={item.id}
                  name="single-select"
                  disabled
                  checked={item.checked}
                />
                <label className="capitalize">{item.name}</label>
              </div>
            ))}
          </div>
        </div>
      )
    case "bool":
      return (
        <div style={{ marginTop: "10px" }}>
          <div className="flex items-center">
            <div className="flex">
              <input
                type="radio"
                id={ques.id}
                checked={ques?.questionaryOptions[0] === "true"}
                disabled
              />
              <label className="mx-2">Yes</label>
            </div>
            <div className="flex mx-5">
              <input
                type="radio"
                id={ques.id}
                checked={ques?.questionaryOptions[0] === "false"}
                disabled
              />
              <label className="mx-2">No</label>
            </div>
          </div>
        </div>
      )
    case "multi":
      return (
        <div style={{ marginTop: "10px" }}>
          {ques.device === undefined ? (
            <>
              {ques.questionaryOptions.map((item, i) => (
                <div key={i} className="flex mb-1">
                  {/* onClick={e=>console.log(ques.id,i)} */}
                  <input type="checkbox" id={item.id} checked={item.checked} disabled />
                  <label className="mx-3 capitalize">{item.name}</label>
                </div>
              ))}
            </>
          ) : ques.device === "blood pressure machine" ? (
            ques.questionaryOptions.map((item, i) =>
              item.name === "Other" ? (
                <>
                  <div className="flex mb-1">
                    <input type="checkbox" id={item.id} disabled checked={item.checked} />
                    <label className="mx-3 capitalize">{item.name}</label>
                  </div>
                  {item.checked === true && (
                    <div>
                      <input
                        type="text"
                        style={{ width: "100%", height: "50px" }}
                        disabled
                        value={item.text}
                      />
                    </div>
                  )}
                </>
              ) : item.name === "None of the above" ? (
                <div className="flex mb-1">
                  <input type="checkbox" id={item.id} checked={item.checked} disabled />
                  <label className="mx-3 capitalize">{item.name}</label>
                </div>
              ) : (
                <div className="flex mb-1">
                  <input type="checkbox" id={item.id} checked={item.checked} disabled />
                  <label className="mx-3 capitalize">{item.name}</label>
                </div>
              )
            )
          ) : ques.device === "weight machine" || ques.device === "oximeter" ? (
            ques.questionaryOptions.map((item, i) => (
              <>
                <div className="flex mb-1">
                  <input type="checkbox" id={item.id} checked={item.checked} disabled />
                  <label className="mx-3 capitalize">{item.name}</label>
                </div>
                {item.checked === true
                  ? item.subQuestion.map((subQues, index) => (
                      <div key={index} className="card shadow rounded p-4 my-4">
                        <h6 className="mb-3">{subQues.name}</h6>
                        {subQues.questionaryOptions.map((innerQues, iii) =>
                          innerQues.name === "Other" ? (
                            <>
                              <div className="flex mb-1">
                                <input
                                  type="checkbox"
                                  id={innerQues.id}
                                  disabled
                                  checked={innerQues.checked}
                                />
                                <label className="mx-3 capitalize">{innerQues.name}</label>
                              </div>
                              {innerQues.checked === true && (
                                <div>
                                  <input
                                    type="text"
                                    style={{ width: "100%", height: "50px" }}
                                    disabled
                                    value={innerQues.text}
                                  />
                                </div>
                              )}
                            </>
                          ) : innerQues.name === "None of the above" ? (
                            <div className="flex mb-1">
                              <input
                                type="checkbox"
                                id={innerQues.id}
                                checked={innerQues.checked}
                                disabled
                              />
                              <label className="mx-3 capitalize">{innerQues.name}</label>
                            </div>
                          ) : (
                            <div className="flex mb-1">
                              <input
                                type="checkbox"
                                id={innerQues.id}
                                checked={innerQues.checked}
                                disabled
                              />
                              <label className="mx-3 capitalize">{innerQues.name}</label>
                            </div>
                          )
                        )}
                      </div>
                    ))
                  : null}
              </>
            ))
          ) : null}
        </div>
      )
    case "multi-field":
      return (
        <div style={{ marginTop: "10px" }}>
          {ques.questionaryOptions.map((item, i) =>
            item.field ? (
              <div key={i} className="my-3">
                <label className="capitalize">{item.name}</label>
                <br />
                <input
                  type="text"
                  style={{ width: "100%", height: "50px" }}
                  value={item.text}
                  disabled
                />
              </div>
            ) : (
              <div key={i} className="flex mb-1">
                <input type="checkbox" id={item.id} checked={item.checked} disabled />
                <label className="mx-3 capitalize">{item.name}</label>
              </div>
            )
          )}
        </div>
      )
    default:
      return <div>Invalid question</div>
  }
}

export default RPMOnlyViewForm
