import { useState, useEffect, lazy, Suspense } from "react"
import { ConfigProvider } from "antd"
import Loading from "../layouts/Loading/Loading"
import "./styles.css"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"
import { useLocation, useNavigate } from "react-router-dom"
const EmailForm = lazy(() => import("./EmailForm"))
const PhoneNumForm = lazy(() => import("./PhoneNumForm"))
const PersonalDetailsForm = lazy(() => import("./PersonalDetailsForm"))
const VerifyMaskEmailForm = lazy(() => import("./VerifyMaskEmailForm"))
const VerifyMaskPhoneNumForm = lazy(() => import("./VerifyMaskPhoneNumForm"))
const ContactUs = lazy(() => import("./ContactUs"))

const IdentifyPatient = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [showContact, setShowContact] = useState(null)
  const [loading, setLoading] = useState(true)
  const [enteredCred, setEnteredCred] = useState("")

  const location = useLocation()

  const navigate = useNavigate()

  const { userInfo: isWebLoggedIn } = useSelector(state => state.userInfo)

  const goToContact = () => {
    setShowContact(true)
  }

  const next = () => {
    setCurrentStep(prevStep => prevStep + 1)
  }

  const verified = ({ credential }) => {
    if (window?.isAppView) {
      window.webkit?.messageHandlers?.logout?.postMessage({
        value: credential
      })
    } else {
      navigate("/login", { state: { credential }, replace: true })
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (window?.isAppView) {
        if (window?.enteredCred) setEnteredCred(window.enteredCred)
      } else {
        if (isWebLoggedIn || !location?.state?.enteredCred) navigate("/")
        else setEnteredCred(location?.state?.enteredCred ?? "")
      }

      setLoading(false)
    }, 1000)
  }, [])

  window.back = () => {
    setCurrentStep(prev => (prev > 0 ? prev - 1 : 0))
  }

  return (
    <>
      <Helmet>
        <script>
          {`
            function fillDetails(enteredCred) {
              window.isAppView = true
              window.enteredCred = enteredCred
            }
          `}
        </script>
      </Helmet>
      <ConfigProvider
        theme={{
          token: { fontSize: 18 },
          components: {
            Input: { controlHeight: 48, borderRadius: 12, colorBgContainer: "#FBFBFB" },
            Button: { controlHeight: 48, borderRadius: 12 }
          }
        }}>
        {loading ? (
          <Loading />
        ) : (
          <Suspense fallback={<Loading />}>
            <div className="identify-patient-container">
              <div className="form-wrapper">
                {showContact ? (
                  <ContactUs />
                ) : (
                  <div className="px-3 m-auto">
                    {currentStep === 1 &&
                      (enteredCred?.includes("@") ? (
                        <PhoneNumForm verified={verified} next={next} />
                      ) : (
                        <EmailForm verified={verified} next={next} />
                      ))}
                    {currentStep === 2 && (
                      <PersonalDetailsForm next={next} goToContact={goToContact} />
                    )}
                    {currentStep === 3 && (
                      <VerifyMaskEmailForm
                        verified={verified}
                        next={next}
                        goToContact={goToContact}
                      />
                    )}
                    {currentStep === 4 && (
                      <VerifyMaskPhoneNumForm verified={verified} goToContact={goToContact} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </Suspense>
        )}
      </ConfigProvider>
    </>
  )
}

export default IdentifyPatient
