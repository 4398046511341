import {
  GET_CCM_FAIL,
  GET_CCM_REQUEST,
  GET_CCM_SUCCESS,
  POST_CCM_USER_FAIL,
  POST_CCM_USER_REQUEST,
  POST_CCM_USER_SUCCESS,
  GET_CCM_CAREPLAN_REQUEST,
  GET_CCM_CAREPLAN_SUCCESS,
  GET_CCM_CAREPLAN_FAIL,
  GET_FACILITY_ADDRESS_REQUEST,
  GET_FACILITY_ADDRESS_SUCCESS,
  GET_FACILITY_ADDRESS_FAIL,
  GET_FILTER_USERS_REQUEST,
  RESET_FILTER_USERS_REQUEST,
  GET_FILTER_USERS_SUCCESS,
  GET_FILTER_USERS_FAIL,
  GET_USER_SEARCH_REQUEST,
  GET_USER_SEARCH_SUCCESS,
  GET_USER_SEARCH_FAIL,
  GET_CCM_FOLLOW_UP_REQUEST,
  GET_CCM_FOLLOW_UP_SUCCESS,
  GET_CCM_FOLLOW_UP_FAIL
} from "../types/ccmConstant"
import { axiosGet, axiosPost } from "../axios"
import { userLogoutAction } from "./userActions"

export const getccmDataActions = moniterId => async dispatch => {
  try {
    dispatch({
      type: GET_CCM_REQUEST
    })

    const { data } = await axiosGet(`/caretaker/v1/getUsersForMoniterType?moniterId=${moniterId}`)

    dispatch({
      type: GET_CCM_SUCCESS,
      payload: data.result
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_CCM_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}
export const postCCMUserActions = (userId, formInputs) => async dispatch => {
  try {
    dispatch({
      type: POST_CCM_USER_REQUEST
    })

    // console.log('formInputs',formInputs);
    // debugger

    const { data } = await axiosPost(`/caretaker/v1/postCCMCareplan`, {
      userId,
      careplan: { ...formInputs }
    })

    dispatch({
      type: POST_CCM_USER_SUCCESS,
      payload: data
    })
    window.location.href = "/ccm-newPatient"
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_CCM_USER_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.detail
    })
  }
}

export const getccmCareplanDataActions = patientId => async dispatch => {
  try {
    dispatch({
      type: GET_CCM_CAREPLAN_REQUEST
    })

    const { data } = await axiosGet(`/api/ccm/careplan/comprehensive/latest/${patientId}/fetch`)

    dispatch({
      type: GET_CCM_CAREPLAN_SUCCESS,
      payload: data?.details
    })
    return data?.details
  } catch (error) {
    console.debug(error?.response)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_CCM_CAREPLAN_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getFacilityAddressAction = orgId => async dispatch => {
  try {
    dispatch({
      type: GET_FACILITY_ADDRESS_REQUEST
    })
    // console.log(orgId)
    const { data } = await axiosGet(`/support/v1/getFaciltiyAddressByOrgId?orgId=${orgId}`)
    // console.log('facility address action',data)
    dispatch({
      type: GET_FACILITY_ADDRESS_SUCCESS,
      payload: data.organizations
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_FACILITY_ADDRESS_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getFilterUsersAction = (address, unit, room, bedPosition, orgId) => async dispatch => {
  console.log("orgId", orgId)
  try {
    dispatch({
      type: GET_FILTER_USERS_REQUEST
    })
    const { data } = await axiosGet(
      `/filter/v1/getUsers?orgId=${orgId}&address=${address}&unit=${unit}&room=${room}&bedPosition=${bedPosition}`
    )
    dispatch({
      type: GET_FILTER_USERS_SUCCESS,
      payload: data.result
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_FILTER_USERS_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const resetFilterUsersAction = () => async dispatch => {
  try {
    dispatch({
      type: RESET_FILTER_USERS_REQUEST
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_FILTER_USERS_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getUserSearchFilters = orgId => async dispatch => {
  try {
    dispatch({
      type: GET_USER_SEARCH_REQUEST
    })
    const { data } = await axiosGet(`/filter/v1/getUserSearchFilters?orgId=${orgId}`)
    dispatch({
      type: GET_USER_SEARCH_SUCCESS,
      payload: data.result
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_USER_SEARCH_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getUsersForCcmFollowUpAction = (month, year, orgId) => async dispatch => {
  try {
    dispatch({
      type: GET_CCM_FOLLOW_UP_REQUEST
    })
    const { data } = await axiosGet(
      `/caretaker/v1/getUserForCCMFollowUpPlan?month=${month}&year=${year}&orgId=${orgId}`
    )
    dispatch({
      type: GET_CCM_FOLLOW_UP_SUCCESS,
      payload: data.result
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_CCM_FOLLOW_UP_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}
