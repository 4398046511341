import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Col, Input, Modal, Select } from "antd"
import { Controller, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import * as yup from "yup"
import {
  getEquipmentStatusAction,
  postEquipmentFunctioningStatusAction
} from "../../redux/actions/equipmentStatusAction"
import { useToasts } from "react-toast-notifications"
import { useEffect } from "react"
import { DownOutlined } from "@ant-design/icons"

const {TextArea} = Input

const options = [
  {
    value: "yes_i_have_received_the_equipment_and_it_is_working_perfectly",
    label: "Yes, I have received the equipment and it is working perfectly."
  },
  {
    value: "yes_i_have_received_the_equipment_but_some_equipment_is_are_missing",
    label: "Yes, I have received the equipment but some components are missing."
  },
  {
    value: "yes_but_i_am_facing_some_issues_with_the_equipment",
    label: "Yes, but I am facing some issues with the equipment."
  },
  { value: "no_i_have_not_received_the_equipment", label: "No, I have not received the equipment." }
]

const STATUS_ERR = "Please select any one option"

const schema = yup.object().shape({
  status: yup.string().required(STATUS_ERR)
})
const EquipmentFunctionStatus = ({ showEquipmentFunctionModal, setShowEquipmentFunctionModal }) => {
  const { error: postEquipmentFunctioningError, loading: postEquipmentFunctioningLoading } =
    useSelector(state => state.postEquipmentFunctioning)
  const patientId = JSON.parse(localStorage.getItem("id"))
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const {
    control,
    getValues,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    shouldFocusError: false,
    resolver: yupResolver(schema)
  })
  useEffect(() => {
    const subscription = watch(["status"])
    return () => (subscription && subscription?.unsubscribe ? subscription.unsubscribe() : null)
  }, [watch])

  const onSubmit = async data => {
    const result = await dispatch(postEquipmentFunctioningStatusAction(patientId, data))
    if (result?.status === 200 || result?.status === 201) {
      addToast("Feedback submitted successfully", { appearance: "success", autoDismiss: true })
      dispatch(getEquipmentStatusAction(patientId))
      setShowEquipmentFunctionModal(false)
    } else {
      console.log(postEquipmentFunctioningError)
    }
  }
  return (
    <Modal
      width={600}
      footer={null}
      closeIcon={null}
      open={showEquipmentFunctionModal}
      title={<h4 className="text-xl flex justify-center">Have you received the equipment?</h4>}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col">
          <Controller
            name="status"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                className="w-full mt-12"
                size="large"
                suffixIcon={<DownOutlined style={{ color: "rgb(0, 0, 0)" }} />}
                status={errors.status ? "error" : undefined}
                {...field}
                options={options}
              />
            )}
          />
          {/* only show for the missing and the issue in the equipment */}
          {getValues("status") === options[1]?.value || getValues("status") === options[2] ?.value ? <Controller
            name="notes"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextArea
                className="w-full mt-12 h-10"
                id="input-notes"
                rows={4}
                placeholder="Please describe..."
                maxLength={500}
                {...field}
              />
            )}
          />: null}
          {errors.status ? <small className="text-danger">{errors.status.message}</small> : null}
          {postEquipmentFunctioningError ? (
            <Col className="mb-4" xs={24}>
              <p className="text-danger font-semibold">{postEquipmentFunctioningError}</p>
            </Col>
          ) : null}
          <Button
            type="primary"
            size="large"
            disabled={postEquipmentFunctioningLoading}
            htmlType="submit"
            className="px-8 mx-40 mt-36">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default EquipmentFunctionStatus
