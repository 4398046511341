import moment from "moment"
import { axiosGet, axiosPost } from "../axios"
import {
  GET_EQUIPMENT_STATUS_FAIL,
  GET_EQUIPMENT_STATUS_REQUEST,
  GET_EQUIPMENT_STATUS_SUCCESS,
  POST_EQUIPMENT_FUNCTIONING_STATUS_FAIL,
  POST_EQUIPMENT_FUNCTIONING_STATUS_REQUEST,
  POST_EQUIPMENT_FUNCTIONING_STATUS_SUCCESS,
  POST_EQUIPMENT_METHOD_STATUS_FAIL,
  POST_EQUIPMENT_METHOD_STATUS_REQUEST,
  POST_EQUIPMENT_METHOD_STATUS_SUCCESS
} from "../types/equipmentStatusConstant"
import { userLogoutAction } from "./userActions"

export const getEquipmentStatusAction = patientId => async dispatch => {
  try {
    dispatch({
      type: GET_EQUIPMENT_STATUS_REQUEST
    })

    const { data } = await axiosGet(`/api/cs/patients/equipmentStatus/${patientId}/fetch`)

    dispatch({
      type: GET_EQUIPMENT_STATUS_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_EQUIPMENT_STATUS_FAIL,
      payload:
        typeof error?.response?.data?.details === "object" &&
        Object.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const postEquipmentFunctioningStatusAction = (patientId, formData ) => async dispatch => {
    try {
      dispatch({
        type: POST_EQUIPMENT_FUNCTIONING_STATUS_REQUEST
      })
      const updatedFormData = {...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
      const data = await axiosPost(`/api/cs2/patients/deviceFunctioningStatus/${patientId}/create`, updatedFormData)
  
      dispatch({
        type: POST_EQUIPMENT_FUNCTIONING_STATUS_SUCCESS,
        payload: data.details
      })
      return data
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      console.log(error)
      dispatch({
        type: POST_EQUIPMENT_FUNCTIONING_STATUS_FAIL,
        payload:  typeof error?.response?.data?.details === "object" &&
        Object.values(error?.response?.data?.details)?.[0]
      })
    }
  }

  export const postEquipmentMethodStatusAction = (patientId, formData ) => async dispatch => {
    try {
      dispatch({
        type: POST_EQUIPMENT_METHOD_STATUS_REQUEST
      })
      const updatedFormData = {...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
      const data = await axiosPost(`/api/cs2/patients/equipmentStatus/${patientId}/create`, updatedFormData)
  
      dispatch({
        type: POST_EQUIPMENT_METHOD_STATUS_SUCCESS,
        payload: data.details
      })
      return data
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      console.log(error)
      dispatch({
        type: POST_EQUIPMENT_METHOD_STATUS_FAIL,
        payload:  typeof error?.response?.data?.details === "object" &&
        Object.values(error?.response?.data?.details)?.[0]
      })
    }
  }