import axios from "axios"

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
axios.defaults.headers.common["time-zone"] = `${timezone}`

export const TempAxiosGet = async (token, url) => {
  try {
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
  } catch (err) {
    // console.log('error temp api',err)
    window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" })
  }
}

export const TempAxiosPost = async (token, payload, url) => {
  try {
    const { data } = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
  } catch (err) {
    // console.log('error',err)
    window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" })
  }
}

export const TempAxiosPostWithoutPayload = async (token, url) => {
  try {
    const { data } = await axios.post(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
    // console.log('rtmcallmob', data)
  } catch (err) {
    // console.log('error',err)
    window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" })
  }
}
