import {
  EMAIL_VERIFICATION_FAIL,
  EMAIL_VERIFICATION_REQUEST,
  EMAIL_VERIFICATION_SUCCESS,
  FORGET_PASSWORD_CHANGE_FAIL,
  FORGET_PASSWORD_CHANGE_REQUEST,
  FORGET_PASSWORD_CHANGE_SUCCESS,
  UPDATE_EMAIL_FAIL,
  UPDATE_EMAIL_REQUEST,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_NUMBER_FAIL,
  UPDATE_NUMBER_REQUEST,
  UPDATE_NUMBER_SUCCESS
} from "../types/utilConstant"

export const emailVerificationReducer = (state = {}, action) => {
  switch (action.type) {
    case EMAIL_VERIFICATION_REQUEST:
      return { loading: true }
    case EMAIL_VERIFICATION_SUCCESS:
      return { loading: false, emailVerification: action.payload }
    case EMAIL_VERIFICATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const passwordChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case FORGET_PASSWORD_CHANGE_REQUEST:
      return { loading: true }
    case FORGET_PASSWORD_CHANGE_SUCCESS:
      return { loading: false, forgetPasswordChange: action.payload }
    case FORGET_PASSWORD_CHANGE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const emailChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_EMAIL_REQUEST:
      return { loading: true }
    case UPDATE_EMAIL_SUCCESS:
      return { loading: false, emailUpdate: action.payload }
    case UPDATE_EMAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const numberChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_NUMBER_REQUEST:
      return { loading: true }
    case UPDATE_NUMBER_SUCCESS:
      return { loading: false, numberUpdate: action.payload }
    case UPDATE_NUMBER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
