export const GET_EQUIPMENT_STATUS_REQUEST = "GET_EQUIPMENT_STATUS_REQUEST"
export const GET_EQUIPMENT_STATUS_SUCCESS = "GET_EQUIPMENT_STATUS_SUCCESS"
export const GET_EQUIPMENT_STATUS_FAIL = "GET_EQUIPMENT_STATUS_FAIL"

export const POST_EQUIPMENT_FUNCTIONING_STATUS_REQUEST = "POST_EQUIPMENT_FUNCTIONING_STATUS_REQUEST"
export const POST_EQUIPMENT_FUNCTIONING_STATUS_SUCCESS = "POST_EQUIPMENT_FUNCTIONING_STATUS_SUCCESS"
export const POST_EQUIPMENT_FUNCTIONING_STATUS_FAIL = "POST_EQUIPMENT_FUNCTIONING_STATUS_FAIL"

export const POST_EQUIPMENT_METHOD_STATUS_REQUEST = "POST_EQUIPMENT_METHOD_STATUS_REQUEST"
export const POST_EQUIPMENT_METHOD_STATUS_SUCCESS = "POST_EQUIPMENT_METHOD_STATUS_SUCCESS"
export const POST_EQUIPMENT_METHOD_STATUS_FAIL = "POST_EQUIPMENT_METHOD_STATUS_FAIL"