import React from "react"

import { createRoot } from "react-dom/client"
import { RouterProvider, createBrowserRouter } from "react-router-dom"

import { Provider } from "react-redux"
import { ToastProvider } from "react-toast-notifications"

import Routes from "./routes"

import store from "./redux/store"

import reportWebVitals from "./reportWebVitals"

import "antd/dist/reset.css"
import "./index.css"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { ConfigProvider } from "antd"
import NoInternetConnection from "./utils/NoInternetConnection"

const router = createBrowserRouter(Routes)

const root = createRoot(document.getElementById("root"))

// eslint-disable-next-line
document.addEventListener("wheel", function (event) {
  if (document.activeElement.type === "number") {
    document.activeElement.blur()
  }
})

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID ?? ""}>
    <Provider store={store}>
      <ToastProvider>
        <NoInternetConnection />
        <ConfigProvider theme={{ token: { fontFamily: '"Lato", sans-serif' } }}>
        <RouterProvider router={router} />
        </ConfigProvider>
    </ToastProvider>
    </Provider>
  </GoogleOAuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
