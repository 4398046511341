import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_BROWSER_STOARGE_KEY; // Secret key for encryption/decryption

// Function to encrypt a boolean value
const encryptBoolean = (value) => {
  const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), secretKey).toString();
  return encryptedValue;
};

// Function to decrypt an encrypted string to a boolean value
const decryptBoolean = (encryptedValue) => {
   if(!encryptedValue) return null
  const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

export { encryptBoolean, decryptBoolean };