import {
  GET_RPM_FAIL,
  GET_RPM_REQUEST,
  GET_RPM_SUCCESS,
  POST_RPM_USER_FAIL,
  POST_RPM_USER_REQUEST,
  POST_RPM_USER_SUCCESS,
  GET_RPM_CAREPLAN_REQUEST,
  GET_RPM_CAREPLAN_SUCCESS,
  GET_RPM_CAREPLAN_FAIL,
  GET_RPM_FOLLOW_UP_REQUIEST,
  GET_RPM_FOLLOW_UP_SUCCESS,
  GET_RPM_FOLLOW_UP_FAIL,
  POST_RPM_TRIAGE_REQUEST,
  POST_RPM_TRIAGE_SUCCESS,
  POST_RPM_TRIAGE_FAIL,
  GET_RPM_DEVICE_REQUEST,
  GET_RPM_DEVICE_SUCCESS,
  GET_RPM_DEVICE_FAIL
} from "../types/rpmConstants"

export const getRpmReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RPM_REQUEST:
      return { loading: true }
    case GET_RPM_SUCCESS:
      return { loading: false, getRpm: action.payload }
    case GET_RPM_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postRpmReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_RPM_USER_REQUEST:
      return { loading: true }
    case POST_RPM_USER_SUCCESS:
      return { loading: false, postRPMUserData: action.payload }
    case POST_RPM_USER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getRpmCarePlanReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RPM_CAREPLAN_REQUEST:
      return { loading: true }
    case GET_RPM_CAREPLAN_SUCCESS:
      return { loading: false, getRpmCarePlan: action.payload }
    case GET_RPM_CAREPLAN_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getUsersForRpmFollowUpReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RPM_FOLLOW_UP_REQUIEST:
      return { loading: true }
    case GET_RPM_FOLLOW_UP_SUCCESS:
      return { loading: false, rpmFollowUpUsers: action.payload }
    case GET_RPM_FOLLOW_UP_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postRpmTriageReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_RPM_TRIAGE_REQUEST:
      return { loading: true }
    case POST_RPM_TRIAGE_SUCCESS:
      return { loading: false, postRpmTriageData: action.payload }
    case POST_RPM_TRIAGE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getRpmDeviceReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RPM_DEVICE_REQUEST:
      return { loading: true }
    case GET_RPM_DEVICE_SUCCESS:
      return { loading: false, rpmDevices: action.payload }
    case GET_RPM_DEVICE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
