import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { Typography } from "antd"
import { Mixpanel } from "../../utils/mixpanel"
import { NavLink } from "react-router-dom"
import { clearUserLoginError } from "../../redux/actions/userActions"

const CountdownTimer = ({ targetDateString, onExpire }) => {
  const calculateTimeRemaining = () => moment.duration(moment.utc(targetDateString).diff(moment()))

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining())

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const newTimeRemaining = calculateTimeRemaining()
      if (newTimeRemaining.asMilliseconds() <= 0) {
        clearInterval(timerInterval)
        setTimeRemaining(moment.duration(0))
        onExpire() // Notify the parent component when the timer expires
      } else {
        setTimeRemaining(newTimeRemaining)
      }
    }, 1000)

    return () => clearInterval(timerInterval)
  }, [targetDateString, onExpire])

  return (
    <span>
      <span className="text-primary">
        {String(timeRemaining.minutes()).padStart(2, "0")}:
        {String(timeRemaining.seconds()).padStart(2, "0")}
      </span>
      s
    </span>
  )
}

const LoginAttemptsMsg = ({ setDisableLogin }) => {
  const [timerExpired, setTimerExpired] = useState(false)
  const { error: authorizeErr } = useSelector(state => state.userInfo)

  const dispatch = useDispatch()

  const errKeys = authorizeErr && typeof authorizeErr === "object" ? Object.keys(authorizeErr) : []

  const handleTimerExpire = () => {
    setTimerExpired(true)
    setDisableLogin(false)
  }

  useEffect(() => {
    if (authorizeErr?.blockedTill) {
      setTimerExpired(false)
      setDisableLogin(true)
    }
  }, [authorizeErr])

  useEffect(() => {
    dispatch(clearUserLoginError())
    setDisableLogin(false)
  }, [])

  return (
    <>
      {errKeys?.includes("blockedTill") && authorizeErr?.blockedTill === null && (
        <div
          style={{
            backgroundColor: "rgba(255, 59, 48, 0.06)",
            borderLeft: "2px solid rgba(255, 59, 48, .6)"
          }}
          className="px-3 py-2 my-4">
          <p>
            <strong>Warning: </strong>
            After 5 consecutive unsuccessful attempts, you will not be able to log in for{" "}
            {authorizeErr?.count + authorizeErr?.attemptsLeft} mins.
          </p>
          <p className="mt-2">
            <strong>Attempts Remaining: </strong>
            {authorizeErr?.attemptsLeft}
          </p>
        </div>
      )}

      {!timerExpired && errKeys?.includes("blockedTill") && authorizeErr?.blockedTill && (
        <Typography.Title level={5} className="mt-3 mb-4">
          You’ve reached maximum number of login attempts, please try again in{" "}
          <CountdownTimer
            onExpire={handleTimerExpire}
            targetDateString={authorizeErr.blockedTill}
          />{" "}
          or try{" "}
          <NavLink
            to="/forget-password"
            style={{ textDecoration: "underline" }}
            onClick={() => Mixpanel.track("Forgot Password Clicked")}>
            forgot password
          </NavLink>
        </Typography.Title>
      )}
    </>
  )
}

export default LoginAttemptsMsg
