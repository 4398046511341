export const PATIENT_HEALTH_KPI_REQUEST = "PATIENT_HEALTH_KPI_REQUEST"
export const PATIENT_HEALTH_KPI_SUCCESS = "PATIENT_HEALTH_KPI_SUCCESS"
export const PATIENT_HEALTH_KPI_FAIL = "PATIENT_HEALTH_KPI_FAIL"

export const PATIENT_WM_REQUEST = "PATIENT_WM_DETAILS_REQUEST"
export const PATIENT_WM_SUCCESS = "PATIENT_WM_DETAILS_SUCCESS"
export const PATIENT_WM_FAIL = "PATIENT_WM_DETAILS_FAIL"

export const PATIENT_BP_REQUEST = "PATIENT_BP_DETAILS_REQUEST"
export const PATIENT_BP_SUCCESS = "PATIENT_BP_DETAILS_SUCCESS"
export const PATIENT_BP_FAIL = "PATIENT_BP_DETAILS_FAIL"

export const PATIENT_OXY_REQUEST = "PATIENT_OXY_DETAILS_REQUEST"
export const PATIENT_OXY_SUCCESS = "PATIENT_OXY_DETAILS_SUCCESS"
export const PATIENT_OXY_FAIL = "PATIENT_OXY_DETAILS_FAIL"

export const PATIENT_GLUCOSE_REQUEST = "PATIENT_GLUCOSE_DETAILS_REQUEST"
export const PATIENT_GLUCOSE_SUCCESS = "PATIENT_GLUCOSE_DETAILS_SUCCESS"
export const PATIENT_GLUCOSE_FAIL = "PATIENT_GLUCOSE_DETAILS_FAIL"

export const PATIENT_LATEST_READING_REQUEST = "PATIENT_LATEST_READING_DETAIL_REQUEST"
export const PATIENT_LATEST_READING_SUCCESS = "PATIENT_LATEST_READING_DETAIL_SUCCESS"
export const PATIENT_LATEST_READING_FAIL = "PATIENT_LATEST_READING_DETAIL_FAIL"

export const PATIENT_INFO_REQUEST = "PATIENT_INFO_REQUEST"
export const PATIENT_INFO_SUCCESS = "PATIENT_INFO_SUCCESS"
export const PATIENT_INFO_FAIL = "PATIENT_INFO_FAIL"

export const PATIENT_TIMELINE_REQUEST = "PATIENT_TIMELINE_REQUEST"
export const PATIENT_TIMELINE_SUCCESS = "PATIENT_TIMELINE_SUCCESS"
export const PATIENT_TIMELINE_FAIL = "PATIENT_TIMELINE_FAIL"

export const PATIENT_WMR_REQUEST = "PATIENT_WM_REQUEST"
export const PATIENT_WMR_SUCCESS = "PATIENT_WM_SUCCESS"
export const PATIENT_WMR_FAIL = "PATIENT_WM_FAIL"

export const PATIENT_BPR_REQUEST = "PATIENT_BP_REQUEST"
export const PATIENT_BPR_SUCCESS = "PATIENT_BP_SUCCESS"
export const PATIENT_BPR_FAIL = "PATIENT_BP_FAIL"

export const PATIENT_OXYR_REQUEST = "PATIENT_OXY_REQUEST"
export const PATIENT_OXYR_SUCCESS = "PATIENT_OXY_SUCCESS"
export const PATIENT_OXYR_FAIL = "PATIENT_OXY_FAIL"

export const ADD_PATIENT_REQUEST = "ADD_PATIENT_REQUEST"
export const ADD_PATIENT_SUCCESS = "ADD_PATIENT_SUCCESS"
export const ADD_PATIENT_FAIL = "ADD_PATIENT_FAIL"

export const UPDATE_PATIENT_REQUEST = "UPDATE_PATIENT_REQUEST"
export const UPDATE_PATIENT_SUCCESS = "UPDATE_PATIENT_SUCCESS"
export const UPDATE_PATIENT_FAIL = "UPDATE_PATIENT_FAIL"

export const ADD_MANUAL_TIME_REQUEST = "ADD_MANUAL_TIME_REQUEST"
export const ADD_MANUAL_TIME_SUCCESS = "ADD_MANUAL_TIME_SUCCESS"
export const ADD_MANUAL_TIME_FAIL = "ADD_MANUAL_TIME_FAIL"

export const GET_MANUAL_TIME_REQUEST = "GET_MANUAL_TIME_REQUEST"
export const GET_MANUAL_TIME_SUCCESS = "GET_MANUAL_TIME_SUCCESS"
export const GET_MANUAL_TIME_FAIL = "GET_MANUAL_TIME_FAIL"

export const GET_CHRONIC_CONDITION_REQUEST = "GET_CHRONIC_CONDITION_REQUEST"
export const GET_CHRONIC_CONDITION_SUCCESS = "GET_CHRONIC_CONDITION_SUCCESS"
export const GET_CHRONIC_CONDITION_FAIL = "GET_CHRONIC_CONDITION_FAIL"

export const CRITICAL_STATUS_REQUEST = "CRITICAL_STATUS_REQUEST"
export const CRITICAL_STATUS_SUCCESS = "CRITICAL_STATUS_SUCCESS"
export const CRITICAL_STATUS_FAIL = "CRITICAL_STATUS_FAIL"

export const CHRONIC_TYPE_REQUEST = "CHRONIC_TYPE_REQUEST"
export const CHRONIC_TYPE_SUCCESS = "CHRONIC_TYPE_SUCCESS"
export const CHRONIC_TYPE_FAIL = "CHRONIC_TYPE_FAIL"

export const POST_CHRONIC_DATA_REQUEST = "POST_CHRONIC_DATA_REQUEST"
export const POST_CHRONIC_DATA_SUCCESS = "POST_CHRONIC_DATA_SUCCESS"
export const POST_CHRONIC_DATA_FAIL = "POST_CHRONIC_DATA_FAIL"

export const GET_REPORT_DATA_REQUEST = "GET_REPORT_DATA_REQUEST"
export const GET_REPORT_DATA_SUCCESS = "GET_REPORT_DATA_SUCCESS"
export const GET_REPORT_DATA_FAIL = "GET_REPORT_DATA_FAIL"

export const UPDATE_PATIENT_LANDLINE_REQUEST = "UPDATE_PATIENT_LANDLINE_REQUEST"
export const UPDATE_PATIENT_LANDLINE_SUCCESS = "UPDATE_PATIENT_LANDLINE_SUCCESS"
export const UPDATE_PATIENT_LANDLINE_FAIL = "UPDATE_PATIENT_LANDLINE_FAIL"

export const POST_CONCERN_REQUEST = "POST_CONCERN_REQUEST"
export const POST_CONCERN_SUCCESS = "POST_CONCERN_SUCCESS"
export const POST_CONCERN_FAIL = "POST_CONCERN_FAIL"

export const GET_CONCERN_REQUEST = "GET_CONCERN_REQUEST"
export const GET_CONCERN_SUCCESS = "GET_CONCERN_SUCCESS"
export const GET_CONCERN_FAIL = "GET_CONCERN_FAIL"

//

export const GET_USER_PAST_CONSULTATION_REQUEST = "GET_USER_PAST_CONSULTATION_REQUEST"
export const GET_USER_PAST_CONSULTATION_SUCCESS = "GET_USER_PAST_CONSULTATION_SUCCESS"
export const GET_USER_PAST_CONSULTATION_FAIL = "GET_USER_PAST_CONSULTATION_FAIL"

export const GET_USER_FUTURE_CONSULTATION_REQUEST = "GET_USER_FUTURE_CONSULTATION_REQUEST"
export const GET_USER_FUTURE_CONSULTATION_SUCCESS = "GET_USER_FUTURE_CONSULTATION_SUCCESS"
export const GET_USER_FUTURE_CONSULTATION_FAIL = "GET_USER_FUTURE_CONSULTATION_FAIL"

export const POST_CALL_REQUEST = "POST_CALL_REQUEST"
export const POST_CALL_SUCCESS = "POST_CALL_SUCCESS"
export const POST_CALL_FAIL = "POST_CALL_FAIL"

export const GET_USER_MONITOR_TYPE_REQUEST = "GET_USER_MONITOR_TYPE_REQUEST"
export const GET_USER_MONITOR_TYPE_SUCCESS = "GET_USER_MONITOR_TYPE_SUCCESS"
export const GET_USER_MONITOR_TYPE_FAIL = "GET_USER_MONITOR_TYPE_FAIL"

export const GET_USER_BOOKING_REQUEST = "GET_USER_BOOKING_REQUEST"
export const GET_USER_BOOKING_SUCCESS = "GET_USER_BOOKING_SUCCESS"
export const GET_USER_BOOKING_FAIL = "GET_USER_BOOKING_FAIL"

export const POST_USER_CONSULTATION_REQUEST = "POST_USER_CONSULTATION_REQUEST"
export const POST_USER_CONSULTATION_SUCCESS = "POST_USER_CONSULTATION_SUCCESS"
export const POST_USER_CONSULTATION_FAIL = "POST_USER_CONSULTATION_FAIL"

export const POST_USER_CANCEL_CONSULTATION_REQUEST = "POST_USER_CANCEL_CONSULTATION_REQUEST"
export const POST_USER_CANCEL_CONSULTATION_SUCCESS = "POST_USER_CANCEL_CONSULTATION_SUCCESS"
export const POST_USER_CANCEL_CONSULTATION_FAIL = "POST_USER_CANCEL_CONSULTATION_FAIL"

export const PATIENT_VITALS_REQUEST = "PATIENT_VITALS_REQUEST"
export const PATIENT_VITALS_SUCCESS = "PATIENT_VITALS_SUCCESS"
export const PATIENT_VITALS_FAIL = "PATIENT_VITALS_FAIL"

export const POST_PATIENT_VITALS_REQUEST = "POST_PATIENT_VITALS_REQUEST"
export const POST_PATIENT_VITALS_SUCCESS = "POST_PATIENT_VITALS_SUCCESS"
export const POST_PATIENT_VITALS_FAIL = "POST_PATIENT_VITALS_FAIL"
export const POST_PATIENT_VITALS_RESET = "POST_PATIENT_VITALS_RESET"

export const GET_PATIENT_CARETAKER_REQUEST = "GET_PATIENT_CARETAKER_REQUEST"
export const GET_PATIENT_CARETAKER_SUCCESS = "GET_PATIENT_CARETAKER_SUCCESS"
export const GET_PATIENT_CARETAKER_FAIL = "GET_PATIENT_CARETAKER_FAIL"

export const PATIENT_MONITORING_REQUEST = "PATIENT_MONITORING_REQUEST"
export const PATIENT_MONITORING_SUCCESS = "PATIENT_MONITORING_SUCCESS"
export const PATIENT_MONITORING_FAIL = "PATIENT_MONITORING_FAIL"

export const PATIENT_DIAGNOSTIC_REQUEST = "PATIENT_DIAGNOSTIC_REQUEST"
export const PATIENT_DIAGNOSTIC_SUCCESS = "PATIENT_DIAGNOSTIC_SUCCESS"
export const PATIENT_DIAGNOSTIC_FAIL = "PATIENT_DIAGNOSTIC_FAIL"

export const PATIENT_CRITICAL_DEVICES_REQUEST = "PATIENT_CRITICAL_DEVICES_REQUEST"
export const PATIENT_CRITICAL_DEVICES_SUCCESS = "PATIENT_CRITICAL_DEVICES_SUCCESS"
export const PATIENT_CRITICAL_DEVICES_FAIL = "PATIENT_CRITICAL_DEVICES_FAIL"

export const POST_EMERGENCY_CONTACT_DETAILS_REQUEST = "POST_EMERGENCY_CONTACT_DETAILS_REQUEST"
export const POST_EMERGENCY_CONTACT_DETAILS_SUCCESS = "POST_EMERGENCY_CONTACT_DETAILS_SUCCESS"
export const POST_EMERGENCY_CONTACT_DETAILS_FAIL = "POST_EMERGENCY_CONTACT_DETAILS_FAIL"

export const GET_EMERGENCY_CONTACT_DETAILS_REQUEST = "GET_EMERGENCY_CONTACT_DETAILS_REQUEST"
export const GET_EMERGENCY_CONTACT_DETAILS_SUCCESS = "GET_EMERGENCY_CONTACT_DETAILS_SUCCESS"
export const GET_EMERGENCY_CONTACT_DETAILS_FAIL = "GET_EMERGENCY_CONTACT_DETAILS_FAIL"

export const UPDATE_EMERGENCY_CONTACT_DETAILS_REQUEST = "UPDATE_EMERGENCY_CONTACT_DETAILS_REQUEST"
export const UPDATE_EMERGENCY_CONTACT_DETAILS_SUCCESS = "UPDATE_EMERGENCY_CONTACT_DETAILS_SUCCESS"
export const UPDATE_EMERGENCY_CONTACT_DETAILS_FAIL = "UPDATE_EMERGENCY_CONTACT_DETAILS_FAIL"

export const POST_SHIPPING_ADDRESS_REQUEST = "POST_SHIPPING_ADDRESS_REQUEST"
export const POST_SHIPPING_ADDRESS_SUCCESS = "POST_SHIPPING_ADDRESS_SUCCESS"
export const POST_SHIPPING_ADDRESS_FAIL = "POST_SHIPPING_ADDRESS_FAIL"

export const GET_SHIPPING_ADDRESS_REQUEST = "GET_SHIPPING_ADDRESS_REQUEST"
export const GET_SHIPPING_ADDRESS_SUCCESS = "GET_SHIPPING_ADDRESS_SUCCESS"
export const GET_SHIPPING_ADDRESS_FAIL = "GET_SHIPPING_ADDRESS_FAIL"

export const UPDATE_SHIPPING_ADDRESS_REQUEST = "UPDATE_SHIPPING_ADDRESS_REQUEST"
export const UPDATE_SHIPPING_ADDRESS_SUCCESS = "UPDATE_SHIPPING_ADDRESS_SUCCESS"
export const UPDATE_SHIPPING_ADDRESS_FAIL = "UPDATE_SHIPPING_ADDRESS_FAIL"