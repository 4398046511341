import React, { useEffect, useState } from "react"

import { InfoOutlined } from "@ant-design/icons"
import { Alert, Card, Tooltip, Button, Table, Row, Col, Radio, DatePicker } from "antd"

import ReactApexChart from "react-apexcharts"

import { useSelector, useDispatch } from "react-redux"
import { getPatientVitals, getWmDetails } from "../../redux/actions/patientAction"
import moment from "moment/moment"
import Loading from "../layouts/Loading/Loading"

const dateFormat = value => moment(value * 1000).format("DD-MMM-YYYY [at] hh:mm A")

const userTimeFormat = value => moment(value).format("DD-MMM-YYYY [at] hh:mm A")

const lineChartOptions = (series, categories) => ({
  series,
  options: {
    chart: {
      type: "line",
      zoom: {
        enabled: false
      }
    },
    colors: ["#007aff"],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "straight"
    },
    xaxis: {
      categories
    }
  }
})

const columns = [
  {
    key: "weight",
    title: "Weight",
    dataIndex: "weight",
    render: (_, { weight, weightUnit }) => `${weight}${weightUnit}`
  },
  {
    key: "bmi",
    title: "BMI",
    dataIndex: "bmi"
  },
  {
    key: "muscleMass",
    title: "Muscle Mass",
    dataIndex: "muscleMass"
  },
  {
    key: "activeDate",
    title: "Taken At",
    dataIndex: "activeDate",
    render: (_, { createdAt, readingDate }) =>
      readingDate ? userTimeFormat(readingDate) : dateFormat(createdAt)
  }
]

const currentDate = moment.utc().endOf("day").toDate().toISOString()
const durationObj = {
  week: moment.utc(currentDate).subtract(7, "days").startOf("day").toDate().toISOString(),
  month: moment.utc(currentDate).subtract(1, "month").startOf("day").toDate().toISOString(),
  year: moment.utc(currentDate).subtract(1, "year").startOf("day").toDate().toISOString()
}

const PatientWeightReadings = () => {
  const [dateRange, setDateRange] = useState(null)
  const [duration, setDuration] = useState("month")
  const [errorMsg, setErrorMsg] = useState(
    "No reading has been taken for Weight Machine in past 30 days"
  )
  const [programId, setProgramId] = useState(null)
  const [graphSeries, setGraphSeries] = useState([])
  const [graphCategories, setGraphCategories] = useState([])

  const dispatch = useDispatch()
  const { patientWmDetail, loading } = useSelector(state => state.healthWm)
  const [wmData, setWmData] = useState([])
  const { userInfo: patientInfo } = useSelector(state => state.userInfo)
  const { loading: monitorLoading, patientMonitoringData } = useSelector(
    state => state.patientMonitoringData
  )

  const { patientVitals } = useSelector(state => state.patientVitals)
  useEffect(() => {
    dispatch(getPatientVitals(patientInfo?.id))
  }, [])
  useEffect(() => {
    if (!patientInfo || !patientMonitoringData || !duration) return

    const program = patientMonitoringData?.rpm
      ?.map(({ programs }) =>
        programs?.find(({ programName }) => programName.toLowerCase() === "weight machine")
      )
      ?.filter(program => program)?.[0]

    if (!program?.programId) return
    else setProgramId(program?.programId)

    dispatch(
      getWmDetails({
        patientId: patientInfo?.id,
        programId: program?.programId,
        endDate: currentDate,
        startDate: durationObj[duration]
      })
    )
  }, [dispatch, patientInfo?.id, duration, patientMonitoringData])

  // useEffect(()=>{
  //   if(patientWmDetail?.length && !patientVitals?.length) dispatch(getPatientVitals(patientInfo?.id))
  // },[patientWmDetail, patientVitals])
  useEffect(() => {
    if (!patientWmDetail?.length) {
      setWmData([])
      switch (duration) {
        case "week":
          setErrorMsg("No reading has been taken for Weight Machine in past 7 days")
          break
        case "year":
          setErrorMsg("No reading has been taken for Weight Machine in past 365 days")
          break
        case "month":
          setErrorMsg("No reading has been taken for Weight Machine in past 30 days")
          break
        default:
          setErrorMsg("No reading has been taken for Weight Machine")
      }
    } else {
      // dispatch(getPatientVitals(patientInfo?.id))
      const formattedGraphDate = []
      const series = [
        {
          name: "Weight",
          data: []
        }
      ]
      let decrement = patientWmDetail
        ?.sort(
          (a, b) =>
            new Date(b?.readingDate || b?.createdAt) - new Date(a?.readingDate || a?.createdAt)
        )
        ?.map((reading, index) => ({ ...reading, index }))
      setWmData(decrement)

      const increment = [...decrement]?.reverse()

      increment !== undefined &&
        increment?.forEach(reading => {
          const graphDate = new Date(reading?.readingDate)
          const formattedDate =
            `${`0${graphDate.getMonth() + 1}`.slice(-2)}` +
            `/${`0${graphDate.getDate()}`.slice(-2)}/${graphDate.getFullYear()}`
          formattedGraphDate.push(formattedDate)
          series[0].data.push(reading.weight.toFixed(2))
        })
      setGraphSeries(series)
      setGraphCategories(formattedGraphDate)
    }
  }, [patientWmDetail, duration])
  const filteredHeight = patientVitals?.find(i => i?.vitalType === "height")?.value ?? 0

  const userHeight = (parseFloat(filteredHeight) ?? 0) * (parseFloat(filteredHeight) ?? 0)
  const underWeight = parseFloat((18.5 * userHeight) / 703).toFixed(1)
  const overWeight = parseFloat((29.9 * userHeight) / 703).toFixed(1)

  const handleBoneMass = () => {
    const weight = wmData?.[0]?.weight
    if (patientInfo?.gender.toLowerCase() === "female") {
      return {
        belowAvg: parseFloat((parseInt(weight) * 2.5) / 100).toFixed(1),
        aboveAvg: parseFloat((parseInt(weight) * 4) / 100).toFixed(1)
      }
    } else {
      return {
        belowAvg: parseFloat((parseInt(weight) * 3) / 100).toFixed(1),
        aboveAvg: parseFloat((parseInt(weight) * 5) / 100).toFixed(1)
      }
    }
  }

  const handleMuscleMass = () => {
    let height = filteredHeight ?? 0
    if (patientInfo?.gender.toLowerCase() === "male") {
      if (height <= 63) {
        return {
          low: "84.9LB",
          high: "102.5LB"
        }
      } else if (63 < height && height <= 70) {
        return {
          low: "97.0LB",
          high: "115.5LB"
        }
      } else if (height > 70) {
        return {
          low: "108.9LB",
          high: "131.0LB"
        }
      }
    } else {
      if (height <= 59.1) {
        return {
          low: "64.2LB",
          high: "76.5LB"
        }
      } else if (59.1 < height && height <= 63.0) {
        return {
          low: "72.5LB",
          high: "82.7LB"
        }
      } else if (height > 63.0) {
        return {
          low: "80.5LB",
          high: "93.7LB"
        }
      }
    }
  }

  const handleRangeChange = (date, dateString) => {
    setDateRange(date)
    if (!date) {
      setDuration("month")
      dispatch(
        getWmDetails({
          patientId: patientInfo?.id,
          programId,
          startDate: durationObj[duration],
          endDate: currentDate
        })
      )
    } else if (date && date?.length === 2) {
      setDuration(null)
      dispatch(
        getWmDetails({
          patientId: patientInfo?.id,
          programId,
          startDate: moment.utc(dateString[0]).startOf("day").toDate().toISOString(),
          endDate: moment.utc(dateString[1]).endOf("day").toDate().toISOString()
        })
      )
    }
  }

  const handleDuration = e => {
    setDuration(e.target.value)
    if (dateRange !== null) setDateRange(null)
  }

  return (
    <Card title="">
      <h4 className="text-xl">Weight Machine Reading</h4>
      <div className="my-4 border-b-2 border-current pb-4">
        <p className="font-semibold mb-3">Duration Pickers</p>
        <div className="flex items-center gap-4">
          <Radio.Group value={duration} onChange={handleDuration}>
            <Radio.Button value="week">Week</Radio.Button>
            <Radio.Button value="month">Month</Radio.Button>
            <Radio.Button value="year">Year</Radio.Button>
          </Radio.Group>
          <DatePicker.RangePicker
            disabledDate={current => current > moment()}
            value={dateRange}
            onChange={handleRangeChange}
          />
        </div>
      </div>
      {loading || monitorLoading ? (
        <Loading />
      ) : wmData && wmData.length ? (
        <Row>
          <Col xs={24} lg={12}>
            <div className="flex flex-wrap gap-3 mb-4">
              <Card
                title="Muscle Rate"
                className="min-w-[250px]"
                extra={
                  <Tooltip title="The ratio of muscle involved in the mechanical system of our limbs and other parts of the body.">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<InfoOutlined className="align-baseline" />}
                    />
                  </Tooltip>
                }>
                <h3 className="mb-4 text-2xl">{wmData?.[0]?.muscleRate}</h3>
                <div className="flex items-center gap-2 mb-1">
                  <div className="info-box bg-danger" />
                  <span>
                    Low: <span className="ml-21font-medium">49%</span>
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-success" />
                  <span>
                    High: <span className="ml-21font-medium">59%</span>
                  </span>
                </div>
              </Card>
              <Card
                title="BMR"
                className="min-w-[250px]"
                extra={
                  <Tooltip title="Basal metabolic rate. In an inactive state, this is the minimum necessary energy needed.">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<InfoOutlined className="align-baseline" />}
                    />
                  </Tooltip>
                }>
                <h3 className="mb-4 text-2xl">{wmData?.[0]?.bmr}</h3>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-danger" />
                  <span>
                    Below Avg: <span className="ml-21font-medium">1600kcal</span>
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-success" />
                  <span>
                    Above Avg: <span className="ml-21font-medium">3000kcal</span>
                  </span>
                </div>
              </Card>
              <Card
                title="Protein"
                className="min-w-[250px]"
                extra={
                  <Tooltip title="Protein plays a vital role in the body, as it builds and maintains muscles, organs and other tissues.">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<InfoOutlined className="align-baseline" />}
                    />
                  </Tooltip>
                }>
                <h3 className="mb-4 text-2xl">{wmData?.[0]?.protein}</h3>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-danger" />
                  <span>
                    Low: <span className="ml-21font-medium">16%</span>
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-success" />
                  <span>
                    High: <span className="ml-21font-medium">18%</span>
                  </span>
                </div>
              </Card>
              <Card
                title="BMI"
                className="min-w-[250px]"
                extra={
                  <Tooltip title="Body Mass Index.">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<InfoOutlined className="align-baseline" />}
                    />
                  </Tooltip>
                }>
                <h3 className="mb-4 text-2xl">{wmData?.[0]?.bmi}</h3>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-danger" />
                  <span>
                    Under Weight: <span className="ml-21font-medium">18</span>
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-success" />
                  <span>
                    Over Weight: <span className="ml-21font-medium">30</span>
                  </span>
                </div>
              </Card>
              <Card
                title="Muscle mass"
                className="min-w-[250px]"
                extra={
                  <Tooltip title="Muscle, in addition to body fat, is a major component of body weight.">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<InfoOutlined className="align-baseline" />}
                    />
                  </Tooltip>
                }>
                <h3 className="mb-4 text-2xl">{wmData?.[0]?.muscleMass} LB</h3>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-danger" />
                  <span>
                    Low: <span className="ml-21font-medium">{handleMuscleMass().low}</span>
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-success" />
                  <span>
                    High: <span className="ml-21font-medium">{handleMuscleMass().high}</span>
                  </span>
                </div>
              </Card>
              <Card
                title="Bone mass"
                className="min-w-[250px]"
                extra={
                  <Tooltip title="Bone tissue consists of bone minerals in (calcium, phosphorous, etc) and bone matrix (collagen fibre, ground substance, in the organic salt, etc) per unit volume.">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<InfoOutlined className="align-baseline" />}
                    />
                  </Tooltip>
                }>
                <h3 className="mb-4 text-2xl">{wmData?.[0]?.boneMass} LB</h3>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-danger" />
                  <span>
                    Below Avg:{" "}
                    <span className="ml-21font-medium">{handleBoneMass().belowAvg}LB</span>
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-success" />
                  <span>
                    Above Avg:{" "}
                    <span className="ml-21font-medium">{handleBoneMass().aboveAvg}LB</span>
                  </span>
                </div>
              </Card>
              <Card
                title="Water Rate"
                className="min-w-[250px]"
                extra={
                  <Tooltip title="Water weight, which includes blood lymphs, extracellular fluid ,and etc.">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<InfoOutlined className="align-baseline" />}
                    />
                  </Tooltip>
                }>
                <h3 className="mb-4 text-2xl">{wmData?.[0]?.bodyWaterRate}</h3>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-danger" />
                  <span>
                    Low: <span className="ml-21font-medium">50%</span>
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-success" />
                  <span>
                    High: <span className="ml-21font-medium">65%</span>
                  </span>
                </div>
              </Card>
              <Card
                title="Subcutaneous fat"
                className="min-w-[250px]"
                extra={
                  <Tooltip title="The ratio of Subcutaneous Fat stored in your skin to body weight.">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<InfoOutlined className="align-baseline" />}
                    />
                  </Tooltip>
                }>
                <h3 className="mb-4 text-2xl">{wmData?.[0]?.subcutaneousFat}</h3>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-danger" />
                  <span>
                    Low: <span className="ml-21font-medium">8.6%</span>
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-success" />
                  <span>
                    High: <span className="ml-21font-medium">16.7%</span>
                  </span>
                </div>
              </Card>
              <Card
                title="Viseral fat"
                className="min-w-[250px]"
                extra={
                  <Tooltip title="The type of fat which is generally found around the human organs and which mainly resides in the abdominal cavity.">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<InfoOutlined className="align-baseline" />}
                    />
                  </Tooltip>
                }>
                <h3 className="mb-4 text-2xl">{wmData?.[0]?.visceralFat}</h3>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-danger" />
                  <span>
                    Good: <span className="ml-21font-medium">6.00</span>
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-success" />
                  <span>
                    High: <span className="ml-21font-medium">15.00</span>
                  </span>
                </div>
              </Card>
              <Card
                title="Body Fat"
                className="min-w-[250px]"
                extra={
                  <Tooltip title="Body composition fat tissues ratio.">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<InfoOutlined className="align-baseline" />}
                    />
                  </Tooltip>
                }>
                <h3 className="mb-4 text-2xl">{wmData?.[0]?.bodyFatRate}</h3>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-danger" />
                  <span>
                    Essential Fat: <span className="ml-21font-medium">6%</span>
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-success" />
                  <span>
                    Obesity: <span className="ml-21font-medium">25%</span>
                  </span>
                </div>
              </Card>
              <Card
                title="Weight"
                className="min-w-[250px]"
                extra={
                  <Tooltip title="One of the important indicators of Health.">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<InfoOutlined className="align-baseline" />}
                    />
                  </Tooltip>
                }>
                <h3 className="mb-4 text-2xl">{wmData?.[0]?.weight} LB</h3>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-danger" />
                  <span>
                    Under Weight: <span className="ml-21font-medium">{underWeight}LB</span>
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="info-box bg-success" />
                  <span>
                    Over Weight: <span className="ml-21font-medium">{overWeight}LB</span>
                  </span>
                </div>
              </Card>
              <Card
                title="Metabolic Age"
                className="min-w-[250px]"
                extra={
                  <Tooltip title="Body age is an indicator to asses physical condition.">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<InfoOutlined className="align-baseline" />}
                    />
                  </Tooltip>
                }>
                <h3 className="mb-4 text-2xl">{wmData?.[0]?.metabolicAge}</h3>
              </Card>
              <Card
                title="Lean Body Weight"
                className="min-w-[250px]"
                extra={
                  <Tooltip title="Muscle, in addition to the body fat, is a major component of the body weight.">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<InfoOutlined className="align-baseline" />}
                    />
                  </Tooltip>
                }>
                <h3 className="mb-4 text-2xl">{wmData?.[0]?.leanBodyWeight} LB</h3>
              </Card>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <Card className="mb-4" title="Weight Readings Chart">
              {graphSeries.length && graphCategories.length ? (
                <ReactApexChart
                  type="line"
                  height={350}
                  {...lineChartOptions(graphSeries, graphCategories)}
                />
              ) : (
                "Loading..."
              )}
            </Card>
            <Card title="Weight Readings Table">
              <Table columns={columns} dataSource={wmData} rowKey="index" />
            </Card>
          </Col>
        </Row>
      ) : (
        <div>
          <h2 className="text-2xl mb-4">Weight Machine Reading</h2>
          <Alert type="error" message={errorMsg} />
        </div>
      )}
    </Card>
  )
}

export default PatientWeightReadings
