import React, { useState, useEffect } from "react"
import { Modal, Button, Col, Divider, Spin } from "antd"
import { encryptBoolean } from "../../utils/encryptionUtils"
import { useNavigate } from "react-router-dom"
import { getEnrolSpGeneratedCodeAction } from "../../redux/actions/selfOnboardPatientAction"
import { useDispatch, useSelector } from "react-redux"

const EnrollmentSplCodeModal = ({ setIsCodeModalVisible, isCodeModalVisible, patientId }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    loading: enrolSpGeneratedCodeLoading,
    data: enrolSpGeneratedCode,
    error: enrolSpGeneratedCodeError
  } = useSelector(state => state.getEnrolSpGeneratedCode)

  useEffect(() => {
    if (isCodeModalVisible && patientId) {
      dispatch(getEnrolSpGeneratedCodeAction(patientId))
    }
  }, [isCodeModalVisible])

  const handleContinue = () => {
    setIsCodeModalVisible(false)
    if (window.isMobileVersion && window.value !== undefined && window.token !== undefined) {
      localStorage.removeItem("token")
      window.webkit?.messageHandlers?.home?.postMessage({ value: "harish" })
    } else {
      localStorage.setItem("welcomeTutorial", JSON.stringify(encryptBoolean(false)))
      navigate("/")
    }
  }

  return (
    <Modal
      open={isCodeModalVisible}
      centered
      footer={null}
      closable={false}
      className="text-center px-8">
      {!enrolSpGeneratedCodeLoading ? (
        <div className="flex flex-col justify-center items-center ">
          <p className="text-xl mb-2 mt-4 font-bold">
            Hey, you're enrolled!{" "}
            <span role="img" aria-label="Party Emoji">
              🎉
            </span>
          </p>
          <p className="text-base">
            Please provide this 6-digit code to the caller if you're signing up over the phone, else
            kindly ignore the code.
          </p>
          <p className="font-extrabold text-3xl mt-2" style={{ letterSpacing: "4px" }}>
            {enrolSpGeneratedCode?.code}
          </p>
          {enrolSpGeneratedCodeError && typeof enrolSpGeneratedCodeError === "string" ? (
            <p className="text-danger block text-center font-medium mt-2">
              {enrolSpGeneratedCodeError}
            </p>
          ) : null}
          <Divider />
          <Col>
            <Button
              type="primary"
              onClick={handleContinue}
              className=" h-12 w-28 rounded-xl flex justify-center items-center">
              Continue
            </Button>
          </Col>
        </div>
      ) : (
        <Spin />
      )}
    </Modal>
  )
}

export default EnrollmentSplCodeModal
