import React, { useEffect, useState, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import { useSelector, useDispatch } from "react-redux"

import { Button } from "antd"

import moment from "moment"
import ReactToPrint from "react-to-print"
import { getccmCareplanDataActions } from "../../../../../redux/actions/ccmActions"

import RPMViewNavbar from "../../../component/RPMViewNavbar/RPMViewNavbar"
import RPMOnlyViewForm from "../../../RPMAdminQuestions/RPMOnlyViewForm"

const RPMViewPrint = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [carePlanData, setCarePlanData] = useState() // for chronic conditions

  const dispatch = useDispatch()

  const userInfo = useSelector(state => state.userInfo)
  // console.log(userInfo)

  const navigate = useNavigate()
  const location = useLocation()

  const componentRef = useRef()

  const list = location.state && location.state.list
  const patientInfoDetail = location.state && location.state.patientInfoDetail
  const id = location.state && location.state.id
  // console.log(patientInfoDetail)

  const getCcmPlan = async () => {
    const ccData = await dispatch(getccmCareplanDataActions(id))
    setCarePlanData(ccData)
  }

  useEffect(() => {
    getCcmPlan()
  }, [id]) //eslint-disable-line
  // console.log('careplan ', carePlanData)

  useEffect(() => {
    if (!list || list === null) {
      navigate("/")
    }
  }, [userInfo, navigate, location?.state]) //eslint-disable-line

  // console.log('list',list)

  const generatePageNum = () => {
    // let totalPages = Math.ceil(document.body.scrollHeight / 1123);  //842px A4 pageheight for 72dpi, 1123px A4 pageheight for 96dpi,
    const totalPages = Math.ceil(Object.keys(list)?.length / 2) + 1
    for (let i = 1; i <= totalPages; i++) {
      const pageNumberDiv = document.createElement("div")
      const pageNumber = document.createTextNode(`Page ${i} of ${totalPages}`)
      pageNumberDiv.classList.add("page-num-div")
      pageNumberDiv.style.position = "absolute"
      pageNumberDiv.style.top = `calc((${i} * (297mm - 0.5px)) - 40px)` // 297mm A4 pageheight; 0,5px unknown needed necessary correction value; additional wanted 40px margin from bottom(own element height included)
      pageNumberDiv.style.height = "16px"
      pageNumberDiv.appendChild(pageNumber)
      const parentDiv = document.getElementById("view-container")
      const originalDiv = document.getElementById("content")
      parentDiv.insertBefore(pageNumberDiv, originalDiv.nextSibling)
      pageNumberDiv.style.left = `calc(100% - (${pageNumberDiv.offsetWidth}px + 100px))`
      pageNumberDiv.style.backgroundColor = "#DBDBDB"
      pageNumberDiv.style.paddingBottom = "24px"
      pageNumberDiv.style.borderRadius = "6px"
    }
  }
  useEffect(() => {
    generatePageNum()
  }, [list]) //eslint-disable-line

  // console.log('list',list)

  return (
    <div className="wrapper">
      {/* <SidebarComponent sidebarOpen={sidebarOpen} /> */}
      <div id="content" className="no-hadow">
        <RPMViewNavbar
          setSidebarOpen={setSidebarOpen}
          sidebarOpen={sidebarOpen}
          userInfo={userInfo}
        />
        <div
          className="view-container"
          id="view-container"
          ref={componentRef}
          style={{
            padding: "20px",
            overflowY: "auto"
          }}>
          <div className="flex justify-between w-full py-2">
            <h4 className="Ish px-3">Answers</h4>
            <div className="flex noprint">
              <div>
                <Button onClick={() => navigate(`/rpm-view-treatement/${id}`)}>Back</Button>
              </div>
              <div>
                {/* eslint-disable */}
                <ReactToPrint
                  trigger={() => <Button className="pdf-button mx-2">Print as PDF</Button>}
                  content={() => componentRef.current}
                />
              </div>
              {/* <div>
                            <button className="btn btn-dark btn-sm mx-2" onClick={exportPdf}>Test Print</button>
                        </div> */}
            </div>
          </div>
          {/* <div id="pageFooter">Page </div> */}
          <div className="CCMAddChronic no-hadow">
            <div className="p-4">
              <p
                style={{
                  fontSize: 24,
                  fontWeight: "600",
                  marginBottom: "10px"
                }}>
                Patient Details
              </p>
              <div className="flex justify-between bg-light">
                <div>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: "10px",
                      textTransform: "capitalize"
                    }}>
                    Date&nbsp;of&nbsp;Service:&nbsp;
                    {patientInfoDetail?.careplanStats !== null &&
                      moment.unix(patientInfoDetail?.careplanStats?.createdAt).format("MM/DD/YYYY")}
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: "10px"
                    }}>
                    Patient&nbsp;Name&nbsp;:&nbsp;{patientInfoDetail?.firstName}&nbsp;
                    {patientInfoDetail?.middleName}&nbsp;
                    {patientInfoDetail?.lastName}
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: "10px",
                      textTransform: "capitalize"
                    }}>
                    Patient&nbsp;DOB&nbsp;:&nbsp;
                    {moment.unix(patientInfoDetail?.dob).format("MM/DD/YYYY")}
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,

                      marginBottom: "10px"
                    }}>
                    <div className="flex">
                      Monitor&nbsp;Type&nbsp;:&nbsp;
                      {patientInfoDetail?.moniteringDetails?.map((item, index) =>
                        item ===
                        patientInfoDetail?.moniteringDetails[
                          patientInfoDetail?.moniteringDetails?.length - 1
                        ] ? (
                          <p key={index}>{item?.moniterData?.name.toUpperCase()}</p>
                        ) : (
                          <p key={index}>{item?.moniterData?.name.toUpperCase()},&nbsp;</p>
                        )
                      )}
                    </div>
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: "10px",
                      textTransform: "capitalize"
                    }}>
                    Date&nbsp;of&nbsp;Plan&nbsp;Modified:&nbsp;
                    {patientInfoDetail?.careplanStats !== null &&
                      moment.unix(patientInfoDetail?.careplanStats?.updatedAt).format("MM/DD/YYYY")}
                  </p>
                </div>
                <div className="mx-5">
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: "10px",
                      textTransform: "capitalize"
                    }}>
                    <div className="flex flex-wrap">
                      <p>CareTaker&nbsp;:&nbsp;</p>
                      {patientInfoDetail?.caretakerData?.map((item, index) =>
                        item ===
                        patientInfoDetail?.caretakerData[
                          patientInfoDetail?.caretakerData?.length - 1
                        ] ? (
                          <p key={index} className="capitalize">
                            {`${item?.caretakerData?.firstName} ${item?.caretakerData?.lastName}`}
                          </p>
                        ) : (
                          <p key={index} className="capitalize">
                            {item?.caretakerData?.firstName.replaceAll(" ", "")}&nbsp;
                            {item?.caretakerData?.lastName.replaceAll(" ", "")},&nbsp;
                          </p>
                        )
                      )}
                    </div>
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: "10px"
                    }}>
                    {patientInfoDetail?.moniteringDetails?.map((item, i) =>
                      item?.moniterData?.name === "rpm" ? (
                        <p className="capitalize" key={i}>
                          Provider&nbsp;:&nbsp;{item?.providerDetails?.providerData?.firstName}
                          &nbsp;
                          {item?.providerDetails?.providerData?.lastName}
                        </p>
                      ) : null
                    )}
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: "10px"
                    }}>
                    <div className="flex flex-wrap">
                      Diagnostic(ICD)&nbsp;Codes&nbsp;:&nbsp;
                      {patientInfoDetail?.diagnosticData?.length
                        ? patientInfoDetail?.diagnosticData.map((item, index) =>
                            item ===
                            patientInfoDetail?.diagnosticData[
                              patientInfoDetail?.diagnosticData?.length - 1
                            ] ? (
                              <p key={index}>{item?.diagnosticId}</p>
                            ) : (
                              <p key={index}>{item?.diagnosticId},&nbsp;</p>
                            )
                          )
                        : ""}
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* -------------------description div starts---------------------------- */}
          {list?.[3]?.questionaryOptions[2]?.checked === true ? (
            <div className="shadow w-full p-4 card no-hadow desc-div mb-5">
              <h4>Description</h4>
              <p>
                <span className="font-semibold capitalize">
                  {patientInfoDetail?.firstName}&nbsp;{patientInfoDetail?.middleName}&nbsp;
                  {patientInfoDetail?.lastName}
                </span>{" "}
                currently has the following conditions:
                <br />
                {carePlanData?.careplanDetails?.map((item, i) => (
                  <div className="capitalize font-semibold" key={i}>
                    {i + 1}. {item}
                  </div>
                ))}
                Dr.{" "}
                <span className="font-semibold capitalize">
                  {
                    patientInfoDetail?.moniteringDetails[0]?.providerDetails?.providerData
                      ?.firstName
                  }
                  &nbsp;
                  {patientInfoDetail?.moniteringDetails[0]?.providerDetails?.providerData?.lastName}
                </span>{" "}
                identified this patient as a good candidate for enrollment into the Remote Patient
                Monitoring (RPM) program.
                <br />
                The healthcare provider has recommended the following devices:
                <br />
                <span className="font-semibold">
                  {patientInfoDetail?.moniteringDetails?.map((item, i) =>
                    item?.moniterData?.name === "rpm"
                      ? item?.deviceDetails?.map((dev, idx) => (
                          <div className="capitalize" key={i}>
                            {idx + 1}. {dev?.deviceData?.name}
                          </div>
                        ))
                      : null
                  )}
                </span>
                The FitPeo devices will be used to collect health data from the patient and to
                monitor their treatment plan remotely.
                <br />
                The patient has been provided informed verbal consent for the RPM program at the
                time of annual enrollment.
                <br />
                <span className="font-semibold capitalize">
                  {userInfo?.userInfo?.firstName}&nbsp;{userInfo?.userInfo?.lastName}
                </span>{" "}
                spoke with the patient today via (video call, phone call, virtual call) to initiate
                and review the treatment plan.
                <br />
                Equipment was mailed to the patient successfully and received on{" "}
                {list?.[4]?.text !== "" ? (
                  <span className="font-semibold">{list[4].text}</span>
                ) : (
                  "--.--.----"
                )}{" "}
                by the patient.
                <br />
                The patient has been educated on how to properly utilize the equipment and the
                importance of monitoring their vital signs daily via the FitPeo equipment provided.
                <br />
                This education was provided on{" "}
                {list?.[4]?.text !== "" ? (
                  <span className="font-semibold">{list[4].text}</span>
                ) : (
                  "--.--.----"
                )}
                .<br />
                The patient has agreed to the treatment plan and has been provided with our contact
                information for any questions/concerns.
              </p>
            </div>
          ) : list?.[3]?.questionaryOptions[1]?.checked === true ? (
            <div className="shadow w-full p-4 card no-hadow desc-div mb-5">
              <h4>Description</h4>
              <p>
                <span className="font-semibold capitalize">
                  {patientInfoDetail?.firstName}&nbsp;{patientInfoDetail?.middleName}&nbsp;
                  {patientInfoDetail?.lastName}
                </span>{" "}
                currently has the following conditions:
                <br />
                {carePlanData?.careplanDetails?.map((item, i) => (
                  <div className="capitalize font-semibold" key={i}>
                    {i + 1}. {item}
                  </div>
                ))}
                Dr.{" "}
                <span className="font-semibold capitalize">
                  {
                    patientInfoDetail?.moniteringDetails[0]?.providerDetails?.providerData
                      ?.firstName
                  }
                  &nbsp;
                  {patientInfoDetail?.moniteringDetails[0]?.providerDetails?.providerData?.lastName}
                </span>{" "}
                identified this patient as a good candidate for enrollment into the Remote Patient
                Monitoring (RPM) program.
                <br />
                The healthcare provider has recommended the following devices:
                <br />
                <span className="font-semibold">
                  {patientInfoDetail?.moniteringDetails?.map((item, i) =>
                    item?.moniterData?.name === "rpm"
                      ? item?.deviceDetails?.map((dev, idx) => (
                          <div key={idx} className="capitalize">
                            {idx + 1}. {dev?.deviceData?.name}
                          </div>
                        ))
                      : null
                  )}
                </span>
                The FitPeo devices will be used to collect health data from the patient and to
                monitor their treatment plan remotely.
                <br />
                The patient has been provided informed verbal consent for the RPM program at the
                time of annual enrollment.
                <br />
                <span className="font-semibold capitalize">
                  {userInfo?.userInfo?.firstName}&nbsp;{userInfo?.userInfo?.lastName}
                </span>{" "}
                spoke with the patient today to initiate and review the treatment plan.
                <br />
                Equipment was delivered to the patient successfully and received on{" "}
                {list?.[4]?.text !== "" ? (
                  <span className="font-semibold">{list[4].text}</span>
                ) : (
                  "--.--.----"
                )}{" "}
                by the patient.
                <br />
                The patient has been educated on how to properly utilize the equipment and the
                importance of monitoring their vital signs daily via the FitPeo equipment provided.
                <br />
                This education was provided on{" "}
                {list?.[4]?.text !== "" ? (
                  <span className="font-semibold">{list[4].text}</span>
                ) : (
                  "--.--.----"
                )}
                .<br />
                The patient has agreed to the treatment plan and has been provided with our contact
                information for any questions/concerns.
              </p>
            </div>
          ) : list?.[3]?.questionaryOptions[0]?.checked === true ? (
            <div className="shadow w-full p-4 card no-hadow desc-div mb-5">
              <h4>Description</h4>
              <p>
                <span className="font-semibold capitalize">
                  {patientInfoDetail?.firstName}&nbsp;{patientInfoDetail?.middleName}&nbsp;
                  {patientInfoDetail?.lastName}
                </span>{" "}
                currently has the following conditions:
                <br />
                {carePlanData?.careplanDetails?.map((item, i) => (
                  <div className="capitalize font-semibold" key={i}>
                    {i + 1}. {item}
                  </div>
                ))}
                Dr.{" "}
                <span className="font-semibold capitalize">
                  {
                    patientInfoDetail?.moniteringDetails[0]?.providerDetails?.providerData
                      ?.firstName
                  }
                  &nbsp;
                  {patientInfoDetail?.moniteringDetails[0]?.providerDetails?.providerData?.lastName}
                </span>{" "}
                identified this patient as a good candidate for enrollment into the Remote Patient
                Monitoring (RPM) program.
                <br />
                The healthcare provider has recommended the following devices:
                <br />
                <span className="font-semibold">
                  {patientInfoDetail?.moniteringDetails?.map((item, i) =>
                    item?.moniterData?.name === "rpm"
                      ? item?.deviceDetails?.map((dev, idx) => (
                          <div key={i} className="capitalize">
                            {idx + 1}. {dev?.deviceData?.name}
                          </div>
                        ))
                      : null
                  )}
                </span>
                The FitPeo devices will be used to collect health data from the patient and to
                monitor their treatment plan remotely.
                <br />
                The patient has been provided informed verbal consent for the RPM program at the
                time of annual enrollment.
                <br />
                <span className="font-semibold capitalize">
                  {userInfo?.userInfo?.firstName}&nbsp;{userInfo?.userInfo?.lastName}
                </span>{" "}
                spoke with the patient today to initiate and review the treatment plan.
                <br />
                Equipment was given to the patient on{" "}
                {list?.[4]?.text !== "" ? (
                  <span className="font-semibold">{list[4].text}</span>
                ) : (
                  "--.--.----"
                )}{" "}
                in the office by{" "}
                <span className="font-semibold capitalize">
                  {userInfo?.userInfo?.firstName}&nbsp;{userInfo?.userInfo?.lastName}
                </span>
                .<br />
                The patient has been educated on how to properly utilize the equipment and the
                importance of monitoring their vital signs daily via the FitPeo equipment provided.
                <br />
                This education was provided on{" "}
                {list?.[4]?.text !== "" ? (
                  <span className="font-semibold">{list[4].text}</span>
                ) : (
                  "--.--.----"
                )}
                .<br />
                The patient has agreed to the treatment plan and has been provided with our contact
                information for any questions/concerns.
              </p>
            </div>
          ) : null}
          {/* --------------------description div ends-------------------- */}
          <div className="list-print-div" style={{ counterIncrement: "page" }}>
            {Object.values(list)?.map((ques, index) => (
              <div
                key={index}
                className="card w-full mb-3 shadow p-4 no-hadow mt-4"
                style={{ pageBreakAfter: index % 2 === 0 ? "always" : "avoid", color: "black" }}>
                <h4 className="form_heading font-semibold">{ques.name}</h4>
                <h5 className="subHeading">{ques.title}</h5>
                {/* <CCMFormInput
                          ques={ques}
                          formInputs={formInputs}
                          otherInputs={otherInputs}
                          setOtherInputs={setOtherInputs}
                        /> */}

                <div className="not_applicable">
                  {ques.notApplicable !== undefined && ques.notApplicable ? (
                    <label className="flex pt-4 text-dark">
                      <input
                        type="checkbox"
                        checked={!!ques.notApplicable}
                        disabled
                        style={{
                          marginRight: "18px"
                        }}
                      />
                      Not Applicable
                    </label>
                  ) : ques.anyValue !== undefined && !ques.anyValue ? (
                    <div className="not_applicable">
                      <label className="flex pt-4 text-dark">
                        <input
                          type="checkbox"
                          checked
                          disabled
                          style={{
                            marginRight: "18px"
                          }}
                        />
                        Not Applicable
                      </label>
                    </div>
                  ) : (
                    <RPMOnlyViewForm ques={ques} />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RPMViewPrint
