import { useState } from "react";
import { Card, Row, Col, Avatar, Button, Divider } from "antd";
import eyeIcon from "../../assets/eyeIcon.svg";
import { IoCall } from "react-icons/io5";
import { SiGooglemeet } from "react-icons/si";
import AppointmentReminder from "./AppointmentReminder";

const BookAppointment = () => {
    const [showPhoneDetail, setShowPhoneDetail] = useState(false);
    const [showMeetDetail, setShowMeetDetail] = useState(false);
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [showRemiderModal, setShowReminderModal] = useState(false);
    const [modeOfAppointment, setModeOfAppointment] = useState('');

    const handlePhoneSelect = () => { // handler func for select the phone
        setShowMeetDetail(false)
        setShowPhoneDetail(true)
        setModeOfAppointment('Phone')
    }
    const handleMeetSelect = () => { // handler func for select the virtual meet
        setShowPhoneDetail(false)
        setShowMeetDetail(true)
        setModeOfAppointment('Virtual Meet')
    }

    const handleSelectDay = (e) => { // handler for select the day
        setSelectedDay(e.target.value);
    }
    const handleSelectTime = (e) => { // handler for select the time
        setSelectedTime(e.target.value);
    }

    const dayButtonSelected = (day) => {
        return day === selectedDay;
    }
    const timeButtonSelected = (time) => {
        return time === selectedTime;
    }
    const handleSchedule = () => {
        if (selectedDay && selectedTime) {
            setShowReminderModal(true);
        }
    }

    return (
        <>
            {showRemiderModal &&
                <AppointmentReminder
                    selectedDay={selectedDay}
                    selectedTime={selectedTime}
                    setShowReminderModal={setShowReminderModal}
                    showRemiderModal={showRemiderModal}
                    modeOfAppointment={modeOfAppointment}
                />}
            <Row gutter={16}>
                <Col sm={24} xl={12} className="mb-4 w-full">
                    <h2 className="text-[15.385px] mb-7 text-base text-center font-semibold">Book Appointment </h2>
                    <Card className="rounded-[10px]">
                        <div className="flex flex-col justify-between items-center gap-[10px] sm:items-start">
                            {(showPhoneDetail || showMeetDetail) ?
                                <div className="w-full flex flex-col gap-[10px] justify-center items-start">
                                    <h1 className="mt-[7px] text-lg text-left font-semibold">Hello Shaqueena</h1>
                                    <p className="text-sm text-[#979797] font-medium text-left">
                                        How do you want us to contact you
                                    </p>
                                </div> :
                                <>
                                    <h1 className="mt-[7px] text-lg font-semibold">Hello Shaqueena</h1>
                                    <p className="text-sm font-medium">
                                        How do you want us to contact you and what time should be weekly appointment?
                                    </p>
                                </>
                            }
                            <div className="mt-[2px] w-full flex justify-center items-center gap-4">
                                <button
                                    className={`border-solid border-[#007AFF] border-[2px] rounded-xl w-32 h-32 flex flex-col items-center justify-center gap-[10px] ${showPhoneDetail && 'bg-[#007AFF]'}`}
                                    onClick={() => handlePhoneSelect()}
                                >
                                    {!showPhoneDetail ? <IoCall style={{ color: "#007AFF", fontSize: '4.6em' }} /> : <IoCall style={{ color: "#fff", fontSize: '4.6em' }} />}
                                    <p className={`text-lg font-normal ${showPhoneDetail ? 'text-white' : 'text-[#007AFF]'}`}>Phone</p>
                                </button>
                                <button
                                    className={`border-solid border-[#007AFF] border-[2px] rounded-xl w-32 h-32 flex flex-col items-center justify-center ${showMeetDetail && 'bg-[#007AFF] text-white'}`}
                                    onClick={() => handleMeetSelect()}
                                >
                                    {!showMeetDetail ? <SiGooglemeet style={{ color: "#007AFF", fontSize: '4.6em' }} /> : <SiGooglemeet style={{ color: "#fff", fontSize: '4.6em' }} />}
                                    <p className={`mt-2 text-lg font-normal text-[#007AFF] ${showMeetDetail ? 'text-white' : 'text-[#007AFF]'}`}>Virtual meet</p>
                                </button>
                            </div>
                            {(showPhoneDetail || showMeetDetail) && <div>
                                <Divider className="mt-[19px] mb-[14px]" />
                                <div className="flex justify-start items-start">
                                    <p className="text-sm font-normal leading-6 text-[#000]">
                                        <span className="inline-flex"><img src={eyeIcon} className="w-4 mr-2" /></span>
                                        Weekly doctor appointments are crucial for prevention, chronic condition management, medication oversight, and early detection, fostering a strong patient-doctor relationship.
                                    </p>
                                </div>
                                <div className="mt-[18px]">
                                    <h1 className="text-lg font-semibold ml-2 " style={{ marginBottom: "16px" }}>Choose Your slot</h1>
                                    <h1 className="text-base font-medium text-[#979797] " style={{ marginBottom: "16px" }} >Select Day</h1>
                                    <div className="flex flex-wrap gap-x-3 gap-y-4">
                                        {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day, index) => (
                                            <button
                                                key={index}
                                                className={`border-solid border-[#007AFF] border-[2px] rounded-2xl w-14 h-7 flex flex-col items-center justify-center text-[#007AFF] ${dayButtonSelected(day) ? 'text-[#fff] bg-[#007AFF]' : ''}`}
                                                value={day}
                                                onClick={(e) => handleSelectDay(e)}
                                            >
                                                {day}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                <div className="mt-[29px]">
                                    <h1 className="text-base font-medium text-[#979797] mb-[11px]">Select Time</h1>
                                    <h1 className="text-base font-medium mb-[9px]" >Morning</h1>
                                    <div className="flex flex-wrap gap-x-3 gap-y-4">
                                        {["9:00 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM"].map((time, index) => (
                                            <button
                                                key={index}
                                                className={`border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF] ${timeButtonSelected(time) ? 'text-[#fff] bg-[#007AFF]' : ''} `}
                                                value={time}
                                                onClick={(e) => handleSelectTime(e)}
                                            >
                                                {time}
                                            </button>
                                        ))}
                                    </div>
                                    <h1 className="text-base font-medium mb-[9px] mt-[11px]" >Afternoon</h1>
                                    <div className="flex flex-wrap gap-x-3 gap-y-4">
                                        {["12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM"].map((time, index) => (
                                            <button
                                                key={index}
                                                className={`border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF] ${timeButtonSelected(time) ? 'text-[#fff] bg-[#007AFF]' : ''} `}
                                                value={time}
                                                onClick={(e) => handleSelectTime(e)}
                                            >
                                                {time}
                                            </button>
                                        ))}
                                    </div>
                                    <h1 className="text-base font-medium mb-[9px] mt-[11px]" >Evening</h1>
                                    <div className="flex flex-wrap gap-x-3 gap-y-4">
                                        {["5:00 PM", "5:30 PM", "6:00 PM", "6:30 PM", "7:00 PM"].map((time, index) => (
                                            <button
                                                key={index}
                                                className={`border-solid border-[#007AFF] border-[2px] rounded-2xl w-20 h-7 flex flex-col items-center justify-center text-[#007AFF] ${timeButtonSelected(time) ? 'text-[#fff] bg-[#007AFF]' : ''} `}
                                                value={time}
                                                onClick={(e) => handleSelectTime(e)}
                                            >
                                                {time}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>}
                            <div className="w-full flex justify-center">
                                {(showPhoneDetail || showMeetDetail) ?
                                    <Button
                                        className="mt-[17px] w-full h-[49px] text-base text-white font-semibold rounded-xl bg-[#007AFF] sm:w-72"
                                        onClick={() => handleSchedule()}
                                    >
                                        {(selectedDay && selectedTime) ? "Schedule" : "Continue"}
                                    </Button> :
                                    <Button
                                        className="mt-[17px] w-full h-[49px] text-base text-white font-semibold rounded-xl bg-[#007AFF] sm:w-72">
                                        Schedule Later
                                    </Button>
                                }
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default BookAppointment;
