import {
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  SolutionOutlined,
  PlusSquareOutlined,
  FileProtectOutlined,
  // FileTextOutlined,
  // ContainerOutlined
} from "@ant-design/icons"

const Navigation = [
  {
    key: "/",
    label: "Dashboard",
    icon: <HomeOutlined />
  },
  {
    key: "/profile",
    label: "Profile",
    icon: <UserOutlined />
  },
  {
    key: "/ccm-view-care",
    label: "CCM Care",
    icon: <PlusSquareOutlined />
  },
  {
    key: "/rpm-view-treatement",
    label: "RPM Treatment",
    icon: <SolutionOutlined />
  },
  {
    key: "/pcm-view-care",
    label: "PCM Care",
    icon: <FileProtectOutlined />
  },
  // {
  //   key: "/rtm",
  //   label: "RTM",
  //   icon: <ContainerOutlined />
  // },
  // {
  //   key: "/consultations",
  //   label: "Consultations",
  //   icon: <FileTextOutlined />,
  //   children: [
  //     {
  //       key: "/consultations/upcoming",
  //       label: "Upcoming"
  //     },
  //     {
  //       key: "/consultations/past",
  //       label: "Past"
  //     },
  //     {
  //       key: "/consultations/new",
  //       label: "Schedule New"
  //     }
  //   ]
  // },
  {
    key: "/provider",
    label: "My Providers",
    icon: <UserOutlined />
  },
  {
    key: "/settings",
    label: "Settings",
    icon: <SettingOutlined />
  }
]

export default Navigation
