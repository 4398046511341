import { useForm, Controller } from "react-hook-form"
import { useState } from "react";
import clock from "../../assets/clock.svg";
import { Modal, Row, Col, Divider, Button, Checkbox } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const AppointmentReminder = ({ selectedDay, selectedTime, setShowReminderModal, showRemiderModal, modeOfAppointment }) => {
    const [reminder, setReminder] = useState('')
    const [showAppointmentDetails, setShowAppointmentDetails] = useState(false);

    const handleClose = () => {
        setReminder('');
        setShowReminderModal(false);
    }
    const handleCheckbox = (e) => {
        setReminder(e.target.value)
    }
    const handleReminder = () => {
        if (reminder) {
            setShowAppointmentDetails(true);
        }
    }
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm()

    const onSubmit = () => {
        return
    }

    return (
        <Modal
            width={"85%"}
            footer={null}
            closeIcon={<CloseOutlined style={{ color: '#000' }} />}
            onCancel={handleClose}
            open={showRemiderModal}
            centered={true}
            className="max-w-lg"
            title={
                <div className="flex gap-[10px] mb-[14px]">
                    <img src={clock} />
                    <h4 className="text-xl font-medium">Appointment Reminder</h4>
                </div>}
        >
            {showAppointmentDetails ?
                <>
                    <h1 className="text-lg text-left font-semibold">Hi Shaqueena,</h1>
                    <p className="text-base font-normal leading-[22px] text-[#666B78] mt-1 mb-[10px]">Just a quick reminder that your appointment is coming up in 1 hour. Please make sure to join on time.</p>

                    <h1 className="mt-[7px] text-lg text-left font-semibold">Appointment Details:</h1>
                    <ul className="list-disc list-inside pl-2 text-[#666B78] text-base font-normal">
                        <li>Day: {selectedDay}</li>
                        <li>Time: {selectedTime}</li>
                        <li>Mode of Appointment: {modeOfAppointment}</li>
                    </ul>
                    <h2 className="text-base font-medium mt-[14px]">Looking forward to seeing you!</h2>
                    <Divider className="mt-[12px] mb-[16px]" />
                    <Col xs={24}>
                        <div className="flex justify-center items-center">
                            <Button
                                className="w-full h-[49px] text-base text-white font-semibold rounded-xl bg-[#007AFF] sm:w-[75%]"
                                onClick={() => handleReminder()}
                            >
                                Confirm
                            </Button>
                        </div>
                    </Col>
                </>
                :
                <>
                    <p className="text-base font-medium">Remind me before</p>
                    <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
                        <Row gutter={20}>
                            <Col xs={24} md={12} className="flex flex-col gap-2">
                                {["1 Hour", "30 mins", "15 mins"].map((t, index) => (
                                    <Checkbox
                                        key={index}
                                        value={t}
                                        checked={reminder === t}
                                        onChange={(e) => handleCheckbox(e)}>
                                        {t}
                                    </Checkbox>
                                ))}
                            </Col>
                            <Divider className="my-[20px]" />
                            <Col xs={24}>
                                <div className="flex justify-center items-center">
                                    <Button
                                        className="w-full h-[49px] text-base text-white font-semibold rounded-xl bg-[#007AFF] sm:w-[75%]"
                                        onClick={() => handleReminder()}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </>
            }
        </Modal >
    )
}

export default AppointmentReminder