import React, { useEffect, useState, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import { useSelector } from "react-redux"

import { Button } from "antd"

import moment from "moment"
import ReactToPrint from "react-to-print"
import CCMdisabledFormInput from "../../../CCMAdminQuestions/CCMdisabledFormInput"
import CCMViewNavbar from "../CCMViewNavbar/CCMViewNavbar"

const CCMViewPrint = () => {
  const [formInputs] = useState([])
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [otherInputs, setOtherInputs] = useState({})

  const userInfo = useSelector(state => state.userInfo)

  const navigate = useNavigate()
  const location = useLocation()
  const title = location.state && location.state.title
  const componentRef = useRef()

  const list = location.state && location.state.list
  const patientInfoDetail = location.state && location.state.patientInfoDetail
  const carePlanData = location.state && location.state.carePlanData
  // console.log('list',list)
  // console.log('careplandata',carePlanData)

  useEffect(() => {
    if (!list || list === null) {
      navigate("/")
    }
  }, [userInfo, navigate, location.state]) //eslint-disable-line

  const generatePageNum = () => {
    // let totalPages = Math.ceil(document.body.scrollHeight / 1123); //842px A4 pageheight for 72dpi, 1123px A4 pageheight for 96dpi,
    const listLen = list?.length || 1
    const totalPages = Math.ceil(listLen / 3) + 1
    for (let i = 1; i <= totalPages; i++) {
      const pageNumberDiv = document.createElement("div")
      const pageNumber = document.createTextNode(`Page ${i} of ${totalPages}`)
      pageNumberDiv.classList.add("page-num-div")
      pageNumberDiv.style.position = "absolute"
      pageNumberDiv.style.top = `calc((${i} * (297mm - 0.5px)) - 40px)` // 297mm A4 pageheight; 0,5px unknown needed necessary correction value; additional wanted 40px margin from bottom(own element height included)
      pageNumberDiv.style.height = "16px"
      pageNumberDiv.appendChild(pageNumber)
      const parentDiv = document.getElementById("view-container")
      const originalDiv = document.getElementById("content")
      parentDiv.insertBefore(pageNumberDiv, originalDiv.nextSibling)
      pageNumberDiv.style.left = `calc(100% - (${pageNumberDiv.offsetWidth}px + 110px))`
      pageNumberDiv.style.backgroundColor = "#DBDBDB"
      pageNumberDiv.style.paddingBottom = "24px"
      pageNumberDiv.style.borderRadius = "6px"
    }
  }
  useEffect(() => {
    generatePageNum()
  }, [list]) //eslint-disable-line

  return (
    <div className="wrapper">
      {/* <SidebarComponent sidebarOpen={sidebarOpen} /> */}
      <div id="content" className="no-hadow">
        <CCMViewNavbar
          setSidebarOpen={setSidebarOpen}
          sidebarOpen={sidebarOpen}
          userInfo={userInfo}
          title={title}
        />
        <div
          className="view-container"
          id="view-container"
          ref={componentRef}
          style={{
            padding: "20px",
            overflowY: "auto"
          }}>
          <div className="flex justify-between w-full py-2">
            <h4 className="Ish px-3">Answers</h4>
            <div className="flex noprint">
              <div>
                <Button type="ghost" onClick={() => window.history.back()}>
                  Back
                </Button>
              </div>
              <div>
                {/* eslint-disable */}
                <ReactToPrint
                  trigger={() => <Button className="pdf-button mx-2">Print as PDF</Button>}
                  content={() => componentRef.current}
                />
              </div>
              {/* <div>
                            <button className="btn btn-dark btn-sm mx-2" onClick={exportPdf}>Test Print</button>
                        </div> */}
            </div>
          </div>
          {/* <div id="pageFooter">Page </div> */}
          <div className="CCMAddChronic no-hadow">
            <div className="p-4">
              <p
                style={{
                  fontSize: 24,
                  fontWeight: "600",
                  marginBottom: "10px"
                }}>
                Patient Details
              </p>
              <div className="flex justify-between bg-light">
                <div>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: "10px",
                      textTransform: "capitalize"
                    }}>
                    Date&nbsp;of&nbsp;Service:&nbsp;
                    {patientInfoDetail?.careplanStats !== null &&
                      moment.unix(patientInfoDetail?.careplanStats?.createdAt).format("MM/DD/YYYY")}
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: "10px"
                    }}>
                    Patient&nbsp;Name&nbsp;:&nbsp;{patientInfoDetail?.firstName}&nbsp;
                    {patientInfoDetail?.middleName}&nbsp;
                    {patientInfoDetail?.lastName}
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: "10px",
                      textTransform: "capitalize"
                    }}>
                    Patient&nbsp;DOB&nbsp;:&nbsp;
                    {moment.unix(patientInfoDetail?.dob).format("MM/DD/YYYY")}
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,

                      marginBottom: "10px"
                    }}>
                    <div className="flex">
                      Monitor&nbsp;Type&nbsp;:&nbsp;
                      {patientInfoDetail?.moniteringDetails?.map(item =>
                        item ===
                        patientInfoDetail?.moniteringDetails?.[
                          patientInfoDetail?.moniteringDetails?.length - 1
                        ] ? (
                          <p className="">{item?.moniterData?.name?.toUpperCase()}</p>
                        ) : (
                          <p className="">{item?.moniterData?.name?.toUpperCase()},&nbsp;</p>
                        )
                      )}
                    </div>
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: "10px",
                      textTransform: "capitalize"
                    }}>
                    Date&nbsp;of&nbsp;Plan&nbsp;Modified:&nbsp;
                    {patientInfoDetail?.careplanStats !== null &&
                      moment.unix(patientInfoDetail?.careplanStats?.updatedAt).format("MM/DD/YYYY")}
                  </p>
                </div>
                <div className="mx-5">
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: "10px",
                      textTransform: "capitalize"
                    }}>
                    <div className="flex flex-wrap">
                      <p>CareTaker&nbsp;:&nbsp;</p>
                      {patientInfoDetail?.caretakerData?.map(item =>
                        item ===
                        patientInfoDetail?.caretakerData?.[
                          patientInfoDetail?.caretakerData?.length - 1
                        ] ? (
                          <p className="capitalize">
                            {`${item?.caretakerData?.firstName} ${item?.caretakerData?.lastName}`}
                          </p>
                        ) : (
                          <p className="capitalize">
                            {item?.caretakerData?.firstName.replaceAll(" ", "")}&nbsp;
                            {item?.caretakerData?.lastName.replaceAll(" ", "")},&nbsp;
                          </p>
                        )
                      )}
                    </div>
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: "10px"
                    }}>
                    {patientInfoDetail?.moniteringDetails?.map((item, i) =>
                      item?.moniterData?.name === "ccm" ? (
                        <p className="capitalize" key={i}>
                          Provider&nbsp;:&nbsp;{item?.providerDetails?.providerData?.firstName}
                          &nbsp;
                          {item?.providerDetails?.providerData?.lastName}
                        </p>
                      ) : null
                    )}
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: "10px"
                    }}>
                    Chronic Conditions :{" "}
                    {Array.from(carePlanData?.careplanDetails ?? [].keys()).join(", ")}
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: "10px"
                    }}>
                    <div className="flex flex-wrap">
                      Diagnostic(ICD)&nbsp;Codes&nbsp;:&nbsp;
                      {patientInfoDetail?.diagnosticData?.length
                        ? patientInfoDetail?.diagnosticData?.map(item =>
                            item ===
                            patientInfoDetail?.diagnosticData?.[
                              patientInfoDetail?.diagnosticData?.length - 1
                            ] ? (
                              <p className="">{item?.diagnosticId}</p>
                            ) : (
                              <p className="">{item?.diagnosticId},&nbsp;</p>
                            )
                          )
                        : ""}
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="list-print-div" style={{ counterIncrement: "page" }}>
            {list?.map((ques, index) => (
              <div
                className="card w-full mb-3 shadow p-4 no-hadow mt-4"
                key={index}
                style={{ pageBreakAfter: index % 3 == 0 ? "always" : "avoid", color: "black" }}>
                <h4 className="form_heading">{ques?.name}</h4>
                <h5 className="subHeading">{ques?.title}</h5>
                <CCMdisabledFormInput
                  ques={ques}
                  formInputs={formInputs}
                  otherInputs={otherInputs}
                  setOtherInputs={setOtherInputs}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CCMViewPrint
