import { Button, Col, Row } from "antd"
import { Helmet } from "react-helmet"
import GoogleMapUseAuto from "./GoogleMapUseAuto"
import Loading from "../layouts/Loading/Loading"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import {
  getShippingAddressAction,
  postShippingAddressAction,
  updateShippingAddressAction
} from "../../redux/actions/patientAction"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import * as yup from "yup"

const ShippingAddress = ({ next, patientId, prevPage }) => {
  patientId = JSON.parse(localStorage.getItem("id"))

  const [address, setAddress] = useState()
  const [isSameShippingAddrs, setIsSameShippingAddrs] = useState(false)

  const { shippingAddress, loading: shippingFetchLoading } = useSelector(
    state => state.shippingAddress
  )
  const { outpatientAddress, loading: fetchAddressLoading } = useSelector(
    state => state.outpatientAddress
  )
  const { error: postShippingError, loading: postShippingLoading } = useSelector(
    state => state.postShippingAddress
  )
  const { error: updateShippingError, loading: updateShippingLoading } = useSelector(
    state => state.updateShippingAddress
  )
  const dispatch = useDispatch()
  const schema = yup.object({
    shippingAddressField: yup.string().required("Shipping address is required")
  })

  const {
    reset,
    control,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })
  useEffect(() => {
    dispatch(getShippingAddressAction(patientId))
  }, [])
  // if both ship and primary address same set the checkbox checked
  useEffect(() => {
    if (outpatientAddress?.street?.toLowerCase() === shippingAddress?.street?.toLowerCase() &&
      outpatientAddress?.city?.toLowerCase() === shippingAddress?.city?.toLowerCase() &&
      outpatientAddress?.state?.toLowerCase() === shippingAddress?.state?.toLowerCase() &&
      outpatientAddress?.zipcode === shippingAddress?.zipcode )
      {
        setIsSameShippingAddrs(true)
      } else {
        setIsSameShippingAddrs(false)
      }
  }, [shippingFetchLoading, fetchAddressLoading])

  const onSubmit = async data => {
    let formValues = {
      street: address.street,
      city: address?.city,
      state: address?.state,
      zipcode: address?.zipcode
    }
    let shippingResult = !shippingAddress?.id
      ? dispatch(postShippingAddressAction(patientId, formValues))
      : dispatch(updateShippingAddressAction(patientId, formValues))
    shippingResult.then(res => {
      if (res?.message?.toLowerCase() === "success") {
        dispatch(getShippingAddressAction(patientId))
        next()
      }
    })
  }

  window.back = () => {
    prevPage()
  }
  return (
    <>
      <Helmet>
        <script>{`

               if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.navigationTitle) {
                 window.webkit.messageHandlers.navigationTitle.postMessage("Your Details");
                  } else {
                  console.error("window.webkit.messageHandlers.navigationTitle is undefined. Unable to post message.");
                }
              
        `}</script>
      </Helmet>
      {!window?.isMobileVersion ? (
        <div className="flex justify-center py-3  text-[1rem] font-semibold shadow-sm ">
          Your Details
        </div>
      ) : null}
      <div className={`${!window?.isMobileVersion ? "pt-1" : "py-6"} px-3`}>
        {!shippingFetchLoading ? (
          <form onSubmit={handleSubmit(onSubmit)} className="h-[90vh] relative">
            <Row gutter={20}>
            <Col xs={24} md={24} className="mb-4">
              <h1 className={`${!window?.isMobileVersion ? "pb-1" : "pb-8"} pt-4`}>Address</h1>
              <label className="text-[#959595] mb-[0.75rem] text-base" htmlFor="input-address">
                Shipping Address <span className="text-danger">*</span>
              </label>
              <Controller
                name="shippingAddressField"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <GoogleMapUseAuto
                    setAddress={setAddress}
                   // {...field}
                    dbAddress={shippingAddress}
                    error={errors?.shippingAddressField?.message}
                    setValue={setValue}
                    from="shippingAddress"
                    isSameShippingAddrs = {isSameShippingAddrs}
                  />
                )}
              />
            </Col>
            {updateShippingError || postShippingError ? (
              <Col className="mb-4" xs={24}>
                <p className="text-danger font-semibold">
                  {postShippingError || updateShippingError}
                </p>
              </Col>
            ) : null}
            </Row>
            
            <Row className="w-full mt-2 absolute bottom-1">
              <Col xs={12} md={12} className="mb-2 pr-2 pl-0">
                <Button
                  type="primary"
                  onClick={prevPage}
                  style={{ minWidth: 0 }}
                  className="w-full">
                  Back
                </Button>
              </Col>
              <Col xs={12} md={12} className="mb-2 pr-0 pl-2">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={postShippingLoading || updateShippingLoading}
                  style={{ minWidth: 0 }}
                  className="w-full">
                  Next
                </Button>
              </Col>
            </Row>
          </form>
        ) : (
          <Loading />
        )}
      </div>
    </>
  )
}

export default ShippingAddress
